import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./ProductosTorta.css";
import {
  CardProductosParametro,
  CardProductosParametroEdit,
  CardProductosTamano,
  CardProductosTamanoEdit,
} from "../../../components/Card/CardProductos/CardProductosTorta/CardProductosTorta.jsx";
import Spinner from "../../../components/Spinner/Spiner";
import Fatal from "../../../components/Fatal/Fatal";
import * as tortaActions from "../../../redux/actions/tortaActions";

const ProductosTorta = (props) => {
  const [editando, setEditando] = useState(false);

  const {
    companyId,
    error,
    cargando,
    torta,
    tortaTraerTodos,
    tortaToggleEdit,
    parametrosToggleEdit,
    agregarTamano,
    eliminarTamano,
    agregarCategoriaParametro,
    editarCategoriaParametro,
    eliminarCategoriaParametro,
    agregarParametro,
    eliminarParametro,
    editarTamano,
  } = props;

  useEffect(() => {
    if (!Object.keys(torta).length) {
      tortaTraerTodos();
    }
  }, []);

  return (
    <div>
      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        <div>
          <p className="producto-torta__titulo">{torta.porciones?.titulo}</p>
          {torta.porciones?.editar ? (
            <CardProductosTamanoEdit
              setEditando={setEditando}
              id={torta.porciones.id}
              porciones={torta.porciones?.datos}
              toggleEdit={tortaToggleEdit}
              agregar={agregarTamano}
              eliminar={eliminarTamano}
              editarTamano={editarTamano}
            />
          ) : (
            <CardProductosTamano
              editando={editando}
              setEditando={setEditando}
              porciones={torta.porciones?.datos}
              toggleEdit={tortaToggleEdit}
            />
          )}
        </div>
      )}

      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        torta.editar &&
        torta.editar.map((elemento, index) => {
          const key = elemento.id ? elemento.id : `array${index}`;
          return (
            <div key={key}>
              {elemento.editar ? (
                <CardProductosParametroEdit
                  companyId={companyId}
                  setEditando={setEditando}
                  parametros={elemento}
                  index={index}
                  id={elemento.id}
                  eliminarParametro={eliminarCategoriaParametro}
                  agregar={agregarParametro}
                  eliminar={eliminarParametro}
                  editarCategoriaParametro={editarCategoriaParametro}
                />
              ) : (
                <CardProductosParametro
                  editando={editando}
                  setEditando={setEditando}
                  parametro={elemento}
                  id={elemento.id}
                  toggleEdit={parametrosToggleEdit}
                />
              )}
            </div>
          );
        })
      )}
      {!editando && (
        <div className="productos-arreglos__item">
          <button
            onClick={() => agregarCategoriaParametro()}
            className="btn btn--small"
          >
            Agregar Nuevo Parametro
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.tortaReducer;
};

export default connect(mapStateToProps, tortaActions)(ProductosTorta);
