import { TRAER_TODOS, CARGANDO, ERROR, ACTUALIZAR } from "../types/ClientesTypes"

const INITIAL_STATE = {
  clientes: {},
  cargando: false,
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRAER_TODOS:
      return {
        ...state,
        clientes: action.payload,
        cargando: false,
        error: "",
      };
    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false };
    
    case ACTUALIZAR:
      return {
        ...state,
        clientes: action.payload,
        cargando: false,
        error: ""
      }
    default:
      return state;
  }
};
