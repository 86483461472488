import React from "react";
import { useForm } from "react-hook-form";
import { recovery } from "../../../services/auth.service";

import "./RecoverPassword.css";

const RecoverPassword = (props) => {
  const { register, handleSubmit } = useForm();

  const recoverPassword = (email) => {
    recovery(email);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (data) => {
          await recoverPassword(data.email);
          await props.history.push("/login");
        })}
      >
        <div className="recover-password__item-titulo">
          <p className="recover-password__titulo-principal">Forgot password</p>
        </div>
        <div className="recover-password__item-container">
          <div className="recover-password__item">
            <p className="recover-password__titulo-secundario">Email</p>
            <input
              {...register("email", { required: true })}
              name="email"
              id="email"
              type="email"
              className="input input--xxlarge"
            />
          </div>

          <div className="recover-password__btn">
            <button className="btn btn--large-short">Recover password</button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default RecoverPassword;
