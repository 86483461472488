import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import "./CuentaEditar.css";
import * as cuentaActions from "../../../redux/actions/cuentaActions";

const CuentaEditar = (props) => {
  const {
    user: { user },
    cuentaTraerTodos,
    actualizarCuenta,
  } = props;

  // useLayoutEffect(() => {
  //   if (!Object.keys(user).length) {
  //     cuentaTraerTodos();
  //   }
  // });

  //   {
  //     "id": 1,
  //     "email": "litosandres@hotmail.com",
  //     "nombre": "Carlos",
  //     "apellido": "Dominguez",
  //     "cedula": 1842357349,
  //     "telefono": 949764528,
  //     "facebook": "alguno",
  //     "instagram": "otro",
  // }

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      id: user.id,
      nombre: user.nombre,
      apellido: user.apellido,
      cedula: user.cedula,
      telefono: user.telefono,
      email: user.email,
      facebook: user.facebook,
      instagram: user.instagram,
    },
  });

  return (
    <div className="cuenta-editar">
      <form
        onSubmit={handleSubmit((data) => {
          actualizarCuenta(data);
          props.history.push("cuenta");
        })}
      >
        <div className="cuenta-editar__item-titulo">
          <p className="cuenta-editar__titulo-principal">Cuenta</p>
        </div>

        <div className="cuenta-editar__item">
          <label htmlFor="nombre" className="cuenta-editar__titulo-secundario">
            Nombre
          </label>
          <input
            {...register("nombre")}
            name="nombre"
            id="nombre"
            className="input input--xxxlarge"
          />
        </div>

        <div className="cuenta-editar__item">
          <label
            htmlFor="apellido"
            className="cuenta-editar__titulo-secundario"
          >
            Apellido
          </label>
          <input
            {...register("apellido")}
            name="apellido"
            id="apellido"
            className="input input--xxxlarge"
          />
        </div>

        <div className="cuenta-editar__item">
          <label htmlFor="cedula" className="cuenta-editar__titulo-secundario">
            Cedula
          </label>
          <input
            {...register("cedula", {
              pattern: {
                value: /^\d+$/,
                message: "This input is number only.",
              },
            })}
            name="cedula"
            id="cedula"
            className="input input--xxxlarge"
          />
          <ErrorMessage
            errors={errors}
            name="cedula"
            render={({ messages }) => {
              console.log("messages", messages);
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>

        <div className="cuenta-editar__item">
          <label
            htmlFor="telefono"
            className="cuenta-editar__titulo-secundario"
          >
            Telefono
          </label>
          <input
            {...register("telefono", {
              pattern: {
                value: /^\d+$/,
                message: "This input is number only.",
              },
            })}
            name="telefono"
            id="telefono"
            className="input input--xxxlarge"
          />
          <ErrorMessage
            errors={errors}
            name="telefono"
            render={({ messages }) => {
              console.log("messages", messages);
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>

        <div className="cuenta-editar__item">
          <label htmlFor="email" className="cuenta-editar__titulo-secundario">
            Email
          </label>
          <input
            {...register("email", { required: "This input is required." })}
            name="email"
            id="email"
            type="email"
            className="input input--xxxlarge"
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ messages }) => {
              console.log("messages", messages);
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>

        <div className="cuenta-editar__item">
          <label
            htmlFor="facebook"
            className="cuenta-editar__titulo-secundario"
          >
            Facebook
          </label>
          <input
            {...register("facebook", {
              required: "Si no desea poner escriba NO TENGO.",
            })}
            name="facebook"
            id="facebook"
            className="input input--xxxlarge"
          />
          <ErrorMessage
            errors={errors}
            name="facebook"
            render={({ messages }) => {
              console.log("messages", messages);
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>

        <div className="cuenta-editar__item">
          <label
            htmlFor="cuentaInstagram"
            className="cuenta-editar__titulo-secundario"
          >
            Instagram
          </label>
          <input
            {...register("instagram", {
              required: "Si no desea poner escriba NO TENGO.",
            })}
            name="instagram"
            id="instagram"
            className="input input--xxxlarge"
          />
          <ErrorMessage
            errors={errors}
            name="instagram"
            render={({ messages }) => {
              console.log("messages", messages);
              return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>
                      {message}
                    </p>
                  ))
                : null;
            }}
          />
        </div>
        <div className="cuenta-editar__btn">
          <button
            className="btn btn--medium"
            type="button"
            onClick={() => {
              reset(user);
            }}
          >
            Valores predeterminados
          </button>
        </div>

        <div className="cuenta-editar__btn">
          <button className="btn btn--large">Guardar</button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.cuentaReducer;
};

export default connect(mapStateToProps, cuentaActions)(CuentaEditar);
