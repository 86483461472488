import React, { useState, useLayoutEffect } from "react";
import { connect } from "react-redux";

import "./Cliente.css";
import {
  CardDeliveryInfo,
  CardOrderInfoArreglos,
  CardOrderInfoBocaditos,
  CardOrderInfoTorta,
} from "../../components/Card/Card";
import { CardClientInfo } from "../../components/Card/CardCliente/CardCliente";
import { ButtonBack } from "../../components/Button/Button";
import * as clientesActions from "../../redux/actions/clientesActions";
import * as pedidosActions from "../../redux/actions/pedidosActions";
import * as reservarActions from "../../redux/actions/reservarActions";
import { useEffect } from "react";

const { clientesTraerTodos, agregarUbicacion } = clientesActions;
const { pedidosTraerTodos } = pedidosActions;
const { reservarTraerTodos, toggleReservar } = reservarActions;

const Cliente = (props) => {
  const [clienteActual, setClienteActual] = useState(null);
  const [pedidosCliente, setPedidosCliente] = useState(null);

  const {
    match: {
      params: { id },
    },
    clientesTraerTodos,
    agregarUbicacion,
    pedidosTraerTodos,
    reservarTraerTodos,
    toggleReservar,
  } = props;

  const { clientes } = props.clientesReducer;
  const { pedidos } = props.pedidosReducer;
  const { reservar } = props.reservarReducer;

  const [sizePedidos, setSizePedidos] = useState(10);
  const [sizeUbicaciones, setSizeUbicaciones] = useState(3);

  useLayoutEffect(() => {
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }

    if (!Object.keys(pedidos).length) {
      pedidosTraerTodos();
    }
    if (!Object.keys(reservar).length) {
      reservarTraerTodos();
    }
  }, []);

  const mostrarMasUbicaciones = () => {
    setSizeUbicaciones(sizeUbicaciones + 3);
  };

  const mostrarMasPedidos = () => {
    setSizePedidos(sizePedidos + 10);
  };

  useEffect(() => {
    if (Object.keys(clientes).length > 0) {
      setClienteActual(
        clientes.clientes?.filter((cliente) => cliente.id === Number(id))[0]
      );
    }
  }, [clientes, id]);

  useEffect(() => {
    if (Object.keys(pedidos).length > 0 && clienteActual)
      setPedidosCliente(
        pedidos.pedidos?.filter(
          (pedido) => pedido.clienteId === clienteActual.id
        )
      );
  }, [pedidos, clienteActual]);

  return (
    <div className="cliente-info">
      <div>
        <ButtonBack />
        <p className="cliente__titulo-principal">Cliente</p>
      </div>
      {clienteActual && <CardClientInfo cliente={clienteActual} />}

      <div>
        <p className="cliente__titulo-secundario">Ubicacion</p>
      </div>
      {clienteActual?.ubicaciones.map((ubicacion, ubicacionIndex) => (
        <CardDeliveryInfo
          key={ubicacionIndex}
          ubicacion={ubicacion}
          ubicacionIndex={ubicacionIndex}
          idCliente={clienteActual.id}
        />
      ))}
      <div className="cliente-btn">
        <button
          onClick={() => agregarUbicacion(clienteActual && clienteActual.id)}
          type="button"
          className="btn btn--small"
        >
          Agregar Ubicacion
        </button>
      </div>
      <div className="cliente-btn">
        <button
          onClick={() => mostrarMasUbicaciones()}
          className="btn btn--small"
        >
          Mostrar mas
        </button>
      </div>

      <div>
        <p className="cliente__titulo-secundario">Pedidos</p>
      </div>

      {pedidosCliente
        ?.slice(0, sizePedidos)
        .map((pedido, index) =>
          pedido.tipoPedidoId === 1 ? (
            <CardOrderInfoTorta
              key={index}
              indexPedido={index}
              className={index % 2 === 0 ? "" : "card__client-info--black"}
              editColor={index % 2 === 0 ? "" : "edit--white"}
              infoColor={index % 2 === 0 ? "" : "info--white"}
              cliente={clienteActual}
              pedido={pedido}
              onClick={toggleReservar}
            />
          ) : pedido.tipoPedidoId === 2 ? (
            <CardOrderInfoBocaditos
              key={index}
              indexPedido={index}
              className={index % 2 === 0 ? "" : "card__client-info--black"}
              editColor={index % 2 === 0 ? "" : "edit--white"}
              infoColor={index % 2 === 0 ? "" : "info--white"}
              cliente={clienteActual}
              pedido={pedido}
              onClick={toggleReservar}
            />
          ) : pedido.tipoPedidoId === 3 ? (
            <CardOrderInfoArreglos
              key={index}
              indexPedido={index}
              className={index % 2 === 0 ? "" : "card__client-info--black"}
              editColor={index % 2 === 0 ? "" : "edit--white"}
              infoColor={index % 2 === 0 ? "" : "info--white"}
              cliente={clienteActual}
              pedido={pedido}
              onClick={toggleReservar}
            />
          ) : (
            <div></div>
          )
        )}

      <div className="cliente-btn">
        <button onClick={() => mostrarMasPedidos()} className="btn btn--small">
          Mostrar mas
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  clientesReducer,
  pedidosReducer,
  reservarReducer,
}) => {
  return { clientesReducer, pedidosReducer, reservarReducer };
};

const mapDispatchToProps = {
  clientesTraerTodos,
  agregarUbicacion,
  pedidosTraerTodos,
  reservarTraerTodos,
  toggleReservar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cliente);
