import React, { useLayoutEffect, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { connect } from "react-redux";

import { ButtonBack } from "../../../components/Button/Button";
import "./EditarCliente.css";
import Spinner from "../../../components/Spinner/Spiner";
import Fatal from "../../../components/Fatal/Fatal";
import * as clientesActions from "../../../redux/actions/clientesActions";

const EditarCliente = (props) => {
  const { register, handleSubmit } = useForm();
  const {
    match: {
      params: { id },
    },
    actualizarCliente,
    eliminarCliente,
    clientesTraerTodos,
  } = props;
  const { clientes, error, cargando } = props.clientesReducer;
  const {
    user: {
      user: { companyId },
    },
  } = props.cuentaReducer;

  const [clienteActual, setClienteActual] = useState(null);

  const location = useLocation();
  const { indexCliente } = location.state;

  useLayoutEffect(() => {
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(clientes).length > 0) {
      setClienteActual(
        clientes.clientes?.filter((cliente) => cliente.id === Number(id))[0]
      );
    }
  }, [clientes, id]);

  const clienteId = clienteActual?.id || `array${indexCliente}`;

  const asignar = (data) => {
    const object = {
      companyId: companyId,
      nombre: data.nombre,
      apellido: data.apellido,
      cedula: data.cedula,
      telefono: data.telefono,
      email: data.email,
      facebook: data.facebook,
      instagram: data.instagram,
    };

    return object;
  };

  const regresar = () => {
    eliminarCliente(clienteId);
    props.history.goBack();
  };

  return (
    <div className="editar-cliente">
      <div>
        <ButtonBack />
        <p className="editar-cliente__titulo-principal">Cliente</p>
      </div>

      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        clienteActual && (
          <form
            onSubmit={handleSubmit(async (data) => {
              await actualizarCliente(asignar(data), clienteId);
              props.history.goBack();
            })}
          >
            <div>
              <label htmlFor="nombre" className="editar-cliente__titulo">
                Nombre
              </label>
              <input
                {...register("nombre", { value: clienteActual?.nombre })}
                id="nombre"
                type="text"
                name="nombre"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="apellido" className="editar-cliente__titulo">
                Apellido
              </label>
              <input
                {...register("apellido", { value: clienteActual?.apellido })}
                id="apellido"
                type="text"
                name="apellido"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="cedula" className="editar-cliente__titulo">
                Cedula
              </label>
              <input
                {...register("cedula", {
                  value: clienteActual?.cedula,
                  valueAsNumber: true,
                })}
                id="cedula"
                type="text"
                name="cedula"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="telefono" className="editar-cliente__titulo">
                Telefono
              </label>
              <input
                {...register("telefono", {
                  value: clienteActual?.telefono,
                  valueAsNumber: true,
                })}
                id="telefono"
                type="text"
                name="telefono"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="email" className="editar-cliente__titulo">
                Email
              </label>
              <input
                {...register("email", { value: clienteActual?.email })}
                id="email"
                type="text"
                name="email"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="facebook" className="editar-cliente__titulo">
                Facebook
              </label>
              <input
                {...register("facebook", { value: clienteActual?.facebook })}
                id="facebook"
                type="text"
                name="facebook"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="instagram" className="editar-cliente__titulo">
                Instagram
              </label>
              <input
                {...register("instagram", { value: clienteActual?.instagram })}
                id="instagram"
                type="text"
                name="instagram"
                className="input input--xxxlarge"
              />
            </div>

            <div className="editar-cliente__btn">
              <button
                onClick={() => regresar()}
                type="button"
                className="btn btn--medium"
              >
                Eliminar
              </button>
            </div>

            <div className="editar-cliente__btn">
              <button type="submit" className="btn btn--large">
                Guardar
              </button>
            </div>
          </form>
        )
      )}
      {clienteActual === undefined && (
        <form
          onSubmit={handleSubmit(async (data) => {
            await actualizarCliente(asignar(data), clienteId);
            props.history.goBack();
          })}
        >
          <div>
            <label htmlFor="nombre" className="editar-cliente__titulo">
              Nombre
            </label>
            <input
              {...register("nombre", { value: clienteActual?.nombre })}
              id="nombre"
              type="text"
              name="nombre"
              className="input input--xxxlarge"
            />
          </div>

          <div>
            <label htmlFor="apellido" className="editar-cliente__titulo">
              Apellido
            </label>
            <input
              {...register("apellido", { value: clienteActual?.apellido })}
              id="apellido"
              type="text"
              name="apellido"
              className="input input--xxxlarge"
            />
          </div>

          <div>
            <label htmlFor="cedula" className="editar-cliente__titulo">
              Cedula
            </label>
            <input
              {...register("cedula", {
                value: clienteActual?.cedula,
                valueAsNumber: true,
              })}
              id="cedula"
              type="text"
              name="cedula"
              className="input input--xxxlarge"
            />
          </div>

          <div>
            <label htmlFor="telefono" className="editar-cliente__titulo">
              Telefono
            </label>
            <input
              {...register("telefono", {
                value: clienteActual?.telefono,
                valueAsNumber: true,
              })}
              id="telefono"
              type="text"
              name="telefono"
              className="input input--xxxlarge"
            />
          </div>

          <div>
            <label htmlFor="email" className="editar-cliente__titulo">
              Email
            </label>
            <input
              {...register("email", { value: clienteActual?.email })}
              id="email"
              type="text"
              name="email"
              className="input input--xxxlarge"
            />
          </div>

          <div>
            <label htmlFor="facebook" className="editar-cliente__titulo">
              Facebook
            </label>
            <input
              {...register("facebook", { value: clienteActual?.facebook })}
              id="facebook"
              type="text"
              name="facebook"
              className="input input--xxxlarge"
            />
          </div>

          <div>
            <label htmlFor="instagram" className="editar-cliente__titulo">
              Instagram
            </label>
            <input
              {...register("instagram", { value: clienteActual?.instagram })}
              id="instagram"
              type="text"
              name="instagram"
              className="input input--xxxlarge"
            />
          </div>

          <div className="editar-cliente__btn">
            <button
              onClick={() => regresar()}
              type="button"
              className="btn btn--medium"
            >
              Eliminar
            </button>
          </div>

          <div className="editar-cliente__btn">
            <button type="submit" className="btn btn--large">
              Guardar
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = ({ clientesReducer, cuentaReducer }) => {
  return { clientesReducer, cuentaReducer };
};

export default connect(mapStateToProps, clientesActions)(EditarCliente);
