import React from "react";
import { useForm } from "react-hook-form";

export const CardProductosArreglo = ({
  setEditando,
  editando,
  onClick,
  indexTipoProducto,
  tipoProducto,
  editColor,
}) => {
  const idTipoProducto = tipoProducto.id
    ? tipoProducto.id
    : `array${indexTipoProducto}`;

  const agregarProductos = (productos) =>
    productos?.map((producto, indexProducto) => {
      const idProducto = producto.id ? producto.id : `array${indexProducto}`;

      return (
        <div key={idProducto}>
          <div className="card__producto-arreglo__inner-item">
            <div>
              <p className="titulo">Producto</p>
              <p className="data">{producto.producto}</p>
            </div>

            <div>
              <p className="titulo">Precio</p>
              <p className="data">{producto.precio && `$${producto.precio}`}</p>
            </div>
          </div>

          <hr className="line"></hr>
        </div>
      );
    });
  return (
    <div>
      <p className="titulo-card">{tipoProducto.tipo}</p>
      <div className="card-productos card__producto-parametro-tamano">
        <div className="card-productos__inner card__producto-arreglo__inner">
          {agregarProductos(tipoProducto.arregloProductos)}

          {!editando && (
            <svg
              onClick={() => {
                setEditando(true);
                onClick(idTipoProducto);
              }}
              className={`card-productos__edit ${editColor}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const CardProductosArregloEdit = ({
  companyId,
  setEditando,
  tipoProducto,
  eliminarCategoria,
  agregarProducto,
  eliminarProducto,
  indexTipoProducto,
  onClick,
  editarArreglo,
}) => {
  const { register, handleSubmit, getValues } = useForm();

  const idTipoProducto = tipoProducto.id
    ? tipoProducto.id
    : `array${indexTipoProducto}`;

  const asignar = (data) => {
    let arrayProductos = [];

    tipoProducto?.arregloProductos.forEach((producto, indexProducto) => {
      const idProducto = producto.id ? producto.id : `array${indexProducto}`;

      arrayProductos.push({
        id: producto.id || null,
        arregloTipoProductoId: producto.arregloTipoProductoId || null,
        precio: data[`precio${idProducto}`],
        producto: data[`producto${idProducto}`],
      });
    });

    arrayProductos.forEach((producto) => {
      if (producto.id === null) {
        delete producto.id;
      }
      if (producto.arregloTipoProductoId === null) {
        producto.arregloTipoProductoId = 1;
      }
    });

    const objeto = {
      id: tipoProducto.id || null,
      companyId: companyId,
      tipo: data[`producto${idTipoProducto}Titulo`],
      arregloProductos: arrayProductos,
    };

    if (objeto.id === null) {
      delete objeto.id;
    }

    return objeto;
  };

  const agregarCamposArregloExistente = () =>
    tipoProducto.arregloProductos &&
    tipoProducto.arregloProductos.map((producto, indexProducto) => {
      const idProducto = producto.id ? producto.id : `array${indexProducto}`;

      return (
        <div
          key={idProducto}
          className="card__producto-arreglo-edit__inner-item"
        >
          <div>
            <label htmlFor={`producto${idProducto}`} className="titulo">
              Producto
            </label>
            <div className="tamano-edit">
              <input
                {...register(`producto${idProducto}`, {
                  value: producto.producto,
                })}
                name={`producto${idProducto}`}
                id={`producto${idProducto}`}
                className="input input--xlarge"
              />
            </div>
          </div>

          <div>
            <label htmlFor="precio1" className="titulo">
              Precio
            </label>
            <div className="tamano-edit">
              <input
                {...register(`precio${idProducto}`, {
                  value: producto.precio,
                })}
                name={`precio${idProducto}`}
                id={`precio${idProducto}`}
                className="input input--xsmall"
              />
              <svg
                onClick={() => eliminarProducto(idTipoProducto, idProducto)}
                className="delete"
              />
            </div>
          </div>
        </div>
      );
    });

  return (
    <div className="card-productos-arreglos-edit-container">
      <form
        onSubmit={handleSubmit((data) => {
          editarArreglo(idTipoProducto, asignar(data));
          onClick(idTipoProducto);
          setEditando(false);
        })}
      >
        <input
          name={`producto${idTipoProducto}Titulo`}
          id={`producto${idTipoProducto}Titulo`}
          {...register(`producto${idTipoProducto}Titulo`, {
            value: tipoProducto.tipo,
          })}
          className="input input--medium titulo-card__input"
        />
        <button
          type="button"
          onClick={() => eliminarCategoria(idTipoProducto)}
          className="btn btn-eliminar-categoria"
        >{`Eliminar ${tipoProducto.tipo}`}</button>
        <div className="card-productos card__producto-parametro-tamano">
          <div className="card__producto-arreglo-edit__inner">
            {agregarCamposArregloExistente()}

            <div className="btn-guardar-parametro">
              <button
                type="button"
                onClick={() =>
                  agregarProducto(idTipoProducto, asignar(getValues()))
                }
                className="btn btn--xsmall"
              >
                Agregar Producto
              </button>
            </div>
            <div className="btn-guardar-parametro">
              <button type="submit" className="btn btn--xsmall">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
