import axios from "axios";

import { authHeader } from "../../services/user.service";

import {
  CARGANDO,
  ERROR,
  TRAER_TODOS,
  ACTUALIZAR,
} from "../types/PedidosTypes";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const URL = `${URL_HOST}/api/v1/pedido`;
const URL_TIPO_TORTA = `${URL_HOST}/api/v1/pedidos/torta/tamano`;
const URL_TIPO_BOCADITOS = `${URL_HOST}/api/v1/pedidos/bocaditos/nombre`;
const URL_ARREGLO = `${URL_HOST}/api/v1/pedidos/arreglos`;
const URL_TIPO_DE_ARREGLOS = `${URL_ARREGLO}/tipo`;
const URL_CAJA_DULCES = `${URL_ARREGLO}/caja-dulces/cant`;
const URL_DESAYUNO = `${URL_ARREGLO}/desayuno`;
const URL_TIPO_DE_PRODUCTO = `${URL_DESAYUNO}/tipo-producto`;
const URL_PRODUCTO = `${URL_DESAYUNO}/producto`;

export const pedidosTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const header = await authHeader();

    const rtaPedidos = await axios.get(URL, {
      headers: {
        ...header,
      },
    });
    const rtaData = await rtaPedidos.data;
    const arrayPedidos = [];

    rtaData.forEach((pedido) => {
      arrayPedidos.push({
        id: pedido.id,
        clienteId: pedido.clienteId,
        ubicacionId: pedido.ubicacionId,
        fecha: new Date(pedido.fecha),
        hora: pedido.hora.slice(0, 5),
        notas: pedido.notas,
        precio: pedido.precio,
        tema: pedido.tema,
        tipoPedidoId: pedido.tipoPedidoId,
        tipoBocaditos: pedido.tipoBocaditos.map((bocaditoPedido) => ({
          id: bocaditoPedido.id,
          pedidoId: bocaditoPedido.pedidoId,
          bocadito: bocaditoPedido.bocadito,
          cantidad: bocaditoPedido.cantidad,
          tipoBocaditosParametros: bocaditoPedido.tipoBocaditosParametros.map(
            (parametro) => ({
              id: parametro.id,
              tipoBocaditoId: parametro.tipoBocaditoId,
              parametro: parametro.parametro,
              valor: parametro.valor,
            })
          ),
        })),
        tipoDeArreglos: pedido.tipoDeArreglos.map((tipoArreglo) => ({
          id: tipoArreglo.id,
          pedidoId: tipoArreglo.pedidoId,
          tipo: tipoArreglo.tipo,
          tipoArregloCajasCupcakes: tipoArreglo.tipoArregloCajasCupcakes?.map(
            (cajaCupcakes) => ({
              id: cajaCupcakes.id,
              tipoDeArregloId: cajaCupcakes.tipoDeArregloId,
              cantidad: cajaCupcakes.cantidad,
              parametros: cajaCupcakes.parametros.map((parametro) => ({
                id: parametro.id,
                parametro: parametro.parametro,
                tipoArregloC: parametro.tipoArregloC,
                valor: parametro.valor,
              })),
            })
          ),
          tipoArregloCajasDulces: tipoArreglo.tipoArregloCajasDulces?.map(
            (cajaDulces) => ({
              id: cajaDulces.id,
              tipoDeArregloId: cajaDulces.tipoDeArregloId,
              bocadito: cajaDulces.bocadito,
              cantidad: cajaDulces.cantidad,
              parametros: cajaDulces.parametros.map((parametro) => ({
                id: parametro.id,
                parametro: parametro.parametro,
                tipoArregloCaj: parametro.tipoArregloCaj,
                valor: parametro.valor,
              })),
            })
          ),
          tipoArregloDesayunos: tipoArreglo.tipoArregloDesayunos?.map(
            (desayuno) => ({
              id: desayuno.id,
              tipoDeArregloId: desayuno.tipoDeArregloId,
              tipoDeProducto: desayuno.tipoDeProducto,
              productos: desayuno.productos.map((producto) => ({
                id: producto.id,
                tipoArregloDesayu: producto.tipoArregloDesayu,
                producto: producto.producto,
                cantidad: producto.cantidad,
              })),
            })
          ),
        })),
        tipoTortas: pedido.tipoTortas.map((tortaPedido) => ({
          id: tortaPedido.id,
          pedidoId: pedido.id,
          tamano: tortaPedido.tamano,
          tipoTortaParametros: tortaPedido.tipoTortaParametros?.map(
            (parametro) => ({
              id: parametro.id,
              tipoTortaId: tortaPedido.id,
              parametro: parametro.parametro,
              valor: parametro.valor,
            })
          ),
        })),
      });
    });

    const respuesta = {
      pedidos: [...arrayPedidos],
      filtrosPrecio: [],
      filtrosFecha: [],
    };

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Informacion de clientes no disponible",
    });
  }
};

export const setFiltroPrecio = (pedidoFiltrado) => (dispatch, getState) => {
  dispatch({
    type: CARGANDO,
  });
  const { pedidos } = getState().pedidosReducer;

  const newObject = {
    ...pedidos,
    filtrosPrecio: pedidoFiltrado,
  };

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const setFiltroFecha = (pedidoFiltrado) => (dispatch, getState) => {
  dispatch({
    type: CARGANDO,
  });

  const { pedidos } = getState().pedidosReducer;

  const newObject = {
    ...pedidos,
    filtrosFecha: pedidoFiltrado,
  };

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const pedidoToggleEdit = (index) => (dispatch, getState) => {
  dispatch({
    type: CARGANDO,
  });

  const { pedidos } = getState().pedidosReducer;

  const seleccionada = pedidos.pedidos[index];

  const newObject = {
    ...pedidos,
  };

  newObject.pedidos = [...pedidos.pedidos];

  newObject.pedidos[index] = {
    ...seleccionada,
    editar: true,
  };

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const editarPedido = (data, idPedido) => async (dispatch, getState) => {
  const { pedidos } = getState().pedidosReducer;

  const datos = {
    datos: [data],
  };

  dispatch({
    type: CARGANDO,
  });

  try {
    const header = await authHeader();

    const rtaPost = await axios.post(URL, datos, {
      headers: {
        ...header,
      },
    });

    const rtaData = await rtaPost.data[0];

    let indexPedido = pedidos.pedidos.findIndex(
      (pedido) => pedido.id === idPedido
    );

    const pedido = {
      id: rtaData.id,
      clienteId: rtaData.clienteId,
      ubicacionId: rtaData.ubicacionId,
      fecha: new Date(rtaData.fecha),
      hora: rtaData.hora.slice(0, 5),
      notas: rtaData.notas,
      precio: rtaData.precio,
      tema: rtaData.tema,
      tipoPedidoId: rtaData.tipoPedidoId,
      tipoBocaditos: rtaData.tipoBocaditos?.map((bocaditoPedido) => ({
        id: bocaditoPedido.id,
        pedidoId: bocaditoPedido.pedidoId,
        bocadito: bocaditoPedido.bocadito,
        cantidad: bocaditoPedido.cantidad,
        tipoBocaditosParametros: bocaditoPedido.tipoBocaditosParametros.map(
          (parametro) => ({
            id: parametro.id,
            tipoBocaditoId: parametro.tipoBocaditoId,
            parametro: parametro.parametro,
            valor: parametro.valor,
          })
        ),
      })),
      tipoDeArreglos: data.tipoDeArreglos?.map((tipoArreglo) => ({
        id: tipoArreglo.id,
        pedidoId: tipoArreglo.pedidoId,
        tipo: tipoArreglo.tipo,
        //tipos de arreglos
        tipoArregloCajasCupcakes: tipoArreglo.tipoArregloCajasCupcakes?.map(
          (cajaCupcakes) => ({
            id: cajaCupcakes.id,
            tipoDeArregloId: cajaCupcakes.tipoDeArregloId,
            cantidad: cajaCupcakes.cantidad,
            parametros: cajaCupcakes.parametros.map((parametro) => ({
              id: parametro.id,
              parametro: parametro.parametro,
              tipoArregloC: parametro.tipoArregloC,
              valor: parametro.valor,
            })),
          })
        ),
        tipoArregloCajasDulces: tipoArreglo.tipoArregloCajasDulces?.map(
          (cajaDulces) => ({
            id: cajaDulces.id,
            tipoDeArregloId: cajaDulces.tipoDeArregloId,
            bocadito: cajaDulces.bocadito,
            cantidad: cajaDulces.cantidad,
            parametros: cajaDulces.parametros?.map((parametro) => ({
              id: parametro.id,
              parametro: parametro.parametro,
              tipoArregloCaj: parametro.tipoArregloCaj,
              valor: parametro.valor,
            })),
          })
        ),
        tipoArregloDesayunos: tipoArreglo.tipoArregloDesayunos?.map(
          (desayuno) => ({
            id: desayuno.id,
            tipoDeArregloId: desayuno.tipoDeArregloId,
            tipoDeProducto: desayuno.tipoDeProducto,
            productos: desayuno.productos.map((producto) => ({
              id: producto.id,
              tipoArregloDesayu: producto.tipoArregloDesayu,
              producto: producto.producto,
              cantidad: producto.cantidad,
            })),
          })
        ),
      })),
      tipoTortas: rtaData.tipoTortas?.map((tortaPedido) => ({
        id: tortaPedido.id,
        pedidoId: rtaData.id,
        tamano: tortaPedido.tamano,
        tipoTortaParametros: tortaPedido.tipoTortaParametros?.map(
          (parametro) => ({
            id: parametro.id,
            tipoTortaId: tortaPedido.id,
            parametro: parametro.parametro,
            valor: parametro.valor,
          })
        ),
      })),
    };

    const newObject = {
      ...pedidos,
    };
    if (indexPedido === -1) {
      newObject.pedidos = [...pedidos.pedidos, pedido];
    } else {
      newObject.pedidos = [...pedidos.pedidos];

      newObject.pedidos[indexPedido] = {
        ...pedido,
      };
    }

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Servicio no disponible en este momento.",
    });
  }
};

export const eliminarPedido = (idPedido) => async (dispatch, getState) => {
  const { pedidos } = getState().pedidosReducer;

  dispatch({
    type: CARGANDO,
  });

  try {
    const header = await authHeader();

    let indexPedido = pedidos.pedidos.findIndex(
      (pedido) => pedido.id === idPedido
    );

    await axios.delete(`${URL}/${idPedido}`, {
      headers: {
        ...header,
      },
    });

    const newObject = {
      ...pedidos,
    };

    newObject.pedidos = [
      ...pedidos.pedidos
        .slice(0, indexPedido)
        .concat(pedidos.pedidos.slice(indexPedido + 1)),
    ];

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Servicio no disponible en este momento.",
    });
  }
};

//RESERVAR BOCADITOS
export const agregarTipoBocadito = (idPedido, data) => (dispatch, getState) => {
  const { pedidos } = getState().pedidosReducer;

  let indexPedido = pedidos.pedidos.findIndex(
    (pedido) => pedido.id === idPedido
  );

  if (indexPedido === -1) {
    indexPedido = idPedido.replace("array", "");
  }

  const pedido = {
    id: idPedido,
    clienteId: data.clienteId,
    ubicacionId: data.ubicacionId,
    fecha: data.fecha,
    hora: data.hora,
    notas: data.notas,
    precio: data.precio,
    tema: data.tema,
    tipoPedidoId: data.tipoPedidoId,
    tipoBocaditos: data.tipoBocaditos.map((bocaditoPedido) => ({
      id: bocaditoPedido.id,
      pedidoId: bocaditoPedido.pedidoId,
      bocadito: bocaditoPedido.bocadito,
      cantidad: bocaditoPedido.cantidad,
      tipoBocaditosParametros: bocaditoPedido.tipoBocaditosParametros.map(
        (parametro) => ({
          id: parametro.id,
          tipoBocaditoId: parametro.tipoBocaditoId,
          parametro: parametro.parametro,
          valor: parametro.valor,
        })
      ),
    })),
    // tipoDeArreglos: data.tipoDeArreglos,
    // tipoTortas: data.tipoTortas
  };

  const newObject = {
    ...pedidos,
  };

  newObject.pedidos = [...pedidos.pedidos];
  newObject.pedidos[indexPedido] = {
    ...pedidos.pedidos[indexPedido],
    ...pedido,
  };
  newObject.pedidos[indexPedido].tipoBocaditos = [
    ...pedido.tipoBocaditos,
    {
      pedidoId: "",
      bocadito: "",
      cantidad: "",
      tipoBocaditosParametros: [],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const eliminarTipoBocadito =
  (idPedido, idTipoBocadito) => async (dispatch, getState) => {
    const { pedidos } = getState().pedidosReducer;
    dispatch({
      type: CARGANDO,
    });
    try {
      const header = await authHeader();

      let indexPedido = pedidos.pedidos.findIndex(
        (pedido) => pedido.id === idPedido
      );

      if (indexPedido === -1) {
        indexPedido = pedidos.pedidos.length - 1;
      }

      let indexTipoBocadito = pedidos.pedidos[
        indexPedido
      ].tipoBocaditos.findIndex(
        (tipoBocadito) => tipoBocadito.id === idTipoBocadito
      );

      if (indexTipoBocadito !== -1) {
        await axios.delete(`${URL_TIPO_BOCADITOS}/${idTipoBocadito}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexTipoBocadito = idPedido.replace("array", "");
      }

      const seleccionada = pedidos.pedidos[indexPedido].tipoBocaditos;

      const newObject = {
        ...pedidos,
      };

      newObject.pedidos = [...pedidos.pedidos];

      newObject.pedidos[indexPedido] = {
        ...pedidos.pedidos[indexPedido],
        tipoBocaditos: seleccionada
          .slice(0, indexTipoBocadito)
          .concat(seleccionada.slice(indexTipoBocadito + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };
//RESERVAR ARREGLOS
function crearPedido(data, idPedido) {
  const pedido = {
    id: idPedido,
    clienteId: data.clienteId,
    ubicacionId: data.ubicacionId,
    fecha: data.fecha,
    hora: data.hora,
    notas: data.notas,
    precio: data.precio,
    tema: data.tema,
    tipoPedidoId: data.tipoPedidoId,
    // tipoBocaditos: data.tipoBocaditos
    tipoDeArreglos: data.tipoDeArreglos.map((tipoArreglo) => ({
      id: tipoArreglo.id,
      pedidoId: tipoArreglo.pedidoId,
      tipo: tipoArreglo.tipo,
      //tipos de arreglos
      tipoArregloCajasCupcakes: tipoArreglo.tipoArregloCajasCupcakes?.map(
        (cajaCupcakes) => ({
          id: cajaCupcakes.id,
          tipoDeArregloId: cajaCupcakes.tipoDeArregloId,
          cantidad: cajaCupcakes.cantidad,
          parametros: cajaCupcakes.parametros.map((parametro) => ({
            id: parametro.id,
            parametro: parametro.parametro,
            tipoArregloC: parametro.tipoArregloCajaCupcakesId,
            valor: parametro.valor,
          })),
        })
      ),
      tipoArregloCajasDulces: tipoArreglo.tipoArregloCajasDulces?.map(
        (cajaDulces) => ({
          id: cajaDulces.id,
          tipoDeArregloId: cajaDulces.tipoDeArregloId,
          bocadito: cajaDulces.bocadito,
          cantidad: cajaDulces.cantidad,
          parametros: cajaDulces.parametros?.map((parametro) => ({
            id: parametro.id,
            parametro: parametro.parametro,
            tipoArregloCaj: parametro.tipoArregloCajaDulcesId,
            valor: parametro.valor,
          })),
        })
      ),
      tipoArregloDesayunos: tipoArreglo.tipoArregloDesayunos?.map(
        (desayuno) => ({
          id: desayuno.id,
          tipoDeArregloId: desayuno.tipoDeArregloId,
          tipoDeProducto: desayuno.tipoDeProducto,
          productos: desayuno.productos.map((producto) => ({
            id: producto.id,
            tipoArregloDesayu: producto.tipoArregloDesayunoId,
            producto: producto.producto,
            cantidad: producto.cantidad,
          })),
        })
      ),
    })),
    // tipoTortas: data.tipoTortas
  };

  return pedido;
}

export const agregarTipoArreglo = (idPedido, data) => (dispatch, getState) => {
  const { pedidos } = getState().pedidosReducer;

  let indexPedido = pedidos.pedidos.findIndex(
    (pedido) => pedido.id === idPedido
  );

  if (indexPedido === -1) {
    indexPedido = idPedido.replace("array", "");
  }

  const pedido = crearPedido(data, idPedido);

  const newObject = {
    ...pedidos,
  };

  newObject.pedidos = [...pedidos.pedidos];
  newObject.pedidos[indexPedido] = {
    ...pedidos.pedidos[indexPedido],
    ...pedido,
  };
  newObject.pedidos[indexPedido].tipoDeArreglos = [
    ...pedido.tipoDeArreglos,
    {
      id: "",
      pedidoId: "",
      tipo: "",
      tipoArregloCajasCupcakes: [],
      tipoArregloCajasDulces: [],
      tipoArregloDesayunos: [],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const eliminarTipoArreglo =
  (idPedido, idArreglo, data) => async (dispatch, getState) => {
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();

      const { pedidos } = getState().pedidosReducer;

      let indexPedido = pedidos.pedidos.findIndex(
        (pedido) => pedido.id === idPedido
      );

      if (indexPedido === -1) {
        indexPedido = idPedido.replace("array", "");
      }

      let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
        (arreglo) => arreglo.id === idArreglo
      );

      if (indexArreglo !== -1) {
        await axios.delete(`${URL_TIPO_DE_ARREGLOS}/${idArreglo}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexArreglo = idArreglo.replace("array", "");
      }

      const pedido = crearPedido(data, idPedido);

      const newObject = {
        ...pedidos,
      };

      newObject.pedidos = [...pedidos.pedidos];
      newObject.pedidos[indexPedido] = {
        ...pedidos.pedidos[indexPedido],
        ...pedido,
      };
      newObject.pedidos[indexPedido].tipoDeArreglos = [
        ...pedido.tipoDeArreglos
          .slice(0, indexArreglo)
          .concat(pedido.tipoDeArreglos.slice(indexArreglo + 1)),
      ];

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarTipoProducto =
  (idPedido, idArreglo, data) => (dispatch, getState) => {
    const { pedidos } = getState().pedidosReducer;

    let indexPedido = pedidos.pedidos.findIndex(
      (pedido) => pedido.id === idPedido
    );

    if (indexPedido === -1) {
      indexPedido = idPedido.replace("array", "");
    }

    let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
      (arreglo) => arreglo.id === idArreglo
    );

    if (indexArreglo === -1) {
      indexArreglo = idArreglo.replace("array", "");
    }

    const pedido = crearPedido(data, idPedido);

    const newObject = {
      ...pedidos,
    };

    newObject.pedidos = [...pedidos.pedidos];
    newObject.pedidos[indexPedido] = {
      ...pedidos.pedidos[indexPedido],
      ...pedido,
    };
    newObject.pedidos[indexPedido].tipoDeArreglos = [...pedido.tipoDeArreglos];

    newObject.pedidos[indexPedido].tipoDeArreglos[indexArreglo] = {
      ...pedido.tipoDeArreglos[indexArreglo],
    };

    newObject.pedidos[indexPedido].tipoDeArreglos[
      indexArreglo
    ].tipoArregloDesayunos = [
      ...pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos,
      {
        id: "",
        tipoDeArregloId: "",
        tipoDeProducto: "",
        productos: [
          {
            id: "",
            tipoArregloDesayu: "",
            producto: "",
            cantidad: "",
          },
        ],
      },
    ];

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  };

export const eliminarTipoProducto =
  (idPedido, idArreglo, idTipoProducto, data) => async (dispatch, getState) => {
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();
      const { pedidos } = getState().pedidosReducer;

      let indexPedido = pedidos.pedidos.findIndex(
        (pedido) => pedido.id === idPedido
      );

      if (indexPedido === -1) {
        indexPedido = idPedido.replace("array", "");
      }

      let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
        (arreglo) => arreglo.id === idArreglo
      );

      if (indexArreglo === -1) {
        indexArreglo = idArreglo.replace("array", "");
      }

      let indexTipoProducto = pedidos.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos.findIndex(
        (tipoProducto) => tipoProducto.id === idTipoProducto
      );

      if (indexTipoProducto !== -1) {
        await axios.delete(`${URL_TIPO_DE_PRODUCTO}/${idTipoProducto}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexTipoProducto = idTipoProducto.replace("array", "");
      }

      const pedido = crearPedido(data, idPedido);
      // por alguna razon esto no funciona en newOBject  pedido.tipoDeArreglos[indexArreglo] supuestamente no iterable

      const seleccionada =
        pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos;

      const newObject = {
        ...pedidos,
      };

      newObject.pedidos = [...pedidos.pedidos];
      newObject.pedidos[indexPedido] = {
        ...pedidos.pedidos[indexPedido],
        ...pedido,
      };
      newObject.pedidos[indexPedido].tipoDeArreglos = [
        ...pedido.tipoDeArreglos,
      ];

      newObject.pedidos[indexPedido].tipoDeArreglos[indexArreglo] = {
        ...pedido.tipoDeArreglos[indexArreglo],
      };

      newObject.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos = [
        ...seleccionada
          .slice(0, indexTipoProducto)
          .concat(...seleccionada.slice(indexTipoProducto + 1)),
      ];

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarProducto =
  (idPedido, idArreglo, idTipoProducto, data) => (dispatch, getState) => {
    const { pedidos } = getState().pedidosReducer;

    let indexPedido = pedidos.pedidos.findIndex(
      (pedido) => pedido.id === idPedido
    );

    if (indexPedido === -1) {
      indexPedido = idPedido.replace("array", "");
    }

    let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
      (arreglo) => arreglo.id === idArreglo
    );

    if (indexArreglo === -1) {
      indexArreglo = idArreglo.replace("array", "");
    }

    let indexTipoProducto = pedidos.pedidos[indexPedido].tipoDeArreglos[
      indexArreglo
    ].tipoArregloDesayunos.findIndex(
      (tipoProducto) => tipoProducto.id === idTipoProducto
    );

    if (indexTipoProducto === -1) {
      indexTipoProducto = idTipoProducto.replace("array", "");
    }

    const pedido = crearPedido(data, idPedido);

    const newObject = {
      ...pedidos,
    };

    newObject.pedidos = [...pedidos.pedidos];
    newObject.pedidos[indexPedido] = {
      ...pedidos.pedidos[indexPedido],
      ...pedido,
    };
    newObject.pedidos[indexPedido].tipoDeArreglos = [...pedido.tipoDeArreglos];

    newObject.pedidos[indexPedido].tipoDeArreglos[indexArreglo] = {
      ...pedido.tipoDeArreglos[indexArreglo],
    };

    newObject.pedidos[indexPedido].tipoDeArreglos[
      indexArreglo
    ].tipoArregloDesayunos = [
      ...pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos,
    ];
    newObject.pedidos[indexPedido].tipoDeArreglos[
      indexArreglo
    ].tipoArregloDesayunos[indexTipoProducto] = {
      ...pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos[
        indexTipoProducto
      ],
    };
    newObject.pedidos[indexPedido].tipoDeArreglos[
      indexArreglo
    ].tipoArregloDesayunos[indexTipoProducto].productos = [
      ...pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos[
        indexTipoProducto
      ].productos,
      {
        id: "",
        tipoArregloDesayu: "",
        producto: "",
        cantidad: "",
      },
    ];

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  };

export const eliminarProducto =
  (idPedido, idArreglo, idTipoProducto, idProducto, data) =>
  async (dispatch, getState) => {
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();
      const { pedidos } = getState().pedidosReducer;

      let indexPedido = pedidos.pedidos.findIndex(
        (pedido) => pedido.id === idPedido
      );

      if (indexPedido === -1) {
        indexPedido = idPedido.replace("array", "");
      }

      let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
        (arreglo) => arreglo.id === idArreglo
      );

      if (indexArreglo === -1) {
        indexArreglo = idArreglo.replace("array", "");
      }

      let indexTipoProducto = pedidos.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos.findIndex(
        (tipoProducto) => tipoProducto.id === idTipoProducto
      );

      if (indexTipoProducto === -1) {
        indexTipoProducto = idTipoProducto.replace("array", "");
      }

      let indexProducto = pedidos.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos[indexTipoProducto].productos.findIndex(
        (producto) => producto.id === idProducto
      );

      if (indexProducto !== -1) {
        await axios.delete(`${URL_PRODUCTO}/${idProducto}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexProducto = idProducto.replace("array", "");
      }

      const pedido = crearPedido(data, idPedido);

      const newObject = {
        ...pedidos,
      };
      const seleccionada =
        pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos[
          indexTipoProducto
        ].productos;

      newObject.pedidos = [...pedidos.pedidos];
      newObject.pedidos[indexPedido] = {
        ...pedidos.pedidos[indexPedido],
        ...pedido,
      };
      newObject.pedidos[indexPedido].tipoDeArreglos = [
        ...pedido.tipoDeArreglos,
      ];

      newObject.pedidos[indexPedido].tipoDeArreglos[indexArreglo] = {
        ...pedido.tipoDeArreglos[indexArreglo],
      };

      newObject.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos = [
        ...pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos,
      ];
      newObject.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos[indexTipoProducto] = {
        ...pedido.tipoDeArreglos[indexArreglo].tipoArregloDesayunos[
          indexTipoProducto
        ],
      };

      newObject.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloDesayunos[indexTipoProducto].productos = [
        ...seleccionada
          .slice(0, indexProducto)
          .concat(...seleccionada.slice(indexProducto + 1)),
      ];

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarBocadito =
  (idPedido, idArreglo, data) => (dispatch, getState) => {
    const { pedidos } = getState().pedidosReducer;

    let indexPedido = pedidos.pedidos.findIndex(
      (pedido) => pedido.id === idPedido
    );

    if (indexPedido === -1) {
      indexPedido = idPedido.replace("array", "");
    }

    let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
      (arreglo) => arreglo.id === idArreglo
    );

    if (indexArreglo === -1) {
      indexArreglo = idArreglo.replace("array", "");
    }

    const pedido = crearPedido(data, idPedido);

    const newObject = {
      ...pedidos,
    };

    newObject.pedidos = [...pedidos.pedidos];
    newObject.pedidos[indexPedido] = {
      ...pedidos.pedidos[indexPedido],
      ...pedido,
    };
    newObject.pedidos[indexPedido].tipoDeArreglos = [...pedido.tipoDeArreglos];

    newObject.pedidos[indexPedido].tipoDeArreglos[indexArreglo] = {
      ...pedido.tipoDeArreglos[indexArreglo],
    };

    newObject.pedidos[indexPedido].tipoDeArreglos[
      indexArreglo
    ].tipoArregloCajasDulces = [
      ...pedido.tipoDeArreglos[indexArreglo].tipoArregloCajasDulces,
      {
        id: "",
        tipoDeArregloId: "",
        bocadito: "",
        cantidad: "",
        parametros: [],
      },
    ];

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  };

export const eliminarBocadito =
  (idPedido, idArreglo, idPedidoBocadito, data) =>
  async (dispatch, getState) => {
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();
      const { pedidos } = getState().pedidosReducer;

      let indexPedido = pedidos.pedidos.findIndex(
        (pedido) => pedido.id === idPedido
      );

      if (indexPedido === -1) {
        indexPedido = idPedido.replace("array", "");
      }

      let indexArreglo = pedidos.pedidos[indexPedido].tipoDeArreglos.findIndex(
        (arreglo) => arreglo.id === idArreglo
      );

      if (indexArreglo === -1) {
        indexArreglo = idArreglo.replace("array", "");
      }

      let indexPedidoBocadito = pedidos.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloCajasDulces.findIndex(
        (bocadito) => bocadito.id === idPedidoBocadito
      );

      if (indexPedidoBocadito !== -1) {
        await axios.delete(`${URL_CAJA_DULCES}/${idPedidoBocadito}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexPedidoBocadito = idPedidoBocadito.replace("array", "");
      }

      const pedido = crearPedido(data, idPedido);

      const newObject = {
        ...pedidos,
      };

      newObject.pedidos = [...pedidos.pedidos];
      newObject.pedidos[indexPedido] = {
        ...pedidos.pedidos[indexPedido],
        ...pedido,
      };
      newObject.pedidos[indexPedido].tipoDeArreglos = [
        ...pedido.tipoDeArreglos,
      ];

      newObject.pedidos[indexPedido].tipoDeArreglos[indexArreglo] = {
        ...pedido.tipoDeArreglos[indexArreglo],
      };

      newObject.pedidos[indexPedido].tipoDeArreglos[
        indexArreglo
      ].tipoArregloCajasDulces = [
        ...pedido.tipoDeArreglos[indexArreglo].tipoArregloCajasDulces
          .slice(0, indexPedidoBocadito)
          .concat(
            pedido.tipoDeArreglos[indexArreglo].tipoArregloCajasDulces.slice(
              indexPedidoBocadito + 1
            )
          ),
      ];

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

//RESERVAR TORTA
export const agregarTipoTorta = (idPedido, data) => (dispatch, getState) => {
  const { pedidos } = getState().pedidosReducer;

  let indexPedido = pedidos.pedidos.findIndex(
    (pedido) => pedido.id === idPedido
  );

  if (indexPedido === -1) {
    indexPedido = idPedido.replace("array", "");
  }

  const pedido = {
    id: idPedido,
    clienteId: data.clienteId,
    ubicacionId: data.ubicacionId,
    fecha: new Date(data.fecha),
    hora: data.hora.slice(0, 5),
    notas: data.notas,
    precio: data.precio,
    tema: data.tema,
    tipoPedidoId: data.tipoPedidoId,
    // tipoBocaditos: data.tipoBocaditos,
    // tipoDeArreglos: data.tipoDeArreglos,
    tipoTortas: data.tipoTortas?.map((tortaPedido) => ({
      id: tortaPedido.id,
      pedidoId: data.id,
      tamano: tortaPedido.tamano,
      tipoTortaParametros: tortaPedido.tipoTortaParametros?.map(
        (parametro) => ({
          id: parametro.id,
          tipoTortaId: tortaPedido.id,
          parametro: parametro.parametro,
          valor: parametro.valor,
        })
      ),
    })),
  };

  const newObject = {
    ...pedidos,
  };

  newObject.pedidos = [...pedidos.pedidos];
  newObject.pedidos[indexPedido] = {
    ...pedidos.pedidos[indexPedido],
    ...pedido,
  };
  newObject.pedidos[indexPedido].tipoTortas = [
    ...pedido.tipoTortas,
    {
      pedidoId: "",
      tamano: "",
      tipoTortaParametros: [],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const eliminarTipoTorta =
  (idPedido, idTipoTorta) => async (dispatch, getState) => {
    const { pedidos } = getState().pedidosReducer;
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();
      let indexPedido = pedidos.pedidos.findIndex(
        (pedido) => pedido.id === idPedido
      );

      if (indexPedido === -1) {
        indexPedido = pedidos.pedidos.length - 1;
      }

      let indexTipoTorta = pedidos.pedidos[indexPedido].tipoTortas.findIndex(
        (tipoTorta) => tipoTorta.id === idTipoTorta
      );

      if (indexTipoTorta !== -1) {
        await axios.delete(`${URL_TIPO_TORTA}/${idTipoTorta}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexTipoTorta = idPedido.replace("array", "");
      }

      const seleccionada = pedidos.pedidos[indexPedido].tipoTortas;

      const newObject = {
        ...pedidos,
      };

      newObject.pedidos = [...pedidos.pedidos];

      newObject.pedidos[indexPedido] = {
        ...pedidos.pedidos[indexPedido],
        tipoTortas: seleccionada
          .slice(0, indexTipoTorta)
          .concat(seleccionada.slice(indexTipoTorta + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };
