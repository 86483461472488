import axios from "axios";

import { authHeader } from "../../services/user.service";

import { CARGANDO, ERROR, TRAER_TODOS, ACTUALIZAR } from "../types/TortaTypes";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const URL = `${URL_HOST}/api/v1/torta`;
const URL_PORCIONES = `${URL}/titulo-porciones`;
const URL_VALOR_PORCIONES = `${URL}/valor-porciones`;
const URL_PARAMETROS = `${URL}/titulo-parametros`;
const URL_VALOR_PARAMETROS = `${URL}/valor-parametros`;
const MULTIPLE = "/multiple";

export const tortaTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const header = await authHeader();

    //Porciones
    const rtaPorciones = await axios.get(URL_PORCIONES, {
      headers: {
        ...header,
      },
    });
    const dataPorciones = await rtaPorciones.data[0];
    let objetoPorciones = {};
    if (dataPorciones === undefined) {
      objetoPorciones = {
        id: 1,
        titulo: "Porciones",
        editar: false,
        datos: [
          {
            tamano: "",
            numPorcionesDe: "",
            numPorcionesA: "",
            precio: "",
          },
        ],
      };
    } else {
      objetoPorciones = {
        id: dataPorciones.id,
        titulo: dataPorciones.titulo,
        editar: false,
        datos: dataPorciones.datosTortaPorciones?.map((element) => ({
          id: element.id,
          tamano: element.tamano,
          numPorcionesDe: element.numeroPorcionesDe,
          numPorcionesA: element.numeroPorcionesA,
          precio: element.precio,
        })),
      };
    }

    //Parametros
    const rtaParametros = await axios.get(URL_PARAMETROS, {
      headers: {
        ...header,
      },
    });
    const dataParametros = await rtaParametros.data;
    const arrayParametros = [];

    if (!dataParametros.length) {
      arrayParametros.push({
        parametro: "",
        editar: true,
        datos: ["", ""],
      });
    } else {
      dataParametros.forEach((element) => {
        arrayParametros.push({
          id: element.id,
          parametro: element.parametro,
          datos: element.datosTortaParametros?.map((subElement) => ({
            id: subElement.id,
            valor: subElement.valor,
          })),
          editar: false,
        });
      });
    }

    const respuesta = {
      editar: [...arrayParametros],
      porciones: { ...objetoPorciones },
    };

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: ERROR,
      payload: "Informacion de torta no disponible",
    });
  }
};
//PORCIONES
export const tortaToggleEdit = () => (dispatch, getState) => {
  const { torta } = getState().tortaReducer;
  const seleccionada = torta.porciones;

  const newObject = {
    ...torta,
  };

  newObject.porciones = {
    ...torta.porciones,
    editar: !seleccionada.editar,
  };

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const parametrosToggleEdit = (id) => (dispatch, getState) => {
  const { torta } = getState().tortaReducer;

  const indexSeleccionada = torta.editar.findIndex(
    (element) => element.id === id
  );

  const newObject = {
    ...torta,
  };
  newObject.editar = [...torta.editar];

  newObject.editar[indexSeleccionada] = {
    ...torta.editar[indexSeleccionada],
    editar: !torta.editar[indexSeleccionada].editar,
  };

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const agregarTamano = (data) => (dispatch, getState) => {
  const { torta } = getState().tortaReducer;

  const newObject = {
    ...torta,
  };

  newObject.porciones = {
    ...torta.porciones,
    datos: [
      ...data,
      {
        tamano: "",
        numPorcionesDe: "",
        numPorcionesA: "",
        precio: "",
      },
    ],
  };

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const eliminarTamano = (id) => async (dispatch, getState) => {
  const { torta } = getState().tortaReducer;

  dispatch({
    type: CARGANDO,
  });

  try {
    const header = await authHeader();

    let indexPorcion = torta.porciones.datos.findIndex(
      (element) => element.id === id
    );

    if (indexPorcion === -1) {
      indexPorcion = id.replace("array", "");

      const seleccionada = torta.porciones.datos;

      const newObject = {
        ...torta,
      };

      newObject.porciones = {
        ...torta.porciones,
        datos: seleccionada
          .slice(0, indexPorcion)
          .concat(seleccionada.slice(indexPorcion + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } else {
      const rtaDelete = await axios.delete(`${URL_VALOR_PORCIONES}/${id}`, {
        headers: {
          ...header,
        },
      });

      const indexSeleccionada = await torta.porciones.datos.findIndex(
        (element) => element.id === Number(rtaDelete.data.id)
      );

      const seleccionada = torta.porciones.datos;

      const newObject = {
        ...torta,
      };

      newObject.porciones = {
        ...torta.porciones,
        datos: seleccionada
          .slice(0, indexSeleccionada)
          .concat(seleccionada.slice(indexSeleccionada + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    }
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Servicio no disponible en este momento.",
    });
  }
};

export const editarTamano = (data) => async (dispatch, getState) => {
  const { torta } = getState().tortaReducer;

  dispatch({
    type: CARGANDO,
  });

  try {
    const header = await authHeader();
    const rtaPost = await axios.post(
      `${URL_VALOR_PORCIONES}${MULTIPLE}`,
      {
        datos: [...data],
      },
      {
        headers: {
          ...header,
        },
      }
    );

    const newObject = {
      ...torta,
    };

    newObject.porciones = {
      ...torta.porciones,
      editar: false,
      datos: rtaPost.data.map((elemento) => ({
        id: elemento.id,
        tamano: elemento.tamano,
        numPorcionesDe: elemento.numeroPorcionesDe,
        numPorcionesA: elemento.numeroPorcionesA,
        precio: elemento.precio,
      })),
    };

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Servicio no disponible en este momento.",
    });
  }
};

//PARAMETROS
export const agregarCategoriaParametro = () => (dispatch, getState) => {
  const { torta } = getState().tortaReducer;

  const newObject = {
    ...torta,
  };

  newObject.editar = [
    ...torta.editar,
    {
      parametro: "",
      editar: true,
      datos: ["", ""],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const editarCategoriaParametro =
  (data) => async (dispatch, getState) => {
    const { torta } = getState().tortaReducer;

    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();

      const rtaPost = await axios.post(
        URL_PARAMETROS,
        {
          datos: [data],
        },
        {
          headers: {
            ...header,
          },
        }
      );

      let indexSeleccionada = torta.editar.findIndex(
        (element) => element.id === rtaPost.data[0].id
      );
      if (indexSeleccionada === -1) {
        indexSeleccionada = torta.editar.length - 1;
      }

      const newObject = {
        ...torta,
      };

      newObject.editar = [...torta.editar];

      newObject.editar[indexSeleccionada] = {
        ...torta.editar[indexSeleccionada],
        parametro: rtaPost.data[0]?.parametro,
        datos: rtaPost.data[0]?.datosTortaParametros?.map((subElement) => ({
          id: subElement.id,
          valor: subElement.valor,
        })),
        editar: false,
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const eliminarCategoriaParametro =
  (id) => async (dispatch, getState) => {
    const { torta } = getState().tortaReducer;
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();
      let indexParametro = torta.editar.findIndex(
        (element) => element.id === id
      );

      if (indexParametro === -1) {
        indexParametro = id.replace("array", "");

        const seleccionada = torta.editar;

        const newObject = {
          ...torta,
          editar: seleccionada
            .slice(0, indexParametro)
            .concat(seleccionada.slice(indexParametro + 1)),
        };

        dispatch({
          type: ACTUALIZAR,
          payload: newObject,
        });
      } else {
        const rtaDelete = await axios.delete(`${URL_PARAMETROS}/${id}`, {
          headers: {
            ...header,
          },
        });

        const indexSeleccionada = await torta.editar.findIndex(
          (element) => element.id === Number(rtaDelete.data.id)
        );

        const seleccionada = torta.editar;

        const newObject = {
          ...torta,
          editar: seleccionada
            .slice(0, indexSeleccionada)
            .concat(seleccionada.slice(indexSeleccionada + 1)),
        };

        dispatch({
          type: ACTUALIZAR,
          payload: newObject,
        });
      }
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarParametro =
  (indexParametros, data) => (dispatch, getState) => {
    const { torta } = getState().tortaReducer;

    const indexSeleccionada = torta.editar.findIndex(
      (element) => element.id === indexParametros
    );

    const newObject = {
      ...torta,
    };

    newObject.editar = [...torta.editar];

    newObject.editar[indexSeleccionada] = {
      ...torta.editar[indexSeleccionada],
      ...data,
      editar: true,
    };
    newObject.editar[indexSeleccionada].datos = [
      ...data.datosTortaParametros,
      "",
    ];

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  };

export const eliminarParametro =
  (idParametro, idDato) => async (dispatch, getState) => {
    const { torta } = getState().tortaReducer;

    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();
      const rtaDelete = await axios.delete(
        `${URL_VALOR_PARAMETROS}/${idDato}`,
        {
          headers: {
            ...header,
          },
        }
      );

      const indexSeleccionada = torta.editar.findIndex(
        (element) => element.id === idParametro
      );

      const indexDato = torta.editar[indexSeleccionada].datos.findIndex(
        (element) => element.id === Number(rtaDelete.data.id)
      );

      const seleccionada = torta.editar[indexSeleccionada].datos;

      const newObject = {
        ...torta,
      };

      newObject.editar = [...torta.editar];

      newObject.editar[indexSeleccionada] = {
        ...torta.editar[indexSeleccionada],
        datos: seleccionada
          .slice(0, indexDato)
          .concat(seleccionada.slice(indexDato + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };
