
import { CARGANDO, ERROR, TRAER_TODOS, ACTUALIZAR } from "../types/TabHistorialTypes";

export const tabHistorialTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const respuesta = {
        clientes: true,
        pedidos: false,
      }

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Informacion de reservar no disponible",
    });
  }
};

export const toggleTabHistorial = (parametro) => (dispatch) => {
    let newObject = {};
    
    if (parametro === "clientes" ) { 
      newObject = {
        clientes: true,
        pedidos: false,
      } 
    } else if (parametro === "pedidos") {
      newObject = {
        clientes: false,
        pedidos: true,
      } 
    }

    dispatch({
        type: ACTUALIZAR,
        payload: newObject
    })
}