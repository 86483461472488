import axios from "axios";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const API_URL = `${URL_HOST}/api/v1/auth`;
const URL_REGISTER = `${URL_HOST}/api/v1/users`;
const URL_LOGIN = `${API_URL}/login`;
const URL_RECOVERY = `${API_URL}/recovery`;
const URL_CHANGE_PASSWORD = `${API_URL}/change-password`;
const URL_COMPANY = `${URL_HOST}/api/v1/company`;

export const traerCompanias = async () => {
  try {
    const rta = await axios.get(URL_COMPANY);
    const rtaData = rta.data;

    return rtaData;
  } catch (error) {
    console.log(error);
  }
};

export const registrarCompania = async (companyName) => {
  try {
    const newObject = {
      companyName: companyName,
    };
    const rta = await axios.post(URL_COMPANY, newObject);
    const rtaData = rta.data;
    return rtaData;
  } catch (error) {
    console.log(error);
  }
};

export const registerUser = async (data) => {
  const dataExample = {
    email: data.email,
    password: data.password,
    companyId: data.companyId,
    role: data.role,
  };

  const rta = await axios.post(URL_REGISTER, dataExample);
  const rtaData = await rta.data;

  return rtaData;
};

export const recovery = async (email) => {
  const newObject = {
    email: email,
  };
  const rta = await axios.post(URL_RECOVERY, newObject);
  const rtaData = await rta.data;
  return rta.data;
};

export const changePassword = async (newPassword, token) => {
  const newObject = {
    newPassword: newPassword,
    token: token,
  };

  const rta = await axios.post(URL_CHANGE_PASSWORD, newObject);
  const rtaData = await rta.data;

  return rtaData;
};

export const login = async (email, password) => {
  const rta = await axios.post(URL_LOGIN, { email, password });
  const rtaData = await rta.data;
  if (rtaData.token) {
    localStorage.setItem("user", JSON.stringify(rtaData));
  }
  return rtaData;
};

export const logout = () => {
  localStorage.removeItem("user");
};
