import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";

import "./Pedidos.css";
import {
  CardOrderInfoArreglos,
  CardOrderInfoBocaditos,
  CardOrderInfoTorta,
} from "../../components/Card/Card";
import Fatal from "../../components/Fatal/Fatal";
import Spinner from "../../components/Spinner/Spiner";
import * as pedidosActions from "../../redux/actions/pedidosActions";
import * as clientesActions from "../../redux/actions/clientesActions";
import * as reservarActions from "../../redux/actions/reservarActions.js";

const { pedidosTraerTodos } = pedidosActions;
const { clientesTraerTodos } = clientesActions;
const { reservarTraerTodos, toggleReservar } = reservarActions;

const Pedidos = (props) => {
  const {
    pedidosTraerTodos,
    clientesTraerTodos,
    reservarTraerTodos,
    toggleReservar,
  } = props;
  const { pedidos, error, cargando } = props.pedidosReducer;
  const { reservar } = props.reservarReducer;
  const { clientes } = props.clientesReducer;
  const { isLoggedIn } = props.cuentaReducer;

  if (!isLoggedIn) {
    props.history.push("/login");
  }

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    if (pedidos && !Object.keys(pedidos).length) {
      pedidosTraerTodos();
    }
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }
    if (!Object.keys(reservar).length) {
      reservarTraerTodos();
    }
  }, []);

  const traerCliente = (id) => {
    return clientes.clientes?.filter((cliente) => cliente.id === id)[0];
  };

  const getPedidosPorImportancia = () => {
    const hoy = new Date();
    hoy.setHours(0);
    hoy.setMinutes(0);
    hoy.setSeconds(0);
    const pedidosDeHoy = pedidos.pedidos?.filter(
      (pedido, indexPedido) => pedido.fecha.getTime() >= hoy.getTime()
    );
    return pedidosDeHoy;
  };

  const ponerPedidos = () => {
    const pedidosOrdenados = pedidos
      ? getPedidosPorImportancia()?.sort(
          (a, b) => a.fecha.getTime() - b.fecha.getTime()
        )
      : pedidos.pedidos;

    return pedidosOrdenados?.map((pedido, index) => {
      return pedido.tipoPedidoId === 1 ? (
        <CardOrderInfoTorta
          key={pedido.id}
          indexPedido={index}
          className={index % 2 === 0 ? "" : "card__client-info--black"}
          editColor={index % 2 === 0 ? "" : "edit--white"}
          infoColor={index % 2 === 0 ? "" : "info--white"}
          pedido={pedido}
          cliente={traerCliente(pedido.clienteId)}
          onClick={toggleReservar}
        />
      ) : pedido.tipoPedidoId === 2 ? (
        <CardOrderInfoBocaditos
          key={pedido.id}
          indexPedido={index}
          className={index % 2 === 0 ? "" : "card__client-info--black"}
          editColor={index % 2 === 0 ? "" : "edit--white"}
          infoColor={index % 2 === 0 ? "" : "info--white"}
          cliente={traerCliente(pedido.clienteId)}
          pedido={pedido}
          onClick={toggleReservar}
        />
      ) : pedido.tipoPedidoId === 3 ? (
        <CardOrderInfoArreglos
          key={pedido.id}
          indexPedido={index}
          className={index % 2 === 0 ? "" : "card__client-info--black"}
          editColor={index % 2 === 0 ? "" : "edit--white"}
          infoColor={index % 2 === 0 ? "" : "info--white"}
          cliente={traerCliente(pedido.clienteId)}
          pedido={pedido}
          onClick={toggleReservar}
        />
      ) : (
        <div></div>
      );
    });
  };

  return (
    <div className="pedidos">
      <div>
        <p className="pedidos__titulo-principal">Lista de Pedidos</p>
      </div>
      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        ponerPedidos()
      )}

      <div className="pedidos-btn">
        <button className="btn btn--small">Mostrar mas</button>
      </div>
    </div>
  );
};
const mapStateToProps = ({
  pedidosReducer,
  clientesReducer,
  reservarReducer,
  cuentaReducer,
}) => {
  return { pedidosReducer, clientesReducer, reservarReducer, cuentaReducer };
};

const mapDispatchToProps = {
  pedidosTraerTodos,
  clientesTraerTodos,
  reservarTraerTodos,
  toggleReservar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pedidos);
