
import { CARGANDO, ERROR, TRAER_TODOS, ACTUALIZAR } from "../types/TabProductosTypes";

export const tabProductosTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const respuesta = {
        torta: false,
        bocaditos: false,
        arreglos: true,
      }

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Informacion de reservar no disponible",
    });
  }
};

export const toggleTabProductos = (parametro) => (dispatch) => {
    let newObject = {};
    
    if (parametro === "torta" ) { newObject = {
        torta: true,
        bocaditos: false,
        arreglos: false,
    } } else if (
    parametro === "bocaditos") {
    newObject = {
        torta: false,
        bocaditos: true,
        arreglos: false,
    } } else if (
    parametro === "arreglos") {
    newObject = {
        torta: false,
        bocaditos: false,
        arreglos: true,
    }}

    dispatch({
        type: ACTUALIZAR,
        payload: newObject
    })
}