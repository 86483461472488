import React from "react";
import { Link } from "react-router-dom";

import "./Card.css";

export const CardDeliveryInfo = ({
  className,
  ubicacion,
  idCliente,
  ubicacionIndex,
  editColor,
  mapColor,
}) => {
  return (
    <div className={`card card__delivery-info ${className}`}>
      <div className=" card__inner card__delivery-info__inner">
        <div className="container-grid-dos-grande">
          <div>
            <p className="titulo">Calles</p>
            <p className="data">{ubicacion.calles}</p>
          </div>

          <div>
            <p className="titulo">Numero de casa</p>
            <p className="data">{ubicacion.numeroCasa}</p>
          </div>
        </div>

        <div className="container-grid-dos-mapa">
          <div>
            <p className="titulo">Referencia</p>
            <p className="data">{ubicacion.referencia}</p>
          </div>

          <div>
            <svg className={`map ${mapColor}`} />
          </div>
        </div>

        <Link
          to={{
            pathname: `/editar-ubicacion/${idCliente}/${ubicacion?.id}`,
            state: {
              ubicacion: ubicacion,
              ubicacionIndex: ubicacionIndex,
            },
          }}
        >
          <svg className={`edit ${editColor}`} />
        </Link>
      </div>
    </div>
  );
};

export const CardOrderInfoTorta = ({
  cliente,
  pedido,
  className,
  editColor,
  infoColor,
  indexPedido,
  onClick,
}) => {
  return (
    <div className={`card card__order-info ${className}`}>
      <Link
        onClick={() => onClick("arreglos")}
        to={{
          pathname: `/detalle-de-pedido/${pedido.id}`,
          state: {
            indexPedido: indexPedido,
          },
        }}
      >
        <svg className={`info ${infoColor}`} />
      </Link>
      <div className="card__inner card__order-info__inner">
        <div className="container-grid-dos">
          <div>
            <p className="titulo">Cliente</p>
            <Link className="detalle-de-pedido__link" to="/cliente">
              <p className="data">{`${cliente?.nombre} ${cliente?.apellido}`}</p>
            </Link>
          </div>

          <div>
            <p className="titulo">Fecha</p>
            <p className="data">
              {pedido?.fecha &&
                `${pedido.fecha.getDate() + 1}/${
                  pedido.fecha.getMonth() + 1
                }/${pedido.fecha.getFullYear()}`}
            </p>
          </div>
        </div>

        <div className="container-grid-dos">
          {pedido?.tipoTortas.map((torta, index) => (
            <div key={torta.id} className="container-grid-dos-pequeño-torta">
              <div>
                <p className="titulo">Tamaño</p>
                <p className="data">{torta.tamano}</p>
              </div>
              {torta.tipoTortaParametros?.map((parametro) => (
                <div key={parametro.id}>
                  <p className="titulo">{parametro.parametro}</p>
                  <p className="data">{parametro.valor}</p>
                </div>
              ))}
              <hr className="reservas-arreglos__line"></hr>
            </div>
          ))}

          <div className="container-grid-dos-pequeño">
            <div>
              <p className="titulo">Hora</p>
              <p className="data">{pedido?.hora}</p>
            </div>

            <div>
              <p className="titulo">Precio</p>
              <p className="data">{pedido?.precio}</p>
            </div>
          </div>
        </div>

        <div>
          <p className="titulo">Tema</p>
          <p className="data">{pedido?.tema}</p>
        </div>
        <div>
          <p className="titulo">Notas</p>
          <p className="data">{pedido?.notas}</p>
        </div>
        <Link
          onClick={() => onClick("torta")}
          to={{
            pathname: `/reservar/${pedido.id}`,
            state: {
              indexPedido: indexPedido,
              pedido: pedido,
            },
          }}
        >
          <svg className={`edit ${editColor}`} />
        </Link>
      </div>
    </div>
  );
};
export const CardOrderInfoBocaditos = ({
  cliente,
  pedido,
  className,
  editColor,
  infoColor,
  indexPedido,
  onClick,
}) => {
  return (
    <div className={`card card__order-info ${className}`}>
      <Link
        onClick={() => onClick("arreglos")}
        to={{
          pathname: `/detalle-de-pedido/${pedido.id}`,
          state: {
            indexPedido: indexPedido,
          },
        }}
      >
        <svg className={`info ${infoColor}`} />
      </Link>
      <div className="card__inner card__order-info-bocadito__inner">
        <div className="container-grid-dos">
          <div>
            <p className="titulo">Cliente</p>
            <Link className="detalle-de-pedido__link" to="/cliente">
              <p className="data">{`${cliente?.nombre} ${cliente?.apellido}`}</p>
            </Link>
          </div>

          <div>
            <p className="titulo">Fecha</p>
            <p className="data">
              {pedido.fecha &&
                `${pedido.fecha.getDate() + 1}/${
                  pedido.fecha.getMonth() + 1
                }/${pedido.fecha.getFullYear()}`}
            </p>
          </div>
        </div>

        <div className="container-grid-dos">
          <div>
            <p className="titulo">Precio</p>
            <p className="data">{pedido?.precio}</p>
          </div>

          <div>
            <p className="titulo">Hora</p>
            <p className="data">{pedido?.hora}</p>
          </div>
        </div>
        {pedido?.tipoBocaditos?.map((bocadito, indexBocadito) => (
          <div key={indexBocadito}>
            <div className="container-grid-tres">
              <div className="">
                <p className="titulo">Bocadito</p>
                <p className="data">{bocadito?.bocadito}</p>
              </div>

              <div>
                <p className="titulo">Cant</p>
                <p className="data">{bocadito?.cantidad}</p>
              </div>

              {bocadito.tipoBocaditosParametros.map(
                (parametro, indexParametro) => (
                  <div key={indexParametro}>
                    <p className="titulo">{parametro?.parametro}</p>
                    <p className="data">{parametro?.valor}</p>
                  </div>
                )
              )}
            </div>
            <hr className="line-orders"></hr>
          </div>
        ))}
        <div>
          <p className="titulo">Notas</p>
          <p className="data">{pedido?.notas}</p>
        </div>

        <Link
          onClick={() => onClick("bocaditos")}
          to={{
            pathname: `/reservar/${pedido.id}`,
            state: {
              indexPedido: indexPedido,
              pedido: pedido,
            },
          }}
        >
          <svg className={`edit ${editColor}`} />
        </Link>
      </div>
    </div>
  );
};
export const CardOrderInfoArreglos = ({
  cliente,
  pedido,
  className,
  editColor,
  infoColor,
  indexPedido,
  onClick,
}) => {
  return (
    <div className={`card card__order-info ${className}`}>
      <Link
        onClick={() => onClick("arreglos")}
        to={{
          pathname: `/detalle-de-pedido/${pedido.id}`,
          state: {
            indexPedido: indexPedido,
          },
        }}
      >
        <svg className={`info ${infoColor}`} />
      </Link>
      <div className="card__inner card__order-info-bocadito__inner">
        <div className="container-grid-dos">
          <div className="">
            <p className="titulo">Cliente</p>
            <Link className="detalle-de-pedido__link" to="/cliente">
              <p className="data">{`${cliente?.nombre} ${cliente?.apellido}`}</p>
            </Link>
          </div>

          <div className="">
            <p className="titulo">Fecha</p>
            <p className="data">
              {pedido.fecha &&
                `${pedido.fecha.getDate() + 1}/${
                  pedido.fecha.getMonth() + 1
                }/${pedido.fecha.getFullYear()}`}
            </p>
          </div>
        </div>

        <div className="container-grid-dos">
          <div className="">
            <p className="titulo">Precio</p>
            <p className="data">{pedido?.precio}</p>
          </div>

          <div className="">
            <p className="titulo">Hora</p>
            <p className="data">{pedido?.hora}</p>
          </div>
        </div>

        {pedido?.tipoDeArreglos.map((arreglo, indexArreglo) => {
          return arreglo.tipo === "Caja cupcakes" ? (
            <div key={arreglo.id}>
              <hr className="line-orders"></hr>
              {arreglo.tipoArregloCajasCupcakes.map((cupcake, indexCupcake) => (
                <div key={cupcake.id} className="container-grid-arreglo">
                  <div className="container-grid-arreglo-item">
                    <div>
                      <p className="titulo">Tipo</p>
                      <p className="data">{arreglo?.tipo}</p>
                    </div>

                    <div>
                      <p className="titulo">Cant Cupcakes</p>
                      <p className="data">{cupcake?.cantidad}</p>
                    </div>
                  </div>
                  {cupcake.parametros?.map((parametro, indexParametro) => {
                    return (
                      <div
                        key={parametro.id}
                        className="container-grid-arreglo-item"
                      >
                        <div className="container-grid-arreglo-item">
                          <div>
                            <p className="titulo">
                              {parametro.para || parametro.parametro}
                            </p>
                            <p className="data">
                              {parametro.valo || parametro.valor}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          ) : arreglo.tipo === "Caja de dulces" ? (
            <div key={arreglo.id}>
              <hr className="line-orders"></hr>
              <div className="container-grid-arreglo-caja-dulces">
                <div className="container-grid-arreglo-item-caja-dulces">
                  <div>
                    <p className="titulo">Tipo</p>
                    <p className="data">{arreglo?.tipo}</p>
                  </div>
                </div>
                <div>
                  {arreglo.tipoArregloCajasDulces.map(
                    (bocadito, indexBocadito) => {
                      return (
                        <div
                          key={bocadito.id}
                          className="container-grid-arreglo-item-caja-dulces"
                        >
                          <div>
                            <p className="titulo">Bocadito</p>
                            <p className="data">{bocadito?.bocadito}</p>
                          </div>
                          <div>
                            <p className="titulo">Cant</p>
                            <p className="data">{bocadito?.cantidad}</p>
                          </div>
                          {bocadito.parametros.map(
                            (parametro, indexParametro) => {
                              return (
                                <div key={parametro.id}>
                                  <p className="titulo">
                                    {parametro.parame || parametro.parametro}
                                  </p>
                                  <p className="data">{parametro?.valor}</p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          ) : arreglo.tipo === "Desayuno" ? (
            <div key={arreglo.id}>
              <hr className="line-orders"></hr>
              <div className="container-grid-arreglo">
                <div className="container-grid-arreglo-item">
                  <div>
                    <p className="titulo">Tipo</p>
                    <p className="data">{arreglo?.tipo}</p>
                  </div>
                </div>
              </div>
              {arreglo.tipoArregloDesayunos.map((tipoProducto, indexProducto) =>
                tipoProducto.productos.map((producto) => {
                  return (
                    <div key={producto.id} className="container-grid-arreglo">
                      <div>
                        <p className="titulo">{tipoProducto?.tipoDeProducto}</p>
                        <p className="data">{producto?.producto}</p>
                      </div>
                      <div>
                        <p className="titulo">cant</p>
                        <p className="data">{producto?.cantidad}</p>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ) : (
            ""
          );
        })}

        <hr className="line-orders"></hr>

        <div className="container-grid-arreglo-tema">
          <p className="titulo">Tema</p>
          <p className="tema-data ">{pedido?.tema}</p>
        </div>
        <div>
          <p className="titulo">Notas</p>
          <p className="tema-data ">{pedido?.notas}</p>
        </div>
        <Link
          onClick={() => onClick("arreglos")}
          to={{
            pathname: `/reservar/${pedido.id}`,
            state: {
              indexPedido: indexPedido,
              pedido: pedido,
            },
          }}
        >
          <svg className={`edit ${editColor}`} />
        </Link>
      </div>
    </div>
  );
};
export const CardAllOrderInfoTorta = ({
  pedido,
  ubicacion,
  className,
  cliente,
  onClick,
  indexPedido,
  mapColor,
  editColor,
}) => {
  return (
    <div className={`card card__all-order-info ${className}`}>
      <div className="card__inner card__all-order-info__inner-client">
        <div className="container-grid-dos">
          <div>
            <p className="titulo">Cliente</p>
            <Link className="detalle-de-pedido__link" to="/cliente">
              <p className="data">{cliente.nombre}</p>
            </Link>
          </div>

          <div>
            <p className="titulo">Telefono</p>
            <p className="data">{cliente?.telefono}</p>
          </div>
        </div>

        <div className="container-grid-dos">
          <div>
            <p className="titulo">Fecha</p>
            <p className="data">
              {pedido?.fecha !== ""
                ? `${pedido?.fecha.getDate()}/${pedido?.fecha.getMonth()}/${pedido?.fecha.getFullYear()}`
                : ""}
            </p>
          </div>

          <div className="container-grid-dos-pequeño">
            <div>
              <p className="titulo">Hora</p>
              <p className="data">{pedido.hora}</p>
            </div>

            <div>
              <p className="titulo">Precio</p>
              <p className="data">{pedido.precio}</p>
            </div>
          </div>
        </div>
      </div>

      <hr className="line"></hr>

      <div className="card__inner card__all-order-info__inner-order">
        {pedido?.tipoTortas.map((torta, index) => (
          <div key={torta.id}>
            <div className="container-grid-tres-torta">
              <div>
                <p className="titulo">Tamaño</p>
                <p className="data">{torta.tamano}</p>
              </div>
              {torta.tipoTortaParametros?.map((parametro) => (
                <div key={parametro.id}>
                  <p className="titulo">{parametro.parametro}</p>
                  <p className="data">{parametro.valor}</p>
                </div>
              ))}
            </div>
            <hr className="reservas-arreglos__line"></hr>
          </div>
        ))}

        <div>
          <p className="titulo">Tema</p>
          <p className="data">{pedido.tema}</p>
        </div>

        <div>
          <p className="titulo">Nota</p>
          <p className="data">{pedido.notas}</p>
        </div>
      </div>
      <div className="edit-container">
        <hr className="line"></hr>
      </div>

      <div className=" card__inner card__delivery-info__inner">
        <div className="container-grid-dos-grande">
          <div>
            <p className="titulo">Calles</p>
            <p className="data">{ubicacion?.calles}</p>
          </div>

          <div>
            <p className="titulo">Numero de casa</p>
            <p className="data">{ubicacion?.numeroCasa}</p>
          </div>
        </div>

        <div className="container-grid-dos-mapa">
          <div>
            <p className="titulo">Referencia</p>
            <p className="data">{ubicacion?.referencia}</p>
          </div>

          <div>
            <svg className={`map ${mapColor}`} />
          </div>
        </div>

        <Link
          onClick={() => onClick("torta")}
          to={{
            pathname: `/reservar/${pedido.id}`,
            state: {
              indexPedido: indexPedido,
              pedido: pedido,
            },
          }}
        >
          <svg className={`edit ${pedido.id}`} />
        </Link>
      </div>
    </div>
  );
};
export const CardAllOrderInfoBocaditos = ({
  pedido,
  ubicacion,
  className,
  cliente,
  onClick,
  indexPedido,
  mapColor,
  editColor,
}) => {
  return (
    <div className={`card card__all-order-info ${className}`}>
      <div className="card__inner card__all-order-info__inner-client">
        <div className="container-grid-dos">
          <div>
            <p className="titulo">Cliente</p>
            <Link className="detalle-de-pedido__link" to="/cliente">
              <p className="data">{cliente?.nombre}</p>
            </Link>
          </div>

          <div>
            <p className="titulo">Telefono</p>
            <p className="data">{cliente.telefono}</p>
          </div>
        </div>

        <div className="container-grid-dos">
          <div>
            <p className="titulo">Fecha</p>
            <p className="data">
              {pedido?.fecha !== ""
                ? `${pedido?.fecha.getDate()}/${pedido?.fecha.getMonth()}/${pedido?.fecha.getFullYear()}`
                : ""}
            </p>
          </div>

          <div className="container-grid-dos-pequeño">
            <div>
              <p className="titulo">Hora</p>
              <p className="data">{pedido.hora}</p>
            </div>

            <div>
              <p className="titulo">Precio</p>
              <p className="data">{pedido.precio}</p>
            </div>
          </div>
        </div>
      </div>

      <hr className="line"></hr>

      <div className="card__inner card__all-order-info__inner-order">
        {pedido?.tipoBocaditos.map((bocadito, indexBocadito) => (
          <div key={indexBocadito}>
            <div className="container-grid-tres">
              <div className="">
                <p className="titulo">Bocadito</p>
                <p className="data">{bocadito?.bocadito}</p>
              </div>

              <div>
                <p className="titulo">Cant</p>
                <p className="data">{bocadito?.cantidad}</p>
              </div>

              {bocadito.tipoBocaditosParametros.map(
                (parametro, indexParametro) => (
                  <div key={indexParametro}>
                    <p className="titulo">{parametro?.parametro}</p>
                    <p className="data">{parametro?.valor}</p>
                  </div>
                )
              )}
            </div>
            <hr className="line-orders"></hr>
          </div>
        ))}
        <div>
          <p className="titulo">Nota</p>
          <p className="data">{pedido.notas}</p>
        </div>
      </div>
      <div className="edit-container"></div>
      <hr className="line"></hr>

      <div className=" card__inner card__delivery-info__inner">
        <div className="container-grid-dos-grande">
          <div>
            <p className="titulo">Calles</p>
            <p className="data">{ubicacion?.calles}</p>
          </div>

          <div>
            <p className="titulo">Numero de casa</p>
            <p className="data">{ubicacion?.numeroCasa}</p>
          </div>
        </div>

        <div className="container-grid-dos-mapa">
          <div>
            <p className="titulo">Referencia</p>
            <p className="data">{ubicacion?.referencia}</p>
          </div>

          <div>
            <svg className={`map ${mapColor}`} />
          </div>
        </div>

        <Link
          onClick={() => onClick("bocaditos")}
          to={{
            pathname: `/reservar/${pedido.id}`,
            state: {
              indexPedido: indexPedido,
              pedido: pedido,
            },
          }}
        >
          <svg className={`edit ${editColor}`} />
        </Link>
      </div>
    </div>
  );
};
export const CardAllOrderInfoArreglos = ({
  pedido,
  ubicacion,
  className,
  cliente,
  onClick,
  indexPedido,
  mapColor,
  editColor,
}) => {
  return (
    <div className={`card card__all-order-info ${className}`}>
      <div className="card__inner card__all-order-info__inner-client">
        <div className="container-grid-dos">
          <div>
            <p className="titulo">Cliente</p>
            <Link className="detalle-de-pedido__link" to="/cliente">
              <p className="data">{cliente?.nombre}</p>
            </Link>
          </div>

          <div>
            <p className="titulo">Telefono</p>
            <p className="data">{cliente.telefono}</p>
          </div>
        </div>

        <div className="container-grid-dos">
          <div>
            <p className="titulo">Fecha</p>
            <p className="data">
              {pedido?.fecha !== ""
                ? `${pedido?.fecha.getDate()}/${pedido?.fecha.getMonth()}/${pedido?.fecha.getFullYear()}`
                : ""}
            </p>
          </div>

          <div className="container-grid-dos-pequeño">
            <div>
              <p className="titulo">Hora</p>
              <p className="data">{pedido.hora}</p>
            </div>

            <div>
              <p className="titulo">Precio</p>
              <p className="data">{pedido.precio}</p>
            </div>
          </div>
        </div>
      </div>

      <hr className="line"></hr>

      <div className="card__inner card__all-order-info__inner-order">
        {pedido?.tipoDeArreglos.map((arreglo, indexArreglo) => {
          return arreglo.tipo === "Caja cupcakes" ? (
            <div key={arreglo.id}>
              {indexArreglo === 0 ? null : <hr className="line-orders"></hr>}
              {arreglo.tipoArregloCajasCupcakes.map((cupcake, indexCupcake) => (
                <div key={cupcake.id} className="container-grid-arreglo">
                  <div className="container-grid-arreglo-item">
                    <div>
                      <p className="titulo">Tipo</p>
                      <p className="data">{arreglo?.tipo}</p>
                    </div>

                    <div>
                      <p className="titulo">Cant Cupcakes</p>
                      <p className="data">{cupcake?.cantidad}</p>
                    </div>
                  </div>
                  {cupcake.parametros?.map((parametro, indexParametro) => {
                    return (
                      <div
                        key={parametro.id}
                        className="container-grid-arreglo-item"
                      >
                        <div className="container-grid-arreglo-item">
                          <div>
                            <p className="titulo">
                              {parametro.para || parametro.parametro}
                            </p>
                            <p className="data">
                              {parametro.valo || parametro.valor}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          ) : arreglo.tipo === "Caja de dulces" ? (
            <div key={arreglo.id}>
              {indexArreglo === 0 ? null : <hr className="line-orders"></hr>}

              <div className="container-grid-arreglo-caja-dulces">
                <div className="container-grid-arreglo-item-caja-dulces">
                  <div>
                    <p className="titulo">Tipo</p>
                    <p className="data">{arreglo?.tipo}</p>
                  </div>
                </div>
                <div>
                  {arreglo.tipoArregloCajasDulces.map(
                    (bocadito, indexBocadito) => {
                      return (
                        <div
                          key={bocadito.id}
                          className="container-grid-arreglo-item-caja-dulces"
                        >
                          <div>
                            <p className="titulo">Bocadito</p>
                            <p className="data">{bocadito?.bocadito}</p>
                          </div>
                          <div>
                            <p className="titulo">Cant</p>
                            <p className="data">{bocadito?.cantidad}</p>
                          </div>
                          {bocadito.parametros.map(
                            (parametro, indexParametro) => {
                              return (
                                <div key={parametro.id}>
                                  <p className="titulo">
                                    {parametro.parame || parametro.parametro}
                                  </p>
                                  <p className="data">{parametro?.valor}</p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          ) : arreglo.tipo === "Desayuno" ? (
            <div key={arreglo.id}>
              {indexArreglo === 0 ? null : <hr className="line-orders"></hr>}

              <div className="container-grid-arreglo">
                <div className="container-grid-arreglo-item">
                  <div>
                    <p className="titulo">Tipo</p>
                    <p className="data">{arreglo?.tipo}</p>
                  </div>
                </div>
              </div>
              {arreglo.tipoArregloDesayunos.map((tipoProducto, indexProducto) =>
                tipoProducto.productos.map((producto) => {
                  return (
                    <div key={producto.id} className="container-grid-arreglo">
                      <div>
                        <p className="titulo">{tipoProducto?.tipoDeProducto}</p>
                        <p className="data">{producto?.producto}</p>
                      </div>
                      <div>
                        <p className="titulo">cant</p>
                        <p className="data">{producto?.cantidad}</p>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ) : (
            ""
          );
        })}
        <div className="">
          <p className="titulo">Tema</p>
          <p className="data">{pedido.tema}</p>
        </div>

        <div>
          <p className="titulo">Nota</p>
          <p className="data">{pedido.notas}</p>
        </div>
      </div>
      <div className="edit-container">
        <hr className="line"></hr>
      </div>

      <div className=" card__inner card__delivery-info__inner">
        <div className="container-grid-dos-grande">
          <div>
            <p className="titulo">Calles</p>
            <p className="data">{ubicacion?.calles}</p>
          </div>

          <div>
            <p className="titulo">Numero de casa</p>
            <p className="data">{ubicacion?.numeroCasa}</p>
          </div>
        </div>

        <div className="container-grid-dos-mapa">
          <div>
            <p className="titulo">Referencia</p>
            <p className="data">{ubicacion?.referencia}</p>
          </div>

          <div>
            <svg className={`map ${mapColor}`} />
          </div>
        </div>

        <Link
          onClick={() => onClick("arreglos")}
          to={{
            pathname: `/reservar/${pedido.id}`,
            state: {
              indexPedido: indexPedido,
              pedido: pedido,
            },
          }}
        >
          <svg className={`edit ${editColor}`} />
        </Link>
      </div>
    </div>
  );
};
