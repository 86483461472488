import React from "react";
import { useForm } from "react-hook-form";
import { registrarCompania } from "../../../services/auth.service";

import "./AgregarCompania.css";

const AgregarCompania = (props) => {
  const { register, handleSubmit } = useForm();

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (data) => {
          await registrarCompania(data.companyName);
          await props.history.push("/register");
        })}
      >
        <div className="recover-password__item-titulo">
          <p className="recover-password__titulo-principal">Forgot password</p>
        </div>
        <div className="recover-password__item-container">
          <div className="recover-password__item">
            <p className="recover-password__titulo-secundario">
              Nombre de la Compañia
            </p>
            <input
              {...register("companyName", { required: true })}
              name="companyName"
              id="companyName"
              type="companyName"
              className="input input--xxlarge"
            />
          </div>

          <div className="recover-password__btn">
            <button className="btn btn--large-short">Registrar compañia</button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AgregarCompania;
