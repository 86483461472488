import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";

import "./ReservarTorta.css";
import { Button } from "../../../components/Button/Button.jsx";

const ReservarTorta = (props) => {
  const history = useHistory();

  const {
    companyId,
    id,
    agregarCliente,
    agregarUbicacion,
    editarPedido,
    eliminarPedido,
    agregarTipoTorta,
    eliminarTipoTorta,
    clientes,
    clienteActual,
    pedidos,
    pedido,
    ubicacion,
    torta1,
  } = props;

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const [editarCliente, setEditarCliente] = useState(false);
  const [editarUbicacion, setEditarUbicacion] = useState(false);
  const [numeroTortas, setNumeroTortas] = useState([
    {
      pedidoId: 1,
      tamano: "",
      tipoTortaParametros: torta1.editar?.map((parametro) => ({
        tipoTortaId: 1,
        parametro: parametro.parametro,
        valor: "",
      })),
    },
  ]);
  const [numeroClicks, setNumeroClicks] = useState(0);

  const pedidoId = pedido?.id || `array${pedidos.pedidos?.length}`;

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    // formState: { errors },
  } = useForm();

  useEffect(() => {
    if (
      watch("cliente") !== undefined &&
      Number(watch("cliente")) !== clienteSeleccionado?.id
    ) {
      setClienteSeleccionado(
        clientes.clientes?.filter(
          (cliente) => cliente.id === Number(watch("cliente"))
        )[0]
      );
    }
  }, [clienteActual, watch(), clientes.clientes]);

  /* empieza logica de cliente y ubicacion */

  const ubicacionSeleccionada = clienteSeleccionado?.ubicaciones.filter(
    (ubicacion, index) => ubicacion.id === Number(watch("ubicacion"))
  )[0];

  const indexUbicacionSeleccionada =
    clienteSeleccionado && ubicacionSeleccionada
      ? clienteSeleccionado[0]?.ubicaciones.findIndex(
          (ubicacion) =>
            ubicacion.numeroCasa === ubicacionSeleccionada[0]?.numeroCasa
        )
      : "";

  const traerInfoNuevoCliente = () => {
    const indexNuevoCliente = clientes.clientes?.length - 1;

    const nuevoClienteInfo = clientes.clientes
      ? clientes.clientes[indexNuevoCliente]
      : "";

    return nuevoClienteInfo;
  };

  const agregarNuevoCliente = () => {
    agregarCliente();
    setEditarCliente(true);
  };

  const agregarNuevaUbicacion = () => {
    agregarUbicacion(clienteSeleccionado.id);
    setEditarUbicacion(true);
  };

  /* termina logica de cliente y ubicacion */

  /* empieza logica de Tamaño de torta */

  const ponerOpcionesTorta = (tipoTorta, indexTorta) => {
    return (
      <div key={tipoTorta.id} className="center-reservas-porciones">
        <select
          {...register(
            tipoTorta.id === undefined
              ? `opcioneTamanoArray${indexTorta}`
              : `opcioneTamano${tipoTorta.id}`,
            { valueAsNumber: true }
          )}
          id={`opcioneTamano${tipoTorta.id}`}
          className="select input--large"
        >
          <option value={tipoTorta.tamano}>{tipoTorta.tamano}</option>
          {torta1.porciones?.datos.map((opcionTamano, index) => (
            <option key={index} value={opcionTamano.tamano}>
              {opcionTamano.tamano}
            </option>
          ))}
        </select>
        <svg
          onClick={() =>
            eliminarTipoTorta(pedidoId, tipoTorta.id || indexTorta)
          }
          className="delete"
        />
      </div>
    );
  };

  const ponerOpcionesTorta1 = () =>
    numeroTortas.map((elemento, index) => {
      return (
        <div key={`array${index}`} className="center-reservas-porciones">
          <select
            {...register(`opcioneTamanoArray${index}`, { valueAsNumber: true })}
            id={`opcioneTamano${index}`}
            className="select input--large"
          >
            <option value="">tamaños...</option>
            {torta1.porciones?.datos.map((opcionTamano, index) => (
              <option key={index} value={opcionTamano.tamano}>
                {opcionTamano.tamano}
              </option>
            ))}
          </select>
          <svg onClick={() => eliminarOpcionTorta(index)} className="delete" />
        </div>
      );
    });

  const agregarOpcionTorta = () => {
    if (pedido && pedido.tipoPedidoId === 1) {
      agregarTipoTorta(pedidoId, asignar(getValues()));
      setNumeroClicks(numeroClicks + 1);
    } else {
      setNumeroTortas((prev) => [
        ...prev,
        {
          pedidoId: 1,
          tamano: "",
          tipoTortaParametros: torta1.editar?.map((parametro) => ({
            tipoTortaId: 1,
            parametro: parametro.parametro,
            valor: "",
          })),
        },
      ]);
      // agregarTipoTorta(pedidoId, asignar(getValues()));

      // setNumeroTortas([...numeroTortas, ""]);
    }
  };

  const eliminarOpcionTorta = (index) => {
    if (pedido) {
      pedido.tortas = [
        ...pedido.tortas.slice(0, index),
        ...pedido.tortas.slice(index + 1),
      ];
    }
    const newArray = [
      ...numeroTortas.slice(0, index),
      ...numeroTortas.slice(index + 1),
    ];
    setNumeroTortas(newArray);
  };

  /* termina logica de Tamaño de torta */

  /* empieza logica de parametros de torta */

  const tortaParametro = (parametro1) => {
    return torta1.editar?.filter(
      (parametro) => parametro.parametro === parametro1
    )[0];
  };

  const ponerParametrosTortas = (tortaPedido, indexTorta) => {
    let opcion = "";
    if (tortaPedido.id === undefined) {
      opcion = `opcioneTamanoArray${indexTorta}`;
    } else {
      opcion = `opcioneTamano${tortaPedido?.id}`;
    }
    let opciones = "";
    if (getValues(opcion) !== undefined) {
      if (tortaPedido.id === undefined) {
        opciones = torta1.editar.map((elemento) => {
          return (
            <div key={`array${elemento.id}`}>
              <label
                htmlFor={`${elemento.parametro}${getValues(
                  opcion
                )}cmArray${indexTorta}`}
                className="titulo-reservas"
              >
                {elemento.parametro} {getValues(opcion)}cm
              </label>
              <select
                {...register(
                  `${elemento.parametro}${getValues(
                    opcion
                  )}cmArray${indexTorta}`
                )}
                id={`${elemento.parametro}${getValues(
                  opcion
                )}cmArray${indexTorta}`}
                className="select input--large"
              >
                <option value="">{elemento.parametro}...</option>
                {elemento.datos
                  ? elemento.datos.map((dato) => (
                      <option key={dato.id} value={dato.valor}>
                        {dato.valor}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
          );
        });
      } else {
        opciones = tortaPedido.tipoTortaParametros?.map((parametro) => (
          <div key={parametro.id}>
            <label
              htmlFor={`${parametro.parametro}${getValues(opcion)}cm${
                parametro.id
              }`}
              className="titulo-reservas"
            >
              {parametro.parametro} {getValues(opcion)}cm
            </label>
            <select
              {...register(
                `${parametro.parametro}${getValues(opcion)}cm${parametro.id}`
              )}
              id={`${parametro.parametro}${getValues(opcion)}cm${parametro.id}`}
              className="select input--large"
            >
              <option value={parametro.valor}>{parametro.valor}</option>
              {tortaParametro(parametro.parametro)?.datos?.map((dato) => (
                <option key={dato.id} value={dato.valor}>
                  {dato.valor}
                </option>
              ))}
            </select>
          </div>
        ));
      }
      return (
        <div>
          {opciones}
          <hr className="reservas-arreglos__line"></hr>
        </div>
      );
    }
  };

  const ponerParametrosTortas1 = () =>
    numeroTortas.map((num, index) => {
      const opcion = `opcioneTamanoArray${index}`;
      if (getValues(opcion) !== undefined && !isNaN(getValues(opcion))) {
        const opciones = torta1.editar?.map((elemento) => {
          return (
            <div key={elemento.id}>
              <label
                htmlFor={`${elemento.parametro}${getValues(opcion)}${index}`}
                className="titulo-reservas"
              >
                {elemento.parametro} {getValues(opcion)}cm
              </label>
              <select
                {...register(
                  `${elemento.parametro}${getValues(opcion)}Array${index}`
                )}
                id={`${elemento.parametro}${getValues(opcion)}Array${index}`}
                className="select input--large"
              >
                <option value="">{elemento.parametro}...</option>
                {elemento.datos
                  ? elemento.datos.map((dato) => (
                      <option key={dato.id} value={dato.valor}>
                        {dato.valor}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
          );
        });
        return (
          <div>
            {opciones}
            <hr className="reservas-arreglos__line"></hr>
          </div>
        );
      }
    });
  /* termina logica de parametros de torta */

  /* comienza logica de precio */

  const tortaActual = (tamanoTorta) => {
    const tortaActual = torta1.porciones
      ? torta1.porciones.datos.filter((tamano) => tamano.tamano === tamanoTorta)
      : "";
    return tortaActual;
  };

  const obtenerPrecio = () => {
    let precio = 0;

    pedido && pedido.tipoPedidoId === 1
      ? pedido.tipoTortas?.map((tortasPedidos, indexPedidos) => {
          let opcion = "";
          if (tortasPedidos.id !== undefined) {
            opcion = getValues(`opcioneTamano${tortasPedidos.id}`);
          } else {
            opcion = getValues(`opcioneTamanoArray${indexPedidos}`);
          }
          precio = precio + Number(tortaActual(opcion)[0]?.precio);
        })
      : numeroTortas.map((elemento, index) => {
          const opcion = getValues(`opcioneTamanoArray${index}`);
          precio = precio + Number(tortaActual(opcion)[0]?.precio);
        });
    return precio;
  };

  /* termina logica de precio */

  const obtenerTortaActual = (data) => {
    let array = [];
    if (pedido) {
      pedido.tipoTortas?.forEach((tortaPedido, indexTipoTorta) => {
        const opcion =
          tortaPedido.id === undefined
            ? `opcioneTamanoArray${indexTipoTorta}`
            : `opcioneTamano${tortaPedido.id}`;

        array.push({
          id: tortaPedido.id || null,
          pedidoId: pedidoId,
          tamano: data[opcion],
          tipoTortaParametros:
            tortaPedido.tipoTortaParametros.length !== 0
              ? tortaPedido.tipoTortaParametros.map((parametro) => ({
                  id: parametro.id,
                  tipoTortaId: tortaPedido.id || 1,
                  parametro: parametro.parametro,
                  valor:
                    data[
                      `${parametro.parametro}${getValues(opcion)}cm${
                        parametro.id
                      }`
                    ],
                }))
              : torta1.editar.map((parametro) => ({
                  tipoTortaId: tortaPedido.id || 1,
                  parametro: parametro.parametro,
                  valor:
                    data[
                      `${parametro.parametro}${getValues(
                        opcion
                      )}cmArray${indexTipoTorta}`
                    ],
                })),
        });
      });
    } else {
      numeroTortas.forEach((tortaPedido, indexTipoTorta) => {
        const opcion = `opcioneTamanoArray${indexTipoTorta}`;

        array.push({
          id: tortaPedido.id || null,
          pedidoId: 1,
          tamano: data[opcion],
          tipoTortaParametros: torta1.editar.map((parametro) => ({
            tipoTortaId: tortaPedido.id || 1,
            parametro: parametro.parametro,
            valor:
              data[
                `${parametro.parametro}${getValues(
                  opcion
                )}Array${indexTipoTorta}`
              ],
          })),
        });
      });
    }

    array.forEach((element) => {
      if (element.id === null) {
        delete element.id;
      }
    });

    return array;
  };

  const asignar = (data) => {
    const fecha = data["fecha"];

    const objeto = {
      companyId,
      clienteId: data["cliente"],
      ubicacionId: data["ubicacion"],
      fecha: fecha,
      hora: data["hora"],
      id: pedido?.id || null,
      precio: isNaN(data["miPrecio"])
        ? obtenerPrecio().toFixed(2)
        : data.miPrecio.toFixed(2),
      tipoTortas: obtenerTortaActual(data),
      // tipoBocaditos: pedido.tipoBocaditos,
      // tipoDeArreglos: pedido.tipoDeArreglos,
      tema: data["tema"],
      notas: data["notas"],
      tipoPedidoId: 1,
    };
    if (objeto.id === null) {
      delete objeto.id;
    }

    return objeto;
  };

  return (
    <div className="reservar-torta">
      {id !== undefined && pedido && clienteActual && ubicacion && torta1 ? (
        <form
          onSubmit={handleSubmit((data) => {
            editarPedido(asignar(data), pedidoId);
            history.push("/pedidos");
          })}
        >
          <div>
            <label htmlFor="cliente" className="titulo-reservas">
              Cliente
            </label>
            <div className="center-reservas">
              <select
                {...register("cliente", {
                  required: true,
                  valueAsNumber: true,
                })}
                name="cliente"
                id="cliente"
                className="select input--xxlarge"
              >
                {pedido?.tipoPedidoId === 1 ? (
                  <option value={clienteActual.id}>
                    {`${clienteActual.nombre} ${clienteActual.apellido}`}
                  </option>
                ) : (
                  <option value="">clientes...</option>
                )}
                {clientes.clientes?.map((clienteSeleccion, index) => (
                  <option
                    key={index}
                    value={clienteSeleccion.id}
                  >{`${clienteSeleccion.nombre} ${clienteSeleccion.apellido}`}</option>
                ))}
              </select>

              <svg
                onClick={() => agregarNuevoCliente()}
                className="icono-agregar-cliente"
              ></svg>
              {editarCliente ? (
                <Redirect
                  to={{
                    pathname: `/editar-cliente/${traerInfoNuevoCliente().id}`,
                    state: {
                      indexCliente: clientes.clientes?.length - 1,
                    },
                  }}
                />
              ) : (
                ""
              )}
              <Link
                to={{
                  pathname: `/editar-cliente/${clienteSeleccionado?.id}`,
                  state: {
                    indexCliente: undefined,
                  },
                }}
              >
                <svg className="icono-edit"></svg>
              </Link>
            </div>
          </div>

          <div>
            <label className="titulo-reservas" htmlFor="porciones">
              Tamaño
            </label>
            {pedido.tipoTortas?.map((tipoTorta, indexTorta) => {
              return ponerOpcionesTorta(tipoTorta, indexTorta);
            })}
          </div>

          <div>
            <button
              type="button"
              onClick={() => agregarOpcionTorta()}
              className="btn btn--small"
            >
              Agregar torta
            </button>
          </div>

          <div className="reservar-torta__container-parametro">
            {pedido.tipoTortas?.map((tortaPedido, indexTorta) => {
              // if (tortaPedido.id === undefined) {
              //   return ponerParametrosTortas1();
              // }
              return ponerParametrosTortas(tortaPedido, indexTorta);
            })}
          </div>

          <div>
            <label htmlFor="tema" className="titulo-reservas">
              Tema
            </label>

            <textarea
              type="text"
              {...register("tema", {
                value: pedido?.tema,
              })}
              id="tema"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="notas" className="titulo-reservas">
              Notas
            </label>
            <textarea
              type="text"
              {...register("notas", {
                value: pedido?.notas,
              })}
              id="notas"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="ubicacion" className="titulo-reservas">
              Ubicacion
            </label>
            <div className="center-reservas">
              <select
                {...register("ubicacion", { valueAsNumber: true })}
                id="ubicacion"
                className="select input--xxlarge"
              >
                {pedido && ubicacion ? (
                  <option value={ubicacion?.id}>
                    {`${ubicacion?.calles} ${ubicacion?.numeroCasa}`}
                  </option>
                ) : (
                  <option value="">Ubicacion...</option>
                )}
                {clienteSeleccionado
                  ? clienteSeleccionado?.ubicaciones.map((ubicacion, index) => (
                      <option key={index} value={ubicacion.id}>
                        {ubicacion.calles} {ubicacion.numeroCasa}
                      </option>
                    ))
                  : ""}
              </select>

              <svg
                onClick={(e) => {
                  agregarNuevaUbicacion();
                }}
                className="icono-agregar"
              />
              {editarUbicacion && (
                <Redirect
                  to={{
                    pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${undefined}`,
                    state: {
                      ubicacionIndex:
                        clienteSeleccionado?.ubicaciones?.length - 1,
                    },
                  }}
                ></Redirect>
              )}

              <Link
                to={{
                  pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${ubicacionSeleccionada?.id}`,
                  state: {
                    ubicacionIndex: ubicacionSeleccionada
                      ? indexUbicacionSeleccionada
                      : "",
                  },
                }}
              >
                <svg className={`icono-edit`} />
              </Link>
            </div>
          </div>

          <div className="reservar-torta__container-tiempo">
            <div>
              <label htmlFor="fecha" className="titulo-reservas">
                Fecha
              </label>
              <input
                type="date"
                {...register("fecha", {
                  valueAsDate: true,
                  value: pedido?.fecha.toISOString().substr(0, 10),
                })}
                id="fecha"
                placeholder="20/12/2021"
                className="input input--fit"
              />
            </div>

            <div>
              <label htmlFor="hora" className="titulo-reservas">
                Hora de entrega
              </label>
              <input
                type="time"
                {...register("hora", { value: pedido?.hora })}
                defaultValue={pedido?.hora}
                id="hora"
                placeholder="00:00"
                className="input input--fit"
              />
            </div>
          </div>

          <div className="reservar-torta__btn">
            <button type="button" className="btn btn--medium">
              Adicional
            </button>
          </div>

          <div className="reservar-torta__container-precio">
            <div>
              <label htmlFor="precio" className="titulo-reservas">
                Precio
              </label>
              {
                <CurrencyInput
                  {...register("precio", {
                    valueAsNumber: true,
                    value: obtenerPrecio().toFixed(2),
                  })}
                  id="precio"
                  placeholder="$0.00"
                  value={obtenerPrecio().toFixed(2)}
                  className="input input--small"
                />
              }
            </div>

            <div>
              <label htmlFor="miPrecio" className="titulo-reservas">
                Mi Precio
              </label>
              <CurrencyInput
                {...register("miPrecio", {
                  valueAsNumber: true,
                })}
                id="miPrecio"
                placeholder="$0.00"
                className="input input--small"
              />
            </div>
          </div>

          <div className="reservar-torta__btn">
            <Button type="submit" className="btn--large">
              Reservar
            </Button>
          </div>
          <div className="reservar-torta__btn">
            <Button
              type="button"
              onClick={() => {
                eliminarPedido(pedido.id);
                history.push("/pedidos");
              }}
              className="btn--medium"
            >
              Eliminar pedido
            </Button>
          </div>
        </form>
      ) : (
        id === undefined && (
          <form
            onSubmit={handleSubmit((data) => {
              editarPedido(asignar(data), pedidoId);
              history.push("/pedidos");
            })}
          >
            <div>
              <label htmlFor="cliente" className="titulo-reservas">
                Cliente
              </label>
              <div className="center-reservas">
                <select
                  {...register("cliente", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  name="cliente"
                  id="cliente"
                  className="select input--xxlarge"
                >
                  <option value="">clientes...</option>

                  {clientes.clientes?.map((clienteSeleccion, index) => (
                    <option
                      key={index}
                      value={clienteSeleccion.id}
                    >{`${clienteSeleccion.nombre} ${clienteSeleccion.apellido}`}</option>
                  ))}
                </select>

                <svg
                  onClick={() => agregarNuevoCliente()}
                  className="icono-agregar-cliente"
                ></svg>
                {editarCliente ? (
                  <Redirect
                    to={{
                      pathname: `/editar-cliente/${traerInfoNuevoCliente().id}`,
                      state: {
                        indexCliente: clientes.clientes?.length - 1,
                      },
                    }}
                  />
                ) : (
                  ""
                )}
                <Link
                  to={{
                    pathname: `/editar-cliente/${clienteSeleccionado?.id}`,
                    state: {
                      indexCliente: undefined,
                    },
                  }}
                >
                  <svg className="icono-edit"></svg>
                </Link>
              </div>
            </div>

            <div>
              <label className="titulo-reservas" htmlFor="porciones">
                Tamaño
              </label>
              {ponerOpcionesTorta1()}
            </div>

            <div>
              <button
                type="button"
                onClick={() => agregarOpcionTorta()}
                className="btn btn--small"
              >
                Agregar torta
              </button>
            </div>

            <div className="reservar-torta__container-parametro">
              {ponerParametrosTortas1()}
            </div>

            <div>
              <label htmlFor="tema" className="titulo-reservas">
                Tema
              </label>

              <textarea
                type="text"
                {...register("tema", {
                  value: pedido?.tema,
                })}
                id="tema"
                className="text-area"
              />
            </div>

            <div>
              <label htmlFor="notas" className="titulo-reservas">
                Notas
              </label>
              <textarea
                type="text"
                {...register("notas")}
                id="notas"
                className="text-area"
              />
            </div>

            <div>
              <label htmlFor="ubicacion" className="titulo-reservas">
                Ubicacion
              </label>
              <div className="center-reservas">
                <select
                  {...register("ubicacion", {
                    valueAsNumber: true,
                  })}
                  id="ubicacion"
                  className="select input--xxlarge"
                >
                  <option value="">Ubicacion...</option>

                  {clienteSeleccionado
                    ? clienteSeleccionado?.ubicaciones.map(
                        (ubicacion, index) => (
                          <option key={index} value={ubicacion.id}>
                            {ubicacion.calles} {ubicacion.numeroCasa}
                          </option>
                        )
                      )
                    : ""}
                </select>

                <svg
                  onClick={(e) => {
                    agregarNuevaUbicacion();
                  }}
                  className="icono-agregar"
                />
                {editarUbicacion && (
                  <Redirect
                    to={{
                      pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${undefined}`,
                      state: {
                        ubicacionIndex:
                          clienteSeleccionado?.ubicaciones?.length - 1,
                      },
                    }}
                  ></Redirect>
                )}

                <Link
                  to={{
                    pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${ubicacionSeleccionada?.id}`,
                    state: {
                      ubicacionIndex: ubicacionSeleccionada
                        ? indexUbicacionSeleccionada
                        : "",
                    },
                  }}
                >
                  <svg className={`icono-edit`} />
                </Link>
              </div>
            </div>

            <div className="reservar-torta__container-tiempo">
              <div>
                <label htmlFor="fecha" className="titulo-reservas">
                  Fecha
                </label>
                <input
                  type="date"
                  {...register("fecha", {
                    valueAsDate: true,
                  })}
                  id="fecha"
                  placeholder="20/12/2021"
                  className="input input--fit"
                />
              </div>

              <div>
                <label htmlFor="hora" className="titulo-reservas">
                  Hora de entrega
                </label>
                <input
                  type="time"
                  {...register("hora")}
                  id="hora"
                  placeholder="00:00"
                  className="input input--fit"
                />
              </div>
            </div>

            <div className="reservar-torta__btn">
              <button type="button" className="btn btn--medium">
                Adicional
              </button>
            </div>

            <div className="reservar-torta__container-precio">
              <div>
                <label htmlFor="precio" className="titulo-reservas">
                  Precio
                </label>
                <CurrencyInput
                  {...register("precio", {
                    valueAsNumber: true,
                    value: obtenerPrecio().toFixed(2),
                  })}
                  id="precio"
                  value={obtenerPrecio().toFixed(2)}
                  placeholder="$0.00"
                  className="input input--small"
                />
              </div>

              <div>
                <label htmlFor="miPrecio" className="titulo-reservas">
                  Mi Precio
                </label>
                <CurrencyInput
                  {...register("miPrecio", {
                    valueAsNumber: true,
                  })}
                  id="miPrecio"
                  placeholder="$0.00"
                  className="input input--small"
                />
              </div>
            </div>

            <div className="reservar-torta__btn">
              <Button type="submit" className="btn--large">
                Reservar
              </Button>
            </div>
          </form>
        )
      )}
    </div>
  );
};

export default ReservarTorta;
