import React, { useLayoutEffect, useEffect, useState } from "react";
import { connect } from "react-redux";

import { ButtonTab } from "../../components/Button/Button";
import { Navtab } from "../../components/Navtab/Navtab";
import ReservarTorta from "./ReservarTorta/ReservarTorta";
import ReservarBocaditos from "./ReservarBocaditos/ReservarBocaditos.jsx";
import ReservarArreglos from "./ReservarArreglos/ReservarArreglos.jsx";
import * as reservarActions from "../../redux/actions/reservarActions";
import * as clientesActions from "../../redux/actions/clientesActions";
import * as tortaActions from "../../redux/actions/tortaActions";
import * as bocaditosActions from "../../redux/actions/bocaditosActions";
import * as pedidosActions from "../../redux/actions/pedidosActions.js";
import * as arreglosActions from "../../redux/actions/arreglosActions";

const { clientesTraerTodos, agregarCliente, agregarUbicacion } =
  clientesActions;
const { tortaTraerTodos } = tortaActions;
const {
  pedidosTraerTodos,
  editarPedido,
  eliminarPedido,
  agregarTipoBocadito,
  eliminarTipoBocadito,
  agregarTipoTorta,
  eliminarTipoTorta,
  agregarTipoArreglo,
  eliminarTipoArreglo,
  agregarTipoProducto,
  eliminarTipoProducto,
  agregarProducto,
  eliminarProducto,
  agregarBocadito,
  eliminarBocadito,
} = pedidosActions;
const { bocaditosTraerTodos } = bocaditosActions;

const { reservarTraerTodos, toggleReservar } = reservarActions;

const { arreglosTraerTodos } = arreglosActions;

const Reservar = (props) => {
  const {
    match: {
      params: { id },
    },
    reservarTraerTodos,
    toggleReservar,
    clientesTraerTodos,
    agregarCliente,
    tortaTraerTodos,
    bocaditosTraerTodos,
    agregarUbicacion,
    arreglosTraerTodos,
    pedidosTraerTodos,
    agregarTipoBocadito,
    eliminarTipoBocadito,
    editarPedido,
    eliminarPedido,
    agregarTipoTorta,
    eliminarTipoTorta,
    agregarTipoArreglo,
    eliminarTipoArreglo,
    agregarTipoProducto,
    eliminarTipoProducto,
    agregarProducto,
    eliminarProducto,
    agregarBocadito,
    eliminarBocadito,
  } = props;

  const { clientes } = props.clientesReducer;
  const { torta } = props.tortaReducer;
  const { bocaditos } = props.bocaditosReducer;
  const { pedidos } = props.pedidosReducer;
  const { reservar } = props.reservarReducer;
  const { arreglos } = props.arreglosReducer;
  const { isLoggedIn, user } = props.cuentaReducer;

  if (!isLoggedIn) {
    props.history.push("/login");
  }

  const companyId = user?.user?.companyId;

  const [clienteActual, setClienteActual] = useState(null);
  const [pedido1, setPedido1] = useState(null);
  const [ubicacion1, setUbicacion1] = useState(null);

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    if (!Object.keys(reservar).length) {
      reservarTraerTodos();
    }
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }
    if (!Object.keys(torta).length) {
      tortaTraerTodos();
    }
    if (!bocaditos.length) {
      bocaditosTraerTodos();
    }
    if (!Object.keys(arreglos).length) {
    }
    arreglosTraerTodos();
    if (!Object.keys(pedidos).length) {
      pedidosTraerTodos();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(pedidos).length > 0) {
      setPedido1(
        pedidos.pedidos?.filter((pedido) => pedido.id === Number(id))[0]
      );
    }
    if (
      Object.keys(clientes).length > 0 &&
      pedido1?.clienteId &&
      clienteActual === null
    ) {
      setClienteActual(
        clientes.clientes?.filter(
          (cliente) => cliente.id === pedido1.clienteId
        )[0]
      );
    }
    if (clienteActual !== null) {
      setUbicacion1(
        clienteActual?.ubicaciones.filter(
          (ubicacion) => ubicacion.id === pedido1?.ubicacionId
        )[0]
      );
    }
  }, [pedidos, id, clientes, pedido1, clienteActual]);

  return (
    <div>
      <Navtab>
        <ButtonTab
          onClick={() => toggleReservar("torta")}
          selected={reservar.torta}
        >
          Torta
        </ButtonTab>
        <ButtonTab
          onClick={() => toggleReservar("bocaditos")}
          selected={reservar.bocaditos}
        >
          Bocaditos
        </ButtonTab>
        <ButtonTab
          onClick={() => toggleReservar("arreglos")}
          selected={reservar.arreglos}
        >
          Arreglos
        </ButtonTab>
      </Navtab>

      {reservar.torta ? (
        <ReservarTorta
          companyId={companyId}
          id={id}
          agregarCliente={agregarCliente}
          agregarUbicacion={agregarUbicacion}
          editarPedido={editarPedido}
          eliminarPedido={eliminarPedido}
          agregarTipoTorta={agregarTipoTorta}
          eliminarTipoTorta={eliminarTipoTorta}
          clientes={clientes}
          clienteActual={clienteActual}
          pedidos={pedidos}
          pedido={pedido1}
          ubicacion={ubicacion1}
          torta1={torta}
        />
      ) : reservar.bocaditos ? (
        pedido1 && clienteActual && ubicacion1 ? (
          <ReservarBocaditos
            companyId={companyId}
            id={id}
            agregarCliente={agregarCliente}
            agregarUbicacion={agregarUbicacion}
            editarPedido={editarPedido}
            eliminarPedido={eliminarPedido}
            agregarTipoBocadito={agregarTipoBocadito}
            eliminarTipoBocadito={eliminarTipoBocadito}
            clientes={clientes}
            clienteActual={clienteActual}
            pedidos={pedidos}
            pedido={pedido1}
            bocaditos={bocaditos}
            ubicacion={ubicacion1}
          />
        ) : (
          pedido1 === undefined && (
            <ReservarBocaditos
              companyId={companyId}
              agregarCliente={agregarCliente}
              agregarUbicacion={agregarUbicacion}
              editarPedido={editarPedido}
              clientes={clientes}
              pedidos={pedidos}
              bocaditos={bocaditos}
            />
          )
        )
      ) : reservar.arreglos ? (
        pedido1 && clienteActual && ubicacion1 ? (
          <ReservarArreglos
            id={id}
            companyId={companyId}
            agregarCliente={agregarCliente}
            agregarUbicacion={agregarUbicacion}
            editarPedido={editarPedido}
            eliminarPedido={eliminarPedido}
            agregarTipoBocadito={agregarTipoBocadito}
            eliminarTipoBocadito={eliminarTipoBocadito}
            agregarTipoArreglo={agregarTipoArreglo}
            eliminarTipoArreglo={eliminarTipoArreglo}
            agregarTipoProducto={agregarTipoProducto}
            eliminarTipoProducto={eliminarTipoProducto}
            agregarProducto={agregarProducto}
            eliminarProducto={eliminarProducto}
            agregarBocadito={agregarBocadito}
            eliminarBocadito={eliminarBocadito}
            clientes={clientes}
            clienteActual={clienteActual}
            pedidos={pedidos}
            pedido={pedido1}
            bocaditos={bocaditos}
            arreglos={arreglos}
            ubicacion={ubicacion1}
            cargando={props.pedidosReducer.cargando}
            error={props.pedidosReducer.error}
          />
        ) : (
          pedido1 === undefined && (
            <ReservarArreglos
              companyId={companyId}
              id={id}
              agregarCliente={agregarCliente}
              agregarUbicacion={agregarUbicacion}
              editarPedido={editarPedido}
              agregarTipoBocadito={agregarTipoBocadito}
              eliminarTipoBocadito={eliminarTipoBocadito}
              agregarTipoArreglo={agregarTipoArreglo}
              eliminarTipoArreglo={eliminarTipoArreglo}
              agregarTipoProducto={agregarTipoProducto}
              eliminarTipoProducto={eliminarTipoProducto}
              agregarProducto={agregarProducto}
              eliminarProducto={eliminarProducto}
              agregarBocadito={agregarBocadito}
              eliminarBocadito={eliminarBocadito}
              clientes={clientes}
              clienteActual={clienteActual}
              pedidos={pedidos}
              pedido={pedido1}
              bocaditos={bocaditos}
              arreglos={arreglos}
              ubicacion={ubicacion1}
              cargando={props.pedidosReducer.cargando}
              error={props.pedidosReducer.error}
            />
          )
        )
      ) : (
        <div> Algo salio mal</div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  reservarReducer,
  bocaditosReducer,
  clientesReducer,
  tortaReducer,
  arreglosReducer,
  pedidosReducer,
  cuentaReducer,
}) => {
  return {
    reservarReducer,
    bocaditosReducer,
    clientesReducer,
    tortaReducer,
    arreglosReducer,
    pedidosReducer,
    cuentaReducer,
  };
};

const mapDispatchToProps = {
  reservarTraerTodos,
  toggleReservar,
  clientesTraerTodos,
  agregarCliente,
  tortaTraerTodos,
  bocaditosTraerTodos,
  agregarUbicacion,
  arreglosTraerTodos,
  pedidosTraerTodos,
  agregarTipoBocadito,
  eliminarTipoBocadito,
  editarPedido,
  eliminarPedido,
  agregarTipoTorta,
  eliminarTipoTorta,
  agregarTipoArreglo,
  eliminarTipoArreglo,
  agregarTipoProducto,
  eliminarTipoProducto,
  agregarProducto,
  eliminarProducto,
  agregarBocadito,
  eliminarBocadito,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservar);
