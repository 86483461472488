import React, { useState, useLayoutEffect } from "react";
import { connect } from "react-redux";

import {
  CardOrderInfoArreglos,
  CardOrderInfoBocaditos,
  CardOrderInfoTorta,
} from "../../../components/Card/Card";
import * as pedidosActions from "../../../redux/actions/pedidosActions";
import * as clientesActions from "../../../redux/actions/clientesActions";
import * as reservarActions from "../../../redux/actions/reservarActions";

const { pedidosTraerTodos } = pedidosActions;
const { clientesTraerTodos } = clientesActions;
const { reservarTraerTodos, toggleReservar } = reservarActions;

const HistorialPedidos = (props) => {
  const {
    pedidosTraerTodos,
    reservarTraerTodos,
    clientesTraerTodos,
    toggleReservar,
    precio,
    busqueda,
  } = props;
  const { pedidos } = props.pedidosReducer;
  const { reservar } = props.reservarReducer;
  const { clientes } = props.clientesReducer;
  const [size, setSize] = useState(30);
  console.log("pedidos", pedidos);
  console.log("busqueda", busqueda);

  useLayoutEffect(() => {
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }

    if (pedidos && !Object.keys(pedidos).length) {
      pedidosTraerTodos();
    }
    if (!Object.keys(reservar).length) {
      reservarTraerTodos();
    }
  }, []);

  const resultado =
    pedidos?.filtrosFecha?.length > 0 && pedidos?.filtrosPrecio?.length > 0
      ? pedidos.filtrosFecha?.filter((pedido) => {
          return Object.keys(pedido).some((key) => {
            return pedido[key].toString().toLowerCase().includes(busqueda);
          });
        })
      : pedidos?.filtrosPrecio?.length > 0 && !pedidos?.filtrosFecha?.length
      ? pedidos.filtrosPrecio?.filter((pedido) => {
          return Object.keys(pedido).some((key) => {
            return pedido[key].toString().toLowerCase().includes(busqueda);
          });
        })
      : pedidos?.filtrosFecha?.length > 0 && !pedidos?.filtrosPrecio?.length
      ? pedidos.filtrosFecha?.filter((pedido) => {
          return Object.keys(pedido).some((key) => {
            return pedido[key].toString().toLowerCase().includes(busqueda);
          });
        })
      : pedidos?.pedidos?.filter((pedido) => {
          return Object.keys(pedido).some((key) => {
            return pedido[key].toString().toLowerCase().includes(busqueda);
          });
        });

  const mostrarMas = () => {
    setSize(size + 10);
  };

  const traerCliente = (id) => {
    return clientes.clientes?.filter((cliente) => cliente.id === id)[0];
  };

  const ponerPedidos = (resultado) => {
    return resultado?.slice(0, size).map((pedido, index) => {
      return pedido.tipoPedidoId === 1 ? (
        <CardOrderInfoTorta
          key={index}
          indexPedido={index}
          className={index % 2 === 0 ? "" : "card__client-info--black"}
          editColor={index % 2 === 0 ? "" : "edit--white"}
          infoColor={index % 2 === 0 ? "" : "info--white"}
          pedido={pedido}
          cliente={traerCliente(pedido.clienteId)}
          onClick={toggleReservar}
        />
      ) : pedido.tipoPedidoId === 2 ? (
        <CardOrderInfoBocaditos
          key={index}
          indexPedido={index}
          className={index % 2 === 0 ? "" : "card__client-info--black"}
          editColor={index % 2 === 0 ? "" : "edit--white"}
          infoColor={index % 2 === 0 ? "" : "info--white"}
          cliente={traerCliente(pedido.clienteId)}
          pedido={pedido}
          onClick={toggleReservar}
        />
      ) : pedido.tipoPedidoId === 3 ? (
        <CardOrderInfoArreglos
          key={index}
          indexPedido={index}
          className={index % 2 === 0 ? "" : "card__client-info--black"}
          editColor={index % 2 === 0 ? "" : "edit--white"}
          infoColor={index % 2 === 0 ? "" : "info--white"}
          cliente={traerCliente(pedido.clienteId)}
          pedido={pedido}
          onClick={toggleReservar}
        />
      ) : (
        <div></div>
      );
    });
  };

  return (
    <div>
      {ponerPedidos(resultado)}
      <div className="historial-btn">
        <button onClick={() => mostrarMas()} className="btn btn--small">
          Mostrar mas
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  pedidosReducer,
  reservarReducer,
  clientesReducer,
}) => {
  return { pedidosReducer, reservarReducer, clientesReducer };
};

const mapDispatchToProps = {
  pedidosTraerTodos,
  clientesTraerTodos,
  reservarTraerTodos,
  toggleReservar,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistorialPedidos);
