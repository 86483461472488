import React from "react";
import { useForm } from "react-hook-form";

import "../CardProductos.css";

export const CardProductosBocaditos = ({
  editando,
  setEditando,
  indx,
  onClickEditar,
  producto,
  editColor,
}) => {
  const idProducto = producto.id ? producto.id : `array${indx}`;

  return (
    <div className="card-productos card__producto-parametro-tamano">
      <div className="card-productos__inner card__producto-bocadito__inner">
        <div>
          <p className="titulo">Producto</p>

          <p className="data">{producto.producto}</p>
        </div>
        <div>
          <p className="titulo">Precio</p>
          <p className="data">{producto.precio ? `$${producto.precio}` : ""}</p>
        </div>
        {producto.parametros?.map((parametro, indexParametro) => {
          const idParametro = parametro.id
            ? parametro.id
            : `array${indexParametro}`;
          return (
            <div key={idParametro}>
              <p className="titulo">{parametro.parametro}</p>
              {parametro.data.map((dato, indexOpciones) => {
                const idOpciones = dato.id ? dato.id : `array${indexOpciones}`;
                return (
                  <p key={idOpciones} className="data">
                    {dato.valor}
                  </p>
                );
              })}
            </div>
          );
        })}

        {!editando && (
          <svg
            onClick={() => {
              setEditando(true);
              onClickEditar(idProducto);
            }}
            className={`card-productos__edit ${editColor}`}
          />
        )}
      </div>
    </div>
  );
};

export const CardProductosBocaditosEdit = ({
  companyId,
  setEditando,
  indexProducto,
  eliminarBocadito,
  producto,
  agregarParametro,
  eliminarParametro,
  agregarOpcion,
  eliminarOpcion,
  editarProducto,
}) => {
  const { register, handleSubmit, getValues } = useForm();

  const idProducto = producto.id ? producto.id : `array${indexProducto}`;

  const asignar = (data) => {
    let array = [];

    producto?.parametros.map((parametro, indexParametro) => {
      const idParametro = parametro.id
        ? parametro.id
        : `array${indexParametro}`;

      array.push({
        id: parametro.id ? parametro.id : null,
        bocaditoId: parametro.bocaditoId,
        parametro: data[`parametro${idParametro}`],
        bocaditoParametroValores: parametro?.data.map((dato, indexOpciones) => {
          const idOpciones = dato.id ? dato.id : `array${indexOpciones}`;
          return {
            id: dato.id ? dato.id : null,
            bocaditoParametroId: dato.bocaditoParametroId,
            valor: data[`parametro${idParametro}opcion${idOpciones}`],
          };
        }),
      });
    });

    array.map((parametro) => {
      if (parametro.id === null) {
        delete parametro.id;
      }
      if (parametro.bocaditoId === undefined) {
        parametro.bocaditoId = 1;
      }
      parametro.bocaditoParametroValores.map((dato) => {
        if (dato.id === null) {
          delete dato.id;
        }
        if (dato.bocaditoParametroId === undefined) {
          dato.bocaditoParametroId = 1;
        }
      });
    });

    const objeto = {
      companyId: companyId,
      id: producto.id ? producto.id : null,
      nombre: data[`producto`],
      precio: data[`precio`],
      bocaditoParametros: array,
    };
    if (objeto.id === null) {
      delete objeto.id;
    }

    return objeto;
  };

  const opcionesExistentes = (idParametro, parametro) => {
    return (
      parametro.data &&
      parametro.data.map((dato, indexOpciones) => {
        const idOpciones = dato.id ? dato.id : `array${indexOpciones}`;
        return agregarCampoOpcionData(idParametro, dato, idOpciones);
      })
    );
  };

  const agregarCampoParametro = () => {
    return (
      producto.parametros &&
      producto.parametros.map((parametro, indexParametros) => {
        const idParametro = parametro.id
          ? parametro.id
          : `array${indexParametros}`;
        return (
          <div
            key={idParametro}
            className="card-productos__inner card__producto-bocadito-edit__inner-pequeno"
          >
            <div>
              <label htmlFor={`parametro${idParametro}`} className="titulo">
                Parametro
              </label>
              <div className="tamano-edit">
                <svg
                  onClick={() => eliminarParametro(idProducto, idParametro)}
                  className="delete-card-productos-bocaditos"
                />
                <input
                  {...register(`parametro${idParametro}`, {
                    value: parametro.parametro,
                  })}
                  name={`parametro${idParametro}`}
                  id={`parametro${idParametro}`}
                  className="input input--small"
                />
              </div>
            </div>

            <button
              type="button"
              onClick={() =>
                agregarOpcion(idProducto, idParametro, asignar(getValues()))
              }
              className="btn"
            >
              Agregar Opcion
            </button>

            {opcionesExistentes(idParametro, parametro)}
          </div>
        );
      })
    );
  };

  const agregarCampoOpcionData = (idParametro, dato, idOpciones) => {
    return (
      <div key={idOpciones}>
        <label
          htmlFor={`parametro${idParametro}opcion${idOpciones}`}
          className="titulo"
        >
          Opcion
        </label>
        <div className="tamano-edit">
          <svg
            onClick={() => eliminarOpcion(idProducto, idParametro, idOpciones)}
            className="delete-card-productos-bocaditos"
          />
          <input
            {...register(`parametro${idParametro}opcion${idOpciones}`, {
              value: dato.valor,
            })}
            name={`parametro${idParametro}opcion${idOpciones}`}
            id={`parametro${idParametro}opcion${idOpciones}`}
            className="input input--small"
          />
        </div>
      </div>
    );
  };
  return (
    <div
      key={indexProducto}
      className="card-productos card__producto-parametro-tamano"
    >
      <button
        type="button"
        onClick={() => {
          setEditando(false);
          eliminarBocadito(idProducto);
        }}
        className="btn btn-eliminar-bocadito"
      >{`Eliminar ${producto.producto}`}</button>
      <form
        onSubmit={handleSubmit((data) => {
          // editarDatos(idProducto, asignar(data));
          // editarParametro(idProducto, asignar(data));
          editarProducto(idProducto, asignar(data));
          setEditando(false);
        })}
      >
        <div className="card-productos__inner card__producto-bocadito-edit__inner">
          <div>
            <label htmlFor="producto" className="titulo">
              Producto
            </label>
            <input
              {...register("producto", { value: producto.producto })}
              name="producto"
              id="producto"
              className="input input--large"
            />
          </div>

          <div className="bocaditos__precio">
            <label htmlFor="precio" className="titulo">
              Precio
            </label>
            <input
              {...register("precio", { value: producto.precio })}
              name="precio"
              id="precio"
              className="input input--xsmall"
            />
          </div>
        </div>
        {agregarCampoParametro()}

        <div className="btn-guardar-parametro">
          <button
            onClick={() => {
              agregarParametro(idProducto, asignar(getValues()));
            }}
            className="btn btn--xsmall"
            type="button"
          >
            Agregar Parametro
          </button>
        </div>
        <div className="btn-guardar-parametro">
          <button type="submit" className="btn btn--xsmall">
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};
