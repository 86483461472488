export const TRAER_TODOS = "cuenta_traer_todos";
export const CARGANDO = "cuenta_cargando";
export const ERROR = "cuenta_error";
export const ACTUALIZAR = "cuenta_actualizar";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
