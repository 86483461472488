import React from "react";
import { Link } from "react-router-dom";

import "../Card.css";

export const CardClientInfo = ({
  className,
  cliente,
  editColor,
  indexCliente,
  infoColor,
}) => (
  <div className={`card card__client-info ${className}`}>
    <Link
      to={{
        pathname: `/cliente/${cliente?.id}`,
      }}
    >
      <svg className={`info ${infoColor}`} />
    </Link>
    <div className="card__inner card__client-info__inner">
      <div>
        <p className="titulo">Nombre</p>

        <p className="data">{cliente?.nombre}</p>
      </div>

      <div>
        <p className="titulo">Cedula</p>
        <p className="data">{cliente?.cedula}</p>
      </div>

      <div>
        <p className="titulo">Apellidos</p>
        <p className="data">{cliente?.apellido}</p>
      </div>

      <div>
        <p className="titulo">Telefono</p>
        <p className="data">0{cliente?.telefono}</p>
      </div>

      <div className="email">
        <p className="titulo">Email</p>
        <p className="data">{cliente?.email}</p>
      </div>

      <div>
        <p className="titulo">instagram</p>
        <p className="data">{cliente?.instagram}</p>
      </div>

      <div>
        <p className="titulo">Facebook</p>
        <p className="data">{cliente?.facebook}</p>
      </div>

      <div>
        <p className="titulo"># Pedidos</p>
        <p className="data">{cliente?.numeroPedidos}</p>
      </div>

      <Link
        to={{
          pathname: `/editar-cliente/${cliente?.id}`,
          state: {
            indexCliente: indexCliente,
          },
        }}
      >
        <svg className={`edit ${editColor}`} />
      </Link>
    </div>
  </div>
);
