import { combineReducers } from "redux"

import clientesReducer from "./clientesReducer"
import pedidosReducer from "./pedidosReducer"
import bocaditosReducer from "./bocaditosReducer"
import tortaReducer from "./tortaReducer"
import arreglosReducer from "./arreglosReducer"
import reservarReducer from "./reservarReducer"
import cuentaReducer from "./cuentaReducer"
import tabProductosReducer from "./tabProductosReducer"
import tabHistorialReducer from "./tabHistorialReducer"

export default combineReducers({
    clientesReducer,
    pedidosReducer,
    bocaditosReducer,
    tortaReducer,
    arreglosReducer,
    reservarReducer,
    cuentaReducer,
    tabProductosReducer,
    tabHistorialReducer
});