import React from "react";
import { Link } from "react-router-dom";

import "./Navbar.css";

export const Navbar = () => (
  <div className="navbar">
    <div className="navbar__container">
      <h1 className="logo">De Sal y Dulce</h1>
      <div className="navbar__tabs">
        <Link className="link" to="/reservar">
          Reservar
        </Link>
        <Link className="link" to="/pedidos">
          Pedidos
        </Link>
        <Link className="link" to="/historial">
          Historial
        </Link>
        <Link className="link" to="/cuenta">
          Cuenta
        </Link>
        <Link className="link" to="/productos">
          Productos
        </Link>
      </div>
    </div>
  </div>
);

export const NavbarLogin = () => (
  <div className="navbar">
    <div className="navbar__container navbar-login__container">
      <h1 className="logo">De Sal y Dulce</h1>
    </div>
  </div>
);
