import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";

import {
  CardProductosArreglo,
  CardProductosArregloEdit,
} from "../../../components/Card/CardProductos/CardProductosArreglo/CardProductosArreglo.jsx";
import Spinner from "../../../components/Spinner/Spiner";
import Fatal from "../../../components/Fatal/Fatal";
import "./ProductosArreglos.css";
import * as arreglosActions from "../../../redux/actions/arreglosActions";

const ProductosArreglos = (props) => {
  const [editando, setEditando] = useState(false);

  const {
    error,
    cargando,
    companyId,
    arreglos,
    arreglosTraerTodos,
    productosToggleEditar,
    agregarProducto,
    eliminarProducto,
    agregarDataProducto,
    eliminarDataPrametro,
    editarArreglo,
  } = props;

  useLayoutEffect(() => {
    if (!Object.keys(arreglos).length) {
      arreglosTraerTodos();
    }
  }, []);

  return (
    <div>
      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        arreglos.productos?.map((tipoProducto, indexTipoProducto) => (
          <div key={indexTipoProducto} className="productos-arreglos__item">
            {tipoProducto.editar ? (
              <CardProductosArregloEdit
                companyId={companyId}
                setEditando={setEditando}
                tipoProducto={tipoProducto}
                eliminarCategoria={eliminarProducto}
                onClick={productosToggleEditar}
                agregarProducto={agregarDataProducto}
                eliminarProducto={eliminarDataPrametro}
                indexTipoProducto={indexTipoProducto}
                editarArreglo={editarArreglo}
              />
            ) : (
              <CardProductosArreglo
                editando={editando}
                setEditando={setEditando}
                tipoProducto={tipoProducto}
                onClick={productosToggleEditar}
                indexTipoProducto={indexTipoProducto}
              />
            )}
          </div>
        ))
      )}

      {!editando && (
        <div className="productos-arreglos__item">
          <button onClick={() => agregarProducto()} className="btn btn--small">
            Agregar Nuevo Bocadito
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.arreglosReducer;
};

export default connect(mapStateToProps, arreglosActions)(ProductosArreglos);
