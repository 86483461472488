import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";

import "./ReservarBocaditos.css";
import { Button } from "../../../components/Button/Button";

const ReservarBocaditos = (props) => {
  const history = useHistory();

  const {
    companyId,
    id,
    agregarCliente,
    agregarUbicacion,
    editarPedido,
    eliminarPedido,
    clientes,
    clienteActual,
    pedidos,
    pedido,
    agregarTipoBocadito,
    eliminarTipoBocadito,
    bocaditos,
    ubicacion,
  } = props;

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const [editarCliente, setEditarCliente] = useState(false);
  const [editarUbicacion, setEditarUbicacion] = useState(false);

  const [numeroBocaditos, setNumeroBocaditos] = useState([
    {
      pedidoId: "",
      bocadito: "",
      cantidad: "",
      tipoBocaditosParametros: [],
    },
  ]);

  const pedidoActual = pedido ? pedido : "";
  const pedidoId = pedido?.id || `array${pedidos.pedidos?.length}`;

  const { register, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      notas: pedidoActual.notas,
      hora: pedidoActual.hora,
      fecha: id !== undefined && pedidoActual.fecha.toISOString().substr(0, 10),
    },
  });

  useEffect(() => {
    if (
      watch("cliente") !== undefined &&
      Number(watch("cliente")) !== clienteSeleccionado?.id
    ) {
      setClienteSeleccionado(
        clientes.clientes?.filter(
          (cliente) => cliente.id === Number(watch("cliente"))
        )[0]
      );
    }
  }, [clienteActual, watch(), clientes.clientes]);

  /* empieza logica de cliente y ubicacion */

  const ubicacionSeleccionada = clienteSeleccionado
    ? clienteSeleccionado[0]?.ubicacion.filter(
        (ubicacion, index) =>
          `${ubicacion.calles} ${ubicacion.numeroDeCasa}` === watch().ubicacion
      )
    : "";

  const indexUbicacionSeleccionada =
    clienteSeleccionado && ubicacionSeleccionada
      ? clienteSeleccionado[0]?.ubicacion.findIndex(
          (ubicacion) =>
            ubicacion.numeroDeCasa === ubicacionSeleccionada[0]?.numeroDeCasa
        )
      : "";

  const traerInfoNuevoCliente = () => {
    const indexNuevoCliente = clientes.cliente?.length - 1;

    const nuevoClienteInfo = clientes.cliente
      ? clientes.cliente[indexNuevoCliente]
      : "";

    return nuevoClienteInfo;
  };

  const agregarNuevoCliente = () => {
    agregarCliente();
    setEditarCliente(true);
  };

  const agregarNuevaUbicacion = () => {
    agregarUbicacion(clienteSeleccionado.id);
    setEditarUbicacion(true);
  };

  /* termina logica de cliente y ubicacion */

  /* empieza logica de Bocaditos y parametros */

  const traerBocaditoActual = (idBocadito) => {
    const bocaditoEncontrado = bocaditos?.filter(
      (bocadito) => bocadito.producto === getValues(`bocadito${idBocadito}`)
    );

    return bocaditoEncontrado;
  };

  const traerParametroActual = (idBocadito, parametro1) => {
    // const ejemplo = parametro;
    const bocaditoEncontrado = bocaditos?.filter(
      (bocadito) => bocadito.producto === getValues(`bocadito${idBocadito}`)
    );
    const parametroActual = bocaditoEncontrado[0]?.parametros?.filter(
      (parametro) => parametro.parametro === parametro1
    );

    return parametroActual;
  };

  const ponerParametrosBocaditos = () => {
    return (
      pedidoActual.tipoBocaditos &&
      pedidoActual.tipoBocaditos.map((tipoBocadito, indextipoBocadito) => {
        const idBocadito = tipoBocadito.id || `array${indextipoBocadito}`;
        const bocaditoActual = traerBocaditoActual(idBocadito)[0];

        return (
          <div
            key={idBocadito}
            className="reservar-bocaditos__container-bocaditos"
          >
            <div>
              <label
                htmlFor={`bocadito${idBocadito}`}
                className="titulo-reservas"
              >
                Bocadito
              </label>
              <select
                {...register(`bocadito${idBocadito}`)}
                name={`bocadito${idBocadito}`}
                id={`bocadito${idBocadito}`}
                className="select input--medium"
              >
                {tipoBocadito?.bocadito ? (
                  <option value={tipoBocadito?.bocadito}>
                    {bocaditoActual?.producto}
                  </option>
                ) : (
                  <option value="">Bocaditos...</option>
                )}
                {bocaditos?.map((bocadito, indexBocadito) => (
                  <option key={indexBocadito} value={bocadito.producto}>
                    {bocadito.producto}
                  </option>
                ))}
              </select>
              <svg
                onClick={() => eliminarTipoBocadito(pedidoId, idBocadito)}
                className="delete"
              />
            </div>

            <div>
              <label htmlFor={`cant${idBocadito}`} className="titulo-reservas">
                Cant
              </label>
              <input
                {...register(`cant${idBocadito}`, {
                  valueAsNumber: true,
                })}
                name={`cant${idBocadito}`}
                id={`cant${idBocadito}`}
                defaultValue={tipoBocadito.cantidad}
                placeholder="1"
                className="input input--xxsmall"
              />
            </div>
            {getValues(`bocadito${idBocadito}`) === tipoBocadito?.bocadito
              ? tipoBocadito?.tipoBocaditosParametros?.map(
                  (parametro, indexParametro) => (
                    <div key={indexParametro}>
                      <label
                        htmlFor={`bocadito${idBocadito}${parametro.parametro}`}
                        className="titulo-reservas"
                      >
                        {parametro.parametro}
                      </label>
                      <div className="center-reservas">
                        <select
                          {...register(
                            `bocadito${idBocadito}${parametro.parametro}`
                          )}
                          name={`bocadito${idBocadito}${parametro.parametro}`}
                          id={`bocadito${idBocadito}${parametro.parametro}`}
                          className="select input--medium"
                        >
                          {parametro.valor ? (
                            <option value={parametro.valor}>
                              {parametro.valor}
                            </option>
                          ) : (
                            <option value="">parametro...</option>
                          )}
                          {traerParametroActual(
                            idBocadito,
                            parametro.parametro
                          ) !== undefined &&
                            traerParametroActual(
                              idBocadito,
                              parametro.parametro
                            )[0].data?.map((dato, indexDato) => (
                              <option key={indexDato} value={dato.valor}>
                                {dato.valor}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )
                )
              : bocaditoActual?.parametros.map((parametro, indexParametro) => (
                  <div key={indexParametro}>
                    <label
                      htmlFor={`bocadito${idBocadito}${parametro.parametro}`}
                      className="titulo-reservas"
                    >
                      {parametro.parametro}
                    </label>
                    <div className="center-reservas">
                      <select
                        {...register(
                          `bocadito${idBocadito}${parametro.parametro}`
                        )}
                        name={`bocadito${idBocadito}${parametro.parametro}`}
                        id={`bocadito${idBocadito}${parametro.parametro}`}
                        className="select input--medium"
                      >
                        {/* aqui esta el problema */}
                        <option value="">parametro...</option>

                        {traerParametroActual(
                          idBocadito,
                          parametro.parametro
                        ) !== undefined &&
                          traerParametroActual(
                            idBocadito,
                            parametro.parametro
                          )[0].data?.map((dato, indexDato) => (
                            <option key={indexDato} value={dato.valor}>
                              {dato.valor}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                ))}
          </div>
        );
      })
    );
  };

  const ponerParametrosBocaditos1 = () => {
    return numeroBocaditos.map((elemento, index) => {
      const idBocadito = `array${index}`;
      const bocaditoActual = traerBocaditoActual(idBocadito)[0];
      return (
        <div key={index} className="reservar-bocaditos__container-bocaditos">
          <div>
            <label
              htmlFor={`bocadito${idBocadito}`}
              className="titulo-reservas"
            >
              Bocadito
            </label>
            <select
              {...register(`bocadito${idBocadito}`)}
              name={`bocadito${idBocadito}`}
              id={`bocadito${idBocadito}`}
              className="select input--medium"
            >
              <option value="">Bocaditos...</option>
              {bocaditos.map((bocadito, indexBocadito) => (
                <option key={indexBocadito}>{bocadito.producto}</option>
              ))}
            </select>
            <svg onClick={() => eliminarBocadito(index)} className="delete" />
          </div>

          <div>
            <label htmlFor={`cant${idBocadito}`} className="titulo-reservas">
              Cant
            </label>
            <input
              {...register(`cant${idBocadito}`)}
              name={`cant${idBocadito}`}
              id={`cant${idBocadito}`}
              placeholder="1"
              className="input input--xxsmall"
            />
          </div>
          {bocaditoActual?.parametros.map((parametro, indexParametro) => (
            <div key={indexParametro}>
              <label
                htmlFor={`bocadito${idBocadito}${parametro.parametro}`}
                className="titulo-reservas"
              >
                {parametro.parametro}
              </label>
              <div className="center-reservas">
                <select
                  {...register(`bocadito${idBocadito}${parametro.parametro}`)}
                  name={`bocadito${idBocadito}${parametro.parametro}`}
                  id={`bocadito${idBocadito}${parametro.parametro}`}
                  className="select input--medium"
                >
                  {/* aqui esta el problema */}
                  <option value="">parametro...</option>

                  {traerParametroActual(idBocadito, parametro.parametro) !==
                    undefined &&
                    traerParametroActual(
                      idBocadito,
                      parametro.parametro
                    )[0].data?.map((dato, indexDato) => (
                      <option key={indexDato} value={dato.valor}>
                        {dato.valor}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          ))}
        </div>
      );
    });
  };

  const agregarBocaditos = () => {
    setNumeroBocaditos((prev) => [
      ...prev,
      {
        pedidoId: "",
        bocadito: "",
        cantidad: "",
        tipoBocaditosParametros: [],
      },
    ]);
  };

  const eliminarBocadito = (index) => {
    let newArray = [];
    if (pedidoActual) {
      pedidoActual.bocadito = [
        ...pedidoActual.bocadito.slice(0, index),
        ...pedidoActual.bocadito.slice(index + 1),
      ];
    } else {
      newArray = [
        ...numeroBocaditos
          .slice(0, index)
          .concat(numeroBocaditos.slice(index + 1)),
      ];
    }
    setNumeroBocaditos(newArray);
  };

  /* termina logica de Bocaditos y parametros */

  /* comienza logica de precio */
  const obtenerPrecio = () => {
    let precio = 0;

    pedido?.tipoPedidoId === 2 && pedidoActual.tipoBocaditos
      ? pedidoActual.tipoBocaditos.map((tipoBocadito, indextipoBocadito) => {
          const idBocadito = tipoBocadito.id || `array${indextipoBocadito}`;
          const precioBocadito = Number(
            traerBocaditoActual(idBocadito)[0]?.precio
          );
          const cant = Number(getValues(`cant${idBocadito}`));
          precio = precio + precioBocadito * cant;
        })
      : id === undefined &&
        numeroBocaditos?.map((bocadito, indexBocadito) => {
          const idBocadito = `array${indexBocadito}`;

          const precioBocadito = Number(
            traerBocaditoActual(idBocadito)[0]?.precio
          );
          const cant = Number(getValues(`cant${idBocadito}`));
          precio = precio + precioBocadito * cant;
        });
    return precio;
  };
  /* termina logica de precio */

  const obtenerBocaditoActual = (data) => {
    let array = [];
    if (pedidoActual.tipoBocaditos && pedidoActual.tipoPedidoId === 2) {
      pedidoActual.tipoBocaditos.forEach((tipoBocadito, indextipoBocadito) => {
        const idBocadito = tipoBocadito.id || `array${indextipoBocadito}`;
        const bocaditoActual = traerBocaditoActual(idBocadito)[0];
        const bocaditos = data[`bocadito${idBocadito}`];
        array.push({
          id: tipoBocadito?.id || null,
          pedidoId: tipoBocadito?.pedidoId || 1,
          bocadito: bocaditos,
          cantidad: data[`cant${idBocadito}`],
          tipoBocaditosParametros: bocaditoActual?.parametros?.map(
            (parametro, indexParametro) => ({
              id: parametro?.id || null,
              tipoBocaditoId: tipoBocadito?.tipoBocaditoId || 1,
              parametro: parametro.parametro,
              valor: data[`bocadito${idBocadito}${parametro.parametro}`],
            })
          ),
        });
      });
    } else if (id === undefined) {
      numeroBocaditos.forEach((bocadito, indexBocadito) => {
        const idBocadito = `array${indexBocadito}`;
        const bocaditoActual = traerBocaditoActual(idBocadito)[0];
        const bocaditos = getValues(`bocadito${idBocadito}`);
        array.push({
          id: null,
          pedidoId: 1,
          bocadito: bocaditos,
          cantidad: data[`cant${idBocadito}`],
          tipoBocaditosParametros: bocaditoActual?.parametros?.map(
            (parametro, indexParametro) => ({
              id: null,
              tipoBocaditoId: 1,
              parametro: parametro.parametro,
              valor: data[`bocadito${idBocadito}${parametro.parametro}`],
            })
          ),
        });
      });
    }
    //ELIMINAMOS LOS IDS NULLOS PARA PODER HACER POST
    array.forEach((bocadito) => {
      if (bocadito.id === null) {
        delete bocadito.id;
      }
      bocadito.tipoBocaditosParametros?.forEach((parametro) => {
        if (parametro.id === null) {
          delete parametro.id;
        }
      });
    });

    return array;
  };

  const asignar = (data) => {
    const objeto = {
      companyId,
      clienteId: data["cliente"],
      ubicacionId: data["ubicacion"],
      fecha: data["fecha"],
      hora: data["hora"],
      id: pedidoActual?.id || null,
      precio: data.miPrecio
        ? Number(data["miPrecio"])
        : Number(obtenerPrecio(data).toFixed(2)),
      tipoBocaditos: obtenerBocaditoActual(data),
      notas: data["notas"],
      tipoPedidoId: 2,
      tema: "nada",
    };
    if (objeto.id === null) {
      delete objeto.id;
    }

    return objeto;
  };

  return (
    <div className="reservar-bocaditos">
      {id !== undefined && pedido && clienteActual && ubicacion && bocaditos ? (
        <form
          onSubmit={handleSubmit((data) => {
            editarPedido(asignar(data), pedidoActual.id);
            history.push("/pedidos");
          })}
        >
          <div>
            <label className="titulo-reservas">Cliente</label>
            <div className="center-reservas">
              <select
                {...register("cliente", {
                  required: true,
                  valueAsNumber: true,
                })}
                name="cliente"
                id="cliente"
                className="select input--xxlarge"
              >
                {pedido?.tipoPedidoId === 2 ? (
                  <option value={clienteActual.id}>
                    {`${clienteActual.nombre} ${clienteActual.apellido}`}
                  </option>
                ) : (
                  <option value="">clientes...</option>
                )}
                {clientes.clientes?.map((clienteSeleccion, index) => (
                  <option
                    key={index}
                    value={clienteSeleccion.id}
                  >{`${clienteSeleccion.nombre} ${clienteSeleccion.apellido}`}</option>
                ))}
              </select>

              <svg
                onClick={() => agregarNuevoCliente()}
                className="icono-agregar-cliente"
              ></svg>
              {editarCliente ? (
                <Redirect
                  to={{
                    pathname: `/editar-cliente/${traerInfoNuevoCliente().id}`,
                    state: {
                      indexCliente: clientes.clientes?.length - 1,
                    },
                  }}
                />
              ) : (
                ""
              )}
              <Link
                to={{
                  pathname: `/editar-cliente/${clienteSeleccionado?.id}`,
                  state: {
                    indexCliente: undefined,
                  },
                }}
              >
                <svg className="icono-edit"></svg>
              </Link>
            </div>
          </div>

          {ponerParametrosBocaditos()}

          <div className="reservar-bocaditos__btn">
            <button
              type="button"
              onClick={() =>
                agregarTipoBocadito(pedidoActual?.id, asignar(getValues()))
              }
              className="btn btn--medium"
            >
              Adicional
            </button>
          </div>

          <div>
            <label htmlFor="notas" className="titulo-reservas">
              Notas
            </label>
            <textarea
              type="text"
              {...register("notas", {})}
              id="notas"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="ubicacion" className="titulo-reservas">
              Ubicacion
            </label>
            <div className="center-reservas">
              <select
                {...register("ubicacion", { valueAsNumber: true })}
                id="ubicacion"
                className="select input--xxlarge"
              >
                {pedido && ubicacion ? (
                  <option value={ubicacion?.id}>
                    {`${ubicacion?.calles} ${ubicacion?.numeroCasa}`}
                  </option>
                ) : (
                  <option value="">Ubicacion...</option>
                )}
                {clienteSeleccionado
                  ? clienteSeleccionado?.ubicaciones.map((ubicacion, index) => (
                      <option key={index} value={ubicacion.id}>
                        {ubicacion.calles} {ubicacion.numeroCasa}
                      </option>
                    ))
                  : ""}
              </select>

              <svg
                onClick={(e) => {
                  agregarNuevaUbicacion();
                }}
                className="icono-agregar"
              />
              {editarUbicacion && (
                <Redirect
                  to={{
                    pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${undefined}`,
                    state: {
                      ubicacionIndex:
                        clienteSeleccionado?.ubicaciones?.length - 1,
                    },
                  }}
                ></Redirect>
              )}

              <Link
                to={{
                  pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${ubicacionSeleccionada?.id}`,
                  state: {
                    ubicacionIndex: ubicacionSeleccionada
                      ? indexUbicacionSeleccionada
                      : "",
                  },
                }}
              >
                <svg className={`icono-edit`} />
              </Link>
            </div>
          </div>

          <div className="reservar-bocaditos__container-tiempo">
            <div>
              <label htmlFor="fecha" className="titulo-reservas">
                Fecha
              </label>
              <input
                type="date"
                {...register("fecha", {
                  valueAsDate: true,
                })}
                id="fecha"
                placeholder="20/12/2021"
                className="input input--fit"
              />
            </div>

            <div>
              <label htmlFor="hora" className="titulo-reservas">
                Hora de entrega
              </label>
              <input
                type="time"
                {...register("hora")}
                id="hora"
                placeholder="00:00"
                className="input input--fit"
              />
            </div>
          </div>

          <div className="reservar-bocaditos__container-precio">
            <div>
              <label htmlFor="precio" className="titulo-reservas">
                Precio
              </label>
              {
                <CurrencyInput
                  {...register("precio", {
                    valueAsNumber: true,
                    value: Number(obtenerPrecio().toFixed(2)),
                  })}
                  id="precio"
                  placeholder="$0.00"
                  value={obtenerPrecio().toFixed(2)}
                  className="input input--small"
                />
              }
            </div>

            <div>
              <label htmlFor="miPrecio" className="titulo-reservas">
                Mi Precio
              </label>
              <CurrencyInput
                {...register("miPrecio", { valueAsNumber: true })}
                name="miPrecio"
                id="miPrecio"
                placeholder="$0.00"
                className="input input--small"
              />
            </div>
          </div>

          <div className="reservar-bocaditos__btn">
            <button className="btn btn--large">Reservar</button>
          </div>
          <div className="reservar-torta__btn">
            <Button
              type="button"
              onClick={() => {
                eliminarPedido(pedido.id);
                history.push("/pedidos");
              }}
              className="btn--medium"
            >
              Eliminar pedido
            </Button>
          </div>
        </form>
      ) : (
        id === undefined && (
          <form
            onSubmit={handleSubmit((data) => {
              editarPedido(asignar(data), pedidoActual.id);
              history.push("/pedidos");
            })}
          >
            <div>
              <label className="titulo-reservas">Cliente</label>
              <div className="center-reservas">
                <select
                  {...register("cliente", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  name="cliente"
                  id="cliente"
                  className="select input--xxlarge"
                >
                  {pedido?.tipoPedidoId === 2 ? (
                    <option value={clienteActual.id}>
                      {`${clienteActual.nombre} ${clienteActual.apellido}`}
                    </option>
                  ) : (
                    <option value="">clientes...</option>
                  )}
                  {clientes.clientes?.map((clienteSeleccion, index) => (
                    <option
                      key={index}
                      value={clienteSeleccion.id}
                    >{`${clienteSeleccion.nombre} ${clienteSeleccion.apellido}`}</option>
                  ))}
                </select>

                <svg
                  onClick={() => agregarNuevoCliente()}
                  className="icono-agregar-cliente"
                ></svg>
                {editarCliente ? (
                  <Redirect
                    to={{
                      pathname: `/editar-cliente/${traerInfoNuevoCliente().id}`,
                      state: {
                        indexCliente: clientes.clientes?.length - 1,
                      },
                    }}
                  />
                ) : (
                  ""
                )}
                <Link
                  to={{
                    pathname: `/editar-cliente/${clienteSeleccionado?.id}`,
                    state: {
                      indexCliente: undefined,
                    },
                  }}
                >
                  <svg className="icono-edit"></svg>
                </Link>
              </div>
            </div>

            {ponerParametrosBocaditos1()}

            <div className="reservar-bocaditos__btn">
              <button
                type="button"
                onClick={() => agregarBocaditos()}
                className="btn btn--medium"
              >
                Adicional
              </button>
            </div>

            <div>
              <label htmlFor="notas" className="titulo-reservas">
                Notas
              </label>
              <textarea
                type="text"
                {...register("notas", {})}
                id="notas"
                className="text-area"
              />
            </div>

            <div>
              <label htmlFor="ubicacion" className="titulo-reservas">
                Ubicacion
              </label>
              <div className="center-reservas">
                <select
                  {...register("ubicacion", { valueAsNumber: true })}
                  id="ubicacion"
                  className="select input--xxlarge"
                >
                  {pedido && ubicacion ? (
                    <option value={ubicacion?.id}>
                      {`${ubicacion?.calles} ${ubicacion?.numeroCasa}`}
                    </option>
                  ) : (
                    <option value="">Ubicacion...</option>
                  )}
                  {clienteSeleccionado
                    ? clienteSeleccionado?.ubicaciones.map(
                        (ubicacion, index) => (
                          <option key={index} value={ubicacion.id}>
                            {ubicacion.calles} {ubicacion.numeroCasa}
                          </option>
                        )
                      )
                    : ""}
                </select>

                <svg
                  onClick={(e) => {
                    agregarNuevaUbicacion();
                  }}
                  className="icono-agregar"
                />
                {editarUbicacion && (
                  <Redirect
                    to={{
                      pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${undefined}`,
                      state: {
                        ubicacionIndex:
                          clienteSeleccionado?.ubicaciones?.length - 1,
                      },
                    }}
                  ></Redirect>
                )}

                <Link
                  to={{
                    pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${ubicacionSeleccionada?.id}`,
                    state: {
                      ubicacionIndex: ubicacionSeleccionada
                        ? indexUbicacionSeleccionada
                        : "",
                    },
                  }}
                >
                  <svg className={`icono-edit`} />
                </Link>
              </div>
            </div>

            <div className="reservar-bocaditos__container-tiempo">
              <div>
                <label htmlFor="fecha" className="titulo-reservas">
                  Fecha
                </label>
                <input
                  type="date"
                  {...register("fecha", {
                    valueAsDate: true,
                  })}
                  id="fecha"
                  placeholder="20/12/2021"
                  className="input input--fit"
                />
              </div>

              <div>
                <label htmlFor="hora" className="titulo-reservas">
                  Hora de entrega
                </label>
                <input
                  type="time"
                  {...register("hora")}
                  id="hora"
                  placeholder="00:00"
                  className="input input--fit"
                />
              </div>
            </div>

            <div className="reservar-bocaditos__container-precio">
              <div>
                <label htmlFor="precio" className="titulo-reservas">
                  Precio
                </label>
                {
                  <CurrencyInput
                    {...register("precio", {
                      valueAsNumber: true,
                      value: Number(obtenerPrecio().toFixed(2)),
                    })}
                    id="precio"
                    placeholder="$0.00"
                    value={obtenerPrecio().toFixed(2)}
                    className="input input--small"
                  />
                }
              </div>

              <div>
                <label htmlFor="miPrecio" className="titulo-reservas">
                  Mi Precio
                </label>
                <CurrencyInput
                  {...register("miPrecio", { valueAsNumber: true })}
                  name="miPrecio"
                  id="miPrecio"
                  placeholder="$0.00"
                  className="input input--small"
                />
              </div>
            </div>

            <div className="reservar-bocaditos__btn">
              <button className="btn btn--large">Reservar</button>
            </div>
          </form>
        )
      )}
    </div>
  );
};

export default ReservarBocaditos;
