import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { traerCompanias } from "../../../services/auth.service";

import { registerUser } from "../../../services/auth.service";

import "./Register.css";

const Register = (props) => {
  const { register, handleSubmit } = useForm();
  const [companias, setCompanias] = useState([]);

  useEffect(() => {
    ponerCompania();
  }, []);

  async function ponerCompania() {
    const compani = await traerCompanias();
    setCompanias(compani);
  }

  function agregarCompania() {
    props.history.push("/agregar-compania");
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (data) => {
          await registerUser(data);
          await props.history.push("/login");
        })}
      >
        <div className="register__item-titulo">
          <p className="register__titulo-principal">Register</p>
        </div>
        <div className="register__item-container">
          
          <div className="register__item">
            <label htmlFor="companyId" className="register__titulo-secundario">
              Compañia
            </label>
            <select
              {...register(`companyId`)}
              id={`companyId`}
              className="select input--large"
            >
              <option value="">Compañias....</option>
              {companias.length > 0 &&
                companias.map((compania) => (
                  <option value={compania.id}>{compania.companyName}</option>
                ))}
            </select>
          </div>
          <div className="register__item">
            <button
              onClick={() => agregarCompania()}
              type="button"
              className="btn btn--medium-short"
            >
              Agregar Compañia
            </button>
          </div>

          <div className="register__item">
            <label htmlFor="email" className="register__titulo-secundario">
              Email
            </label>
            <input
              {...register("email")}
              name="email"
              id="email"
              className="input input--xxlarge"
            />
          </div>

          <div className="register__item">
            <label htmlFor="password" className="register__titulo-secundario">
              Contraseña
            </label>
            <input
              {...register("password")}
              name="password"
              id="password"
              type="password"
              className="input input--xxlarge"
            />
          </div>
          <div className="register__item">
            <label htmlFor="role" className="register__titulo-secundario">
              Role
            </label>
            <select
              {...register(`role`)}
              id={`role`}
              className="select input--large"
            >
              <option value="">roles....</option>
              <option value="admin">admin</option>
              <option value="seller">seller</option>
            </select>
          </div>

          <div className="register__btn">
            <button className="btn btn--large-short">Register</button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Register;
