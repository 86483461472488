import React from "react";
import { useForm } from "react-hook-form";

import { changePassword } from "../../../services/auth.service";

import "./ChangePassword.css";

const ChangePassword = (props) => {
  const { register, handleSubmit } = useForm();

  const token = props?.location?.search?.replace("?token=", "");

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (data.newPassword === data.confirmPassword) {
            await changePassword(data.newPassword, token);
            await props.history.push("/login");
          }
        })}
      >
        <div className="recover-password__item-titulo">
          <p className="recover-password__titulo-principal">Change Password</p>
        </div>
        <div className="recover-password__item-container">
          <div className="recover-password__item">
            <p className="recover-password__titulo-secundario">
              Create Password
            </p>
            <input
              {...register("newPassword", { required: true })}
              name="newPassword"
              id="newPassword"
              className="input input--xxlarge"
            />
          </div>
          <div className="recover-password__item">
            <p className="recover-password__titulo-secundario">
              Confirm Password
            </p>
            <input
              {...register("confirmPassword", { required: true })}
              name="confirmPassword"
              id="confirmPassword"
              className="input input--xxlarge"
            />
          </div>

          <div className="recover-password__btn">
            <button className="btn btn--large-short">Change Password</button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ChangePassword;
