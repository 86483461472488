import React from "react";

import "./Navtab.css";

export const Navtab = ({children, className}) => {
  const selected = true;
  return (
    <div className={`navtab ${className}`} >
      <div className="navtabs__container">{children}</div>
    </div>
  );
};

export const NavtabHistory = () => <div></div>;
