import React from "react";

import "./SearchBar.css";

const SearchBar = ({ handleSearch }) => {
  const onChange = (e) => {
    handleSearch(e.target.value);
  };
  
  return (
    <div className="search-bar__container">
      <div className="search-bar">
        <input type="text" onChange={onChange} />
        <span className="lupa" />
      </div>
    </div>
  );
};

export default SearchBar;
