import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Navbar, NavbarLogin } from "./components/Navbar/Navbar.jsx";
import Reservar from "./pages/Reservar/Reservar.jsx";
import Login from "./pages/Login/Login.jsx";
import Cuenta from "./pages/Cuenta/Cuenta.jsx";
import Historial from "./pages/Historial/Historial.jsx";
import Pedidos from "./pages/Pedidos/Pedidos.jsx";
import Productos from "./pages/Productos/Productos.jsx";
import EditarCliente from "./pages/Editar/Cliente/EditarCliente.jsx";
import EditarUbicacion from "./pages/Editar/Ubicacion/EditarUbicacion.jsx";
import DetalleDePedido from "./pages/DetalleDePedido/DetalleDePedido.jsx";
import Cliente from "./pages/Cliente/Cliente.jsx";
import CuentaEditar from "./pages/Cuenta/CuentaEditar/CuentaEditar.jsx";
import Register from "./pages/Login/Register/Register.jsx";
import RecoverPassword from "./pages/Login/RecoverPassword/RecoverPassword.jsx";
import ChangePassword from "./pages/Login/ChangePassword/ChangePassword.jsx";
import AgregarCompania from "./pages/Login/AgregarCompania/AgregarCompania.jsx";

function App() {
  const logged = true;

  return (
    <Router>
      <div className="App">
        {logged ? <Navbar /> : <NavbarLogin />}
        <Switch>
          <Route exact path="/reservar" component={Reservar} />
          <Route exact path="/reservar/:id" component={Reservar} />
          <Route exact path="/pedidos" component={Pedidos} />
          <Route exact path="/historial" component={Historial} />
          <Route exact path="/cuenta" component={Cuenta} />
          <Route exact path="/cuenta-editar" component={CuentaEditar} />
          <Route exact path="/productos" component={Productos} />
          <Route exact path="/editar-cliente/:id" component={EditarCliente} />
          <Route
            exact
            path="/editar-ubicacion/:usu_id/:ubi_id"
            component={EditarUbicacion}
          />
          <Route
            exact
            path="/detalle-de-pedido/:id"
            component={DetalleDePedido}
          />
          <Route exact path="/cliente/:id" component={Cliente} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/recover-password" component={RecoverPassword} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/agregar-compania" component={AgregarCompania} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
