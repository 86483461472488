import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";

import { ButtonBack } from "../../components/Button/Button";
import {
  CardAllOrderInfoArreglos,
  CardAllOrderInfoTorta,
  CardAllOrderInfoBocaditos,
} from "../../components/Card/Card";
import * as pedidosActions from "../../redux/actions/pedidosActions";
import * as clientesActions from "../../redux/actions/clientesActions";
import * as reservarActions from "../../redux/actions/reservarActions.js";
import "./DetalleDePedido.css";
import { useEffect } from "react";
import { useState } from "react";

const { clientesTraerTodos } = clientesActions;
const { pedidosTraerTodos } = pedidosActions;
const { reservarTraerTodos, toggleReservar } = reservarActions;

const DetalleDePedido = (props) => {
  const {
    match: {
      params: { id },
    },
    pedidosTraerTodos,
    clientesTraerTodos,
    reservarTraerTodos,
    toggleReservar,
  } = props;
  const { pedidos } = props.pedidosReducer;
  const { clientes } = props.clientesReducer;
  const { reservar } = props.reservarReducer;

  const location = useLocation();

  const { indexPedido } = location.state ? location.state : "";

  const [pedido1, setPedido1] = useState(null);
  const [cliente1, setCliente1] = useState(null);
  const [ubicacion1, setUbicacion1] = useState(null);

  useEffect(() => {
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }
    if (!Object.keys(pedidos).length) {
      pedidosTraerTodos();
    }
    if (!Object.keys(reservar).length) {
      reservarTraerTodos();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(pedidos).length > 0)
      setPedido1(
        pedidos.pedidos?.filter((pedido) => pedido.id === Number(id))[0]
      );
  }, [pedidos, id]);

  useEffect(() => {
    if (Object.keys(clientes).length > 0 && pedido1?.clienteId) {
      setCliente1(
        clientes.clientes?.filter(
          (cliente) => cliente.id === pedido1.clienteId
        )[0]
      );
    }
  }, [clientes, pedido1]);

  useEffect(() => {
    if (cliente1 !== null) {
      setUbicacion1(
        cliente1?.ubicaciones.filter(
          (ubicacion) => ubicacion.id === pedido1?.ubicacionId
        )[0]
      );
    }
  }, [cliente1, pedido1]);

  return (
    <div className="detalle-de-pedido">
      <div>
        <ButtonBack />
        <p className="detalle-de-pedido__titulo-principal">Detalle de pedido</p>
      </div>
      {pedido1 &&
        cliente1 &&
        (pedido1.tipoPedidoId === 1 ? (
          <CardAllOrderInfoTorta
            pedido={pedido1}
            ubicacion={ubicacion1}
            cliente={cliente1}
            onClick={toggleReservar}
            indexPedido={indexPedido}
          />
        ) : pedido1.tipoPedidoId === 3 ? (
          <CardAllOrderInfoArreglos
            pedido={pedido1}
            ubicacion={ubicacion1}
            cliente={cliente1}
            onClick={toggleReservar}
            indexPedido={indexPedido}
          />
        ) : pedido1.tipoPedidoId === 2 ? (
          <CardAllOrderInfoBocaditos
            pedido={pedido1}
            ubicacion={ubicacion1}
            cliente={cliente1}
            onClick={toggleReservar}
            indexPedido={indexPedido}
          />
        ) : (
          ""
        ))}
    </div>
  );
};

const mapStateToProps = ({
  clientesReducer,
  reservarReducer,
  pedidosReducer,
}) => {
  return { clientesReducer, reservarReducer, pedidosReducer };
};

const mapDispatchToProps = {
  clientesTraerTodos,
  pedidosTraerTodos,
  reservarTraerTodos,
  toggleReservar,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalleDePedido);
