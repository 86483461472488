import React, { useLayoutEffect, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";

import "./EditarUbicacion.css";
import { ButtonBack } from "../../../components/Button/Button";
import Spinner from "../../../components/Spinner/Spiner";
import Fatal from "../../../components/Fatal/Fatal";
import * as clientesActions from "../../../redux/actions/clientesActions";

const EditarUbicacion = (props) => {
  const { register, handleSubmit } = useForm();
  const [clienteActual, setClienteActual] = useState(null);
  const [ubicacion1, setUbicacion1] = useState(null);
  const {
    match: {
      params: { usu_id, ubi_id },
    },
    clientesTraerTodos,
    actualizarUbicacion,
    eliminarUbicacion,
  } = props;

  const { clientes, error, cargando } = props.clientesReducer;
  const {
    user: {
      user: { companyId },
    },
  } = props.cuentaReducer;

  useLayoutEffect(() => {
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(clientes).length > 0) {
      setClienteActual(
        clientes.clientes?.filter((cliente) => cliente.id === Number(usu_id))[0]
      );
    }
  }, [clientes, usu_id]);

  useEffect(() => {
    if (clienteActual !== null) {
      setUbicacion1(
        clienteActual?.ubicaciones.filter(
          (ubicacion) => ubicacion.id === Number(ubi_id)
        )[0]
      );
    }
  }, [clienteActual, ubi_id, usu_id]);

  const location = useLocation();
  const { ubicacionIndex } = location.state;

  const ubicacionId = ubicacion1?.id || `array${ubicacionIndex}`;

  const asignar = (data) => {
    const object = {
      companyId: companyId,
      clienteId: Number(usu_id),
      calles: data.calles,
      numeroCasa: Number(data.numCasa),
      referencia: data.referencia,
    };

    return object;
  };
  console.log("cargando", cargando);

  const regresar = () => {
    eliminarUbicacion(Number(usu_id), ubicacionId);
    props.history.goBack();
  };
  return (
    <div className="editar-ubicacion">
      <div>
        <ButtonBack />
        <p className="editar-ubicacion__titulo-principal">Ubicacion</p>
      </div>
      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        ubicacion1 && (
          <form
            onSubmit={handleSubmit(async (data) => {
              await actualizarUbicacion(
                asignar(data),
                Number(usu_id),
                ubicacionId
              );
              props.history.goBack();
            })}
          >
            <div>
              <label htmlFor="calles" className="editar-ubicacion__titulo">
                Calles
              </label>
              <input
                {...register("calles", { value: ubicacion1?.calles })}
                id="calles"
                name="calles"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="numCasa" className="editar-ubicacion__titulo">
                Numero de casa
              </label>
              <input
                {...register("numCasa", { value: ubicacion1?.numeroCasa })}
                id="numCasa"
                name="numCasa"
                className="input input--xsmall"
              />
            </div>
            <div>
              <label htmlFor="referencia" className="editar-ubicacion__titulo">
                Referencia
              </label>
              <textarea
                {...register("referencia", { value: ubicacion1?.referencia })}
                id="referencia"
                name="referencia"
                className="text-area"
              />
            </div>

            <div className="editar-cliente__btn">
              <button
                onClick={() => regresar()}
                type="button"
                className="btn btn--medium"
              >
                Eliminar
              </button>
            </div>

            <div className="editar-ubicacion__btn">
              <button type="submit" className="btn btn--large">
                Guardar
              </button>
            </div>
          </form>
        )
      )}

      {ubicacion1 === null ||
        (ubicacion1 === undefined && (
          <form
            onSubmit={handleSubmit(async (data) => {
              await actualizarUbicacion(
                asignar(data),
                clienteActual.id,
                ubicacionId
              );
              props.history.goBack();
            })}
          >
            <div>
              <label htmlFor="calles" className="editar-ubicacion__titulo">
                Calles
              </label>
              <input
                {...register("calles")}
                id="calles"
                name="calles"
                className="input input--xxxlarge"
              />
            </div>

            <div>
              <label htmlFor="numCasa" className="editar-ubicacion__titulo">
                Numero de casa
              </label>
              <input
                {...register("numCasa")}
                id="numCasa"
                name="numCasa"
                className="input input--xsmall"
              />
            </div>
            <div>
              <label htmlFor="referencia" className="editar-ubicacion__titulo">
                Referencia
              </label>
              <textarea
                {...register("referencia")}
                id="referencia"
                name="referencia"
                className="text-area"
              />
            </div>

            <div className="editar-cliente__btn">
              <button
                onClick={() => regresar()}
                type="button"
                className="btn btn--medium"
              >
                Eliminar
              </button>
            </div>

            <div className="editar-ubicacion__btn">
              <button type="submit" className="btn btn--large">
                Guardar
              </button>
            </div>
          </form>
        ))}
    </div>
  );
};

const mapStateToProps = ({ clientesReducer, cuentaReducer }) => {
  return { clientesReducer, cuentaReducer };
};

export default connect(mapStateToProps, clientesActions)(EditarUbicacion);
