import React, { useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";

import { CardClientInfo } from "../../../components/Card/CardCliente/CardCliente";
import * as clientesActions from "../../../redux/actions/clientesActions";

const HistorialClientes = (props) => {
  const { clientes, busqueda, clientesTraerTodos, agregarCliente } = props;
  const [size, setSize] = useState(10);

  // useEffect(() => {
  //   // if (!Object.keys(clientes).length) {
  //   clientesTraerTodos();
  //   // }
  // }, []);

  useLayoutEffect(() => {
    if (!Object.keys(clientes).length) {
      clientesTraerTodos();
    }
  }, []);

  const resultado = clientes?.clientes?.filter((cliente) => {
    return Object.keys(cliente).some((key) => {
      if (cliente[key] !== null) {
        return cliente[key]
          .toString()
          .toLowerCase()
          .includes(busqueda.toLowerCase().trim());
      }
    });
  });

  const ponerClientes = (resultado) => {
    const arrayBusqueda = resultado || clientes.clientes;
    return arrayBusqueda
      ?.slice(0, size)
      ?.map((cliente, indexCliente) => (
        <CardClientInfo
          key={cliente.id}
          className={indexCliente % 2 === 0 ? "" : "card__client-info--black"}
          editColor={indexCliente % 2 === 0 ? "" : "edit--white"}
          cliente={cliente}
          infoColor={indexCliente % 2 === 0 ? "" : "info--white"}
        />
      ));
  };
  const mostrarMas = () => {
    setSize(size + 10);
  };

  return (
    <div>
      {ponerClientes(resultado)}

      <button
        className="btn historial-btn btn--small"
        onClick={() => agregarCliente()}
      >
        Agregar Cliente
      </button>
      <button
        onClick={() => mostrarMas()}
        className="btn historial-btn btn--small"
      >
        Mostrar mas
      </button>
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.clientesReducer;
};

export default connect(mapStateToProps, clientesActions)(HistorialClientes);
