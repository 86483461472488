import React, { useState, useLayoutEffect } from "react";
import { connect } from "react-redux";

import "./Historial.css";
import { Navtab } from "../../components/Navtab/Navtab";
import { ButtonTab } from "../../components/Button/Button";
import SearchBar from "../../components/Input/SearchBar/SearchBar";
import CardFilter from "../../components/Card/CardFilter/CardFilter";
import HistorialClientes from "./HistorialClientes/HistorialClientes";
import HistorialPedidos from "./HistorialPedidos/HistorialPedidos";
import Fatal from "../../components/Fatal/Fatal";
import Spinner from "../../components/Spinner/Spiner";
import * as pedidosActions from "../../redux/actions/pedidosActions";
import * as tabHistorialActions from "../../redux/actions/tabHistorialActions";

const { pedidosTraerTodos, setFiltroPrecio, setFiltroFecha } = pedidosActions;
const { tabHistorialTraerTodos, toggleTabHistorial } = tabHistorialActions;

const Historial = (props) => {
  const {
    pedidosTraerTodos,
    setFiltroPrecio,
    setFiltroFecha,
    tabHistorialTraerTodos,
    toggleTabHistorial,
  } = props;
  const { pedidos, error, cargando } = props.pedidosReducer;
  const { tabHistorial } = props.tabHistorialReducer;
  const { isLoggedIn } = props.cuentaReducer;

  if (!isLoggedIn) {
    props.history.push("/login");
  }

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    if (!Object.keys(pedidos).length) {
      pedidosTraerTodos();
    }
    if (!Object.keys(tabHistorial).length) {
      tabHistorialTraerTodos();
    }
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
  };

  const getParametrosPrecio = (parametro1, parametro2) => {
    const pedidosFiltrados = pedidos
      ? pedidos.pedidos?.filter(
          (pedido, indexPedido) =>
            Number(pedido.precio) >= Number(parametro1) &&
            Number(pedido.precio) <= Number(parametro2)
        )
      : [];
    setFiltroPrecio(pedidosFiltrados);
  };

  const getParametrosFecha = (parametro1, parametro2) => {
    const pedidosFiltrados =
      pedidos && pedidos.filtrosPrecio.length > 1
        ? pedidos.filtrosPrecio?.filter(
            (pedido, indexPedido) =>
              pedido.fecha.getTime() >= parametro1.getTime() &&
              pedido.fecha.getTime() <= parametro2.getTime()
          )
        : pedidos.pedidos?.filter(
            (pedido, indexPedido) =>
              pedido.fecha.getTime() >= parametro1.getTime() &&
              pedido.fecha.getTime() <= parametro2.getTime()
          );

    setFiltroFecha(pedidosFiltrados);
  };

  const getArrows = (arrow) => {
    const arrayPrecio =
      arrow.precio === false
        ? pedidos.filtrosPrecio?.sort((a, b) => b.precio - a.precio)
        : pedidos.filtrosPrecio?.sort((a, b) => a.precio - b.precio);

    setFiltroPrecio(arrayPrecio);

    const arrayFecha =
      arrow.fecha === false
        ? pedidos.filtrosFecha?.sort(
            (a, b) => b.fecha.getTime() - a.fecha.getTime()
          )
        : pedidos.filtrosFecha?.sort(
            (a, b) => a.fecha.getTime() - b.fecha.getTime()
          );

    setFiltroFecha(arrayFecha);
  };

  // const getSelected = (tab) => {
  //   const tabOption = {
  //     clientes: () => setTabsHistorial({ clientes: true }),
  //     pedidos: () => setTabsHistorial({ pedidos: true }),
  //   };
  //   return tabOption[tab]();
  // };

  return (
    <div className="historial">
      <div>
        <p className="historial__titulo-principal">Historial de ventas</p>
      </div>
      <div>
        <Navtab className="navtab--historial">
          <ButtonTab
            onClick={() => toggleTabHistorial("clientes")}
            selected={tabHistorial.clientes}
          >
            Clientes
          </ButtonTab>
          <ButtonTab
            onClick={() => toggleTabHistorial("pedidos")}
            selected={tabHistorial.pedidos}
          >
            Pedidos
          </ButtonTab>
        </Navtab>

        <SearchBar handleSearch={handleSearch} />

        <CardFilter
          getParametrosPrecio={getParametrosPrecio}
          getParametrosFecha={getParametrosFecha}
          getArrows={getArrows}
        />

        {error ? (
          <Fatal mensaje={error} />
        ) : cargando && !error ? (
          <Spinner />
        ) : tabHistorial.clientes ? (
          <HistorialClientes busqueda={searchQuery} />
        ) : tabHistorial.pedidos ? (
          <HistorialPedidos busqueda={searchQuery} />
        ) : (
          <div>Algo salio mal</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  pedidosReducer,
  tabHistorialReducer,
  cuentaReducer,
}) => {
  return { pedidosReducer, tabHistorialReducer, cuentaReducer };
};

const mapDispatchToProps = {
  pedidosTraerTodos,
  setFiltroPrecio,
  setFiltroFecha,
  tabHistorialTraerTodos,
  toggleTabHistorial,
};

export default connect(mapStateToProps, mapDispatchToProps)(Historial);
