import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "../../../Button/Button";
import "../CardProductos.css";

export const CardProductosTamano = ({
  editando,
  porciones,
  editColor,
  toggleEdit,
  setEditando,
}) => {
  const agregarTamano = (porciones) =>
    porciones?.map((porcion, indx) => (
      <p key={indx} className="data">
        {porcion.tamano ? `${porcion.tamano}cm` : ""}
      </p>
    ));

  const agregarPorcion = (porciones) =>
    porciones?.map((porcion, indx) => (
      <p key={indx} className="data">
        {porcion.numPorcionesDe && porcion.numPorcionesA
          ? `de ${porcion.numPorcionesDe} a ${porcion.numPorcionesA} porciones`
          : ""}
      </p>
    ));
  const agregarPrecio = (porciones) =>
    porciones?.map((porcion, indx) => (
      <p key={indx} className="data">
        {porcion.precio ? `$${porcion.precio}` : ""}
      </p>
    ));

  return (
    <div className="card-productos card__producto-tamano">
      <div className="card-productos__inner card__producto-tamano__inner">
        <div>
          <p className="titulo">Tamaño</p>
          {agregarTamano(porciones)}
        </div>
        <div>
          <p className="titulo"># Porciones</p>
          {agregarPorcion(porciones)}
        </div>
        <div>
          <p className="titulo">Precio</p>
          {agregarPrecio(porciones)}
        </div>
        {!editando && (
          <svg
            onClick={() => {
              toggleEdit();
              setEditando(true);
            }}
            className={`card-productos__edit ${editColor}`}
          />
        )}
      </div>
    </div>
  );
};

export const CardProductosTamanoEdit = ({
  setEditando,
  id,
  porciones,
  agregar,
  toggleEdit,
  eliminar,
  editarTamano,
}) => {
  const { register, handleSubmit, getValues } = useForm();

  const asignar = (data) => {
    let array = [];
    porciones?.map((porcion, indx) => {
      const key = porcion.id ? porcion.id : `array${indx}`;

      array.push({
        id: porcion.id ? porcion.id : null,
        tortaPorcionId: 1,
        tamano: data[`tamano${key}`],
        numeroPorcionesDe: data[`numPorcionesDe${key}`],
        numeroPorcionesA: data[`numPorcionesA${key}`],
        precio: data[`precio${key}`],
      });
    });

    array.map((porcion) => {
      if (porcion.id === null) {
        delete porcion.id;
      }
    });
    return array;
  };

  const agregarParametrosTamano = (porciones) => {
    return porciones?.map((porcion, indx) => {
      const key = porcion.id ? porcion.id : `array${indx}`;
      return (
        <div key={key} className="tamano-edit">
          <input
            {...register(`tamano${key}`, { value: porcion.tamano })}
            name={`tamano${key}`}
            id={`tamano${key}`}
            className="input input--xxsmall"
          />
          <p className="data">cm</p>
        </div>
      );
    });
  };

  const agregarParametroPorciones = (porciones) =>
    porciones?.map((porcion, indx) => {
      const key = porcion.id ? porcion.id : `array${indx}`;
      return (
        <div key={key} className="numero-porciones-edit">
          <label htmlFor={`numPorcionesDe${porcion.id}`} className="data">
            de
          </label>
          <input
            {...register(`numPorcionesDe${key}`, {
              value: porcion.numPorcionesDe,
            })}
            name={`numPorcionesDe${key}`}
            id={`numPorcionesDe${key}`}
            className="input input--xxsmall"
          />
          <label htmlFor={`numPorcionesA${key}`} className="data">
            a
          </label>
          <input
            {...register(`numPorcionesA${key}`, {
              value: porcion.numPorcionesA,
            })}
            name={`numPorcionesA${key}`}
            id={`numPorcionesA${key}`}
            className="input input--xxsmall"
          />
        </div>
      );
    });
  const agregarParametroPrecio = (porciones) =>
    porciones?.map((porcion, indx) => {
      const key = porcion.id ? porcion.id : `array${indx}`;
      return (
        <div key={key} className="precio-edit">
          <label htmlFor={`precio${key}`} className="data">
            $
          </label>
          <input
            {...register(`precio${key}`, { value: porcion.precio })}
            name={`precio${key}`}
            id={`precio${key}`}
            className="input input--xsmall"
          />

          <svg onClick={() => eliminar(key)} className="delete" />
        </div>
      );
    });

  return (
    <div className="card-productos card__producto-tamano">
      <form
        onSubmit={handleSubmit((data) => {
          editarTamano(asignar(data));
          setEditando(false);
          toggleEdit();
        })}
      >
        <div className="card-productos__inner card__producto-tamano__inner">
          <div>
            <label htmlFor="tamano1" className="titulo">
              Tamaño
            </label>

            {agregarParametrosTamano(porciones)}
          </div>
          <div>
            <label htmlFor="numPorciones" className="titulo">
              # Porciones
            </label>

            {agregarParametroPorciones(porciones)}
          </div>

          <div>
            <label htmlFor="precio" className="titulo">
              Precio
            </label>

            {agregarParametroPrecio(porciones)}
          </div>

          <div className="btn-guardar">
            <svg
              onClick={() => {
                agregar(asignar(getValues()));
              }}
              className="plus"
            />
          </div>

          <div className="btn-guardar">
            <button className="btn btn--xsmall">Guardar</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const CardProductosParametro = ({
  editando,
  setEditando,
  parametro,
  editColor,
  id,
  toggleEdit,
}) => {
  const agregarData = (parametro) =>
    parametro.datos?.map((elemento, indx) => {
      const key = elemento.id ? `valor${elemento.id}` : `valorArray${indx}`;
      return (
        <div key={key}>
          <p className="titulo">{parametro ? parametro.parametro : ""}</p>
          <p className="data">{parametro ? elemento.valor : ""}</p>
        </div>
      );
    });

  return (
    <div>
      <p className="titulo-card">{parametro.parametro}</p>
      <div className="card-productos card__producto-parametro-tamano">
        <div className="card-productos__inner card__producto-parametro__inner">
          {agregarData(parametro)}

          {!editando && (
            <svg
              onClick={() => {
                setEditando(true);
                toggleEdit(id);
              }}
              className={`card-productos__edit ${editColor}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const CardProductosParametroEdit = ({
  companyId,
  setEditando,
  parametros,
  index,
  id,
  eliminarParametro,
  agregar,
  eliminar,
  editarCategoriaParametro,
}) => {
  const { register, handleSubmit, getValues } = useForm();

  const asignar = (data) => {
    let array = [];

    parametros?.datos?.map((dato, indx) => {
      array.push({
        id: dato.id ? dato.id : null,
        tortaParametroId: id !== undefined ? id : 1,
        valor: data[`${parametros.parametro}item${indx}`],
      });
    });

    array.map((elemento) => {
      if (elemento.id === null) {
        delete elemento.id;
      }
    });

    const objeto = {
      id: id ? id : null,
      companyId: companyId,
      parametro: data[`parametro${id}titulo`],
      datosTortaParametros: array,
    };
    if (objeto.id === null) {
      delete objeto.id;
    }
    return objeto;
  };

  const agregarParametroData = (parametros) =>
    parametros.datos?.map((dato, indx) => {
      const key = dato.id ? `valor${dato.id}` : `valorArray${indx}`;
      return (
        <div key={key}>
          <label
            htmlFor={`${parametros.parametro}item${indx}`}
            className="titulo"
          >
            {parametros.parametro}
          </label>
          <div className="tamano-edit">
            <input
              {...register(`${parametros.parametro}item${indx}`, {
                value: dato.valor,
              })}
              name={`${parametros.parametro}item${indx}`}
              id={`${parametros.parametro}item${indx}`}
              className="input input--small"
            />
            <svg className="delete" onClick={() => eliminar(id, dato.id)} />
          </div>
        </div>
      );
    });

  return (
    <div className="card-productos-tortas-edit-container">
      <form
        onSubmit={handleSubmit((data) => {
          editarCategoriaParametro(asignar(data));
          setEditando(false);
        })}
      >
        <input
          name={`parametro${id}titulo`}
          id={`parametro${id}titulo`}
          {...register(`parametro${id}titulo`, {
            value: parametros.parametro,
          })}
          className="input input--medium titulo-card__input"
        />
        <button
          type="button"
          onClick={() => {
            setEditando(false);
            eliminarParametro(id ? id : `array${index}`);
          }}
          className="btn btn-eliminar-categoria"
        >{`Eliminar ${parametros.parametro}`}</button>

        <div className="card-productos card__producto-parametro-tamano">
          <div className="card-productos__inner card__producto-parametro-edit__inner">
            {agregarParametroData(parametros)}

            <div className="btn-guardar-parametro">
              <svg
                className="plus"
                onClick={() => agregar(id, asignar(getValues()))}
              />
            </div>

            <div className="btn-guardar-parametro">
              <Button className="btn--xsmall">Guardar</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
