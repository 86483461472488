import React, { useState } from "react";
import { useForm } from "react-hook-form";

import "./CardFilter.css";
const CardFilter = ({ getParametrosPrecio, getParametrosFecha, getArrows }) => {
  const { register, handleSubmit, getValues } = useForm();

  const [arrow, setArrow] = useState({
    precio: true,
    fecha: true,
  });

  const filterUpOrDown = (variable) => {
    const { precio, fecha } = arrow;
    const variables = {
      precio: () => setArrow({ precio: !precio, fecha: fecha }),
      fecha: () => setArrow({ precio: precio, fecha: !fecha }),
    };

    return variables[variable]();
  };

  const onBlurFecha = (e) => {
    getParametrosFecha(getValues("fechaDe"), getValues("fechaA"));
  };

  const onBlur = (e) => {
    getParametrosPrecio(getValues("precioDe"), getValues("precioA"));
  };

  return (
    <div>
      <form onSubmit={handleSubmit((data) => {})}>
        <div className="cardfilters-container">
          <div className="cardfilter">
            <div className="cardfilter__grid">
              <div className="center">
                <p className="titulo-filter">Precio</p>
                <svg
                  className={`${arrow.precio ? "up-arrow" : "down-arrow"}`}
                  onClick={() => {
                    filterUpOrDown("precio");
                    getArrows(arrow);
                  }}
                />
              </div>
              <div>
                <label htmlFor="precioDe" className="titulo-filter-de">
                  De
                </label>
                <input
                  {...register("precioDe")}
                  name="precioDe"
                  placeholder="$40.00"
                  className="input input--xsmall-short"
                  onBlur={onBlur}
                />
              </div>
              <div>
                <label htmlFor="precioA" className="titulo-filter-de">
                  A
                </label>
                <input
                  {...register("precioA")}
                  name="precioA"
                  id="precioA"
                  placeholder="$100.00"
                  className="input input--xsmall-short"
                  onBlur={onBlur}
                />
              </div>
            </div>
          </div>

          <div className="cardfilter">
            <div className="cardfilter__grid">
              <div className="center">
                <p className="titulo-filter">Fecha</p>
                <svg
                  className={`${arrow.fecha ? "up-arrow" : "down-arrow"}`}
                  onClick={() => {
                    filterUpOrDown("fecha");
                    getArrows(arrow);
                  }}
                />
              </div>
              <div>
                <label htmlFor="fechaDe" className="titulo-filter-de">
                  De
                </label>
                <input
                  type="date"
                  id="fechaDe"
                  {...register("fechaDe", { valueAsDate: true })}
                  onBlur={onBlurFecha}
                  className="input input--xsmall-short"
                />
              </div>
              <div>
                <label htmlFor="fechaA" className="titulo-filter-de">
                  A
                </label>
                <input
                  type="date"
                  id="fechaA"
                  {...register("fechaA", { valueAsDate: true })}
                  onBlur={onBlurFecha}
                  className="input input--xsmall-short"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CardFilter;
