import {
  TRAER_TODOS,
  CARGANDO,
  ERROR,
  ACTUALIZAR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../types/CuentaTypes";

const user = JSON.parse(localStorage.getItem("user"));

const INITIAL_STATE = user
  ? { isLoggedIn: true, user, cargando: false, error: "" }
  : { isLoggedIn: false, user: {}, cargando: false, error: "" };

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TRAER_TODOS:
      return {
        ...state,
        user: action.payload,
        cargando: false,
        error: "",
      };
    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false };

    case ACTUALIZAR:
      return {
        ...state,
        user: action.payload,
        cargando: false,
        error: "",
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        cargando: false,
        error: "",
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        cargando: false,
        error: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        cargando: false,
        error: "",
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
        cargando: false,
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
        cargando: false,
        error: "",
      };
    default:
      return state;
  }
};
