import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";

import "./ProductosBocaditos.css";
import SearchBar from "../../../components/Input/SearchBar/SearchBar";
import {
  CardProductosBocaditos,
  CardProductosBocaditosEdit,
} from "../../../components/Card/CardProductos/CardProductosBocaditos/CardProductosBocaditos.jsx";
import Spinner from "../../../components/Spinner/Spiner";
import Fatal from "../../../components/Fatal/Fatal";
import * as bocaditosActions from "../../../redux/actions/bocaditosActions";

const ProductosBocaditos = (props) => {
  const [editando, setEditando] = useState(false);
  const {
    error,
    cargando,
    companyId,
    bocaditos,
    bocaditosTraerTodos,
    cambioEditar,
    agregarParametro,
    agregarOpcion,
    eliminarParametro,
    eliminarOpcion,
    agregarEdit,
    eliminarEdit,
    editarProducto,
    editarParametro,
    editarDatos,
  } = props;

  const [searchQuery, setSearchQuery] = useState("");

  useLayoutEffect(() => {
    if (!bocaditos.length) {
      bocaditosTraerTodos();
    }
  }, []);

  const resultado = bocaditos?.filter((producto) => {
    return Object.keys(producto).some((key) => {
      return producto[key]
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase().trim());
    });
  });

  const handleSearch = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
  };

  const agregarProductos = () => {
    const array = resultado ? resultado : bocaditos;
    return array?.map((producto, indexProducto) => (
      <div key={indexProducto} className="productos-bocaditos__item">
        {producto.editar ? (
          <CardProductosBocaditosEdit
            companyId={companyId}
            setEditando={setEditando}
            indexProducto={indexProducto}
            onClickEditar={cambioEditar}
            producto={producto}
            eliminarBocadito={eliminarEdit}
            agregarParametro={agregarParametro}
            eliminarParametro={eliminarParametro}
            agregarOpcion={agregarOpcion}
            eliminarOpcion={eliminarOpcion}
            editarProducto={editarProducto}
            editarParametro={editarParametro}
            editarDatos={editarDatos}
          />
        ) : (
          <CardProductosBocaditos
            editando={editando}
            setEditando={setEditando}
            indx={indexProducto}
            onClickEditar={cambioEditar}
            producto={producto}
          />
        )}
      </div>
    ));
  };

  return (
    <div>
      <SearchBar handleSearch={handleSearch} />

      {error ? (
        <Fatal mensaje={error} />
      ) : cargando && !error ? (
        <Spinner />
      ) : (
        agregarProductos()
      )}

      {!editando && (
        <div className="productos-bocaditos__item">
          <button
            onClick={() => {
              setEditando(true);
              agregarEdit();
            }}
            className="btn btn--small"
          >
            Agregar Nuevo Bocadito
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.bocaditosReducer;
};

export default connect(mapStateToProps, bocaditosActions)(ProductosBocaditos);
