import axios from "axios";

import { authHeader } from "../../services/user.service";

import {
  CARGANDO,
  ERROR,
  TRAER_TODOS,
  ACTUALIZAR,
} from "../types/ArreglosTypes";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const URL = `${URL_HOST}/api/v1/arreglos`;
const URL_TIPO_ARREGLOS = `${URL}/tipo`;
const URL_VALOR_ARREGLOS = `${URL}/valor`;

export const arreglosTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const header = await authHeader();

    const rtaArreglos = await axios.get(URL_TIPO_ARREGLOS, {
      headers: {
        ...header,
      },
    });
    const dataArreglos = await rtaArreglos.data;
    const arrayArreglos = [];
    dataArreglos.map((tipoArreglo) => {
      arrayArreglos.push({
        id: tipoArreglo.id,
        tipo: tipoArreglo.tipo,
        editar: false,
        arregloProductos: tipoArreglo.arregloProductos.map((producto) => ({
          id: producto.id,
          arregloTipoProductoId: producto.arregloTipoProductoId,
          producto: producto.producto,
          precio: producto.precio,
        })),
      });
    });
    const respuesta = {
      tipoDeArreglo: ["Caja cupcakes", "Desayuno", "Caja de dulces"],
      productos: [...arrayArreglos],
    };

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Informacion de clientes no disponible",
    });
  }
};

export const productosToggleEditar =
  (idTipoProducto) => (dispatch, getState) => {
    const { arreglos } = getState().arreglosReducer;

    let indexTipoProducto = arreglos.productos.findIndex(
      (tipoProducto) => tipoProducto.id === idTipoProducto
    );

    if (indexTipoProducto === -1) {
      indexTipoProducto = idTipoProducto.replace("array", "");
    }

    const seleccionada = arreglos.productos[indexTipoProducto];

    const newObject = {
      ...arreglos,
    };

    newObject.productos = [...arreglos.productos];

    newObject.productos[indexTipoProducto] = {
      ...arreglos.productos[indexTipoProducto],
      editar: !seleccionada.editar,
    };

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  };

export const agregarProducto = () => (dispatch, getState) => {
  const { arreglos } = getState().arreglosReducer;

  const newObject = {
    ...arreglos,
  };

  newObject.productos = [
    ...arreglos.productos,
    {
      tipo: "",
      editar: true,
      arregloProductos: [
        {
          producto: "",
          precio: "",
        },
        {
          producto: "",
          precio: "",
        },
      ],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const eliminarProducto =
  (idTipoProducto) => async (dispatch, getState) => {
    const { arreglos } = getState().arreglosReducer;

    dispatch({
      type: CARGANDO,
    });

    //Indexes
    let indexTipoProducto = arreglos.productos.findIndex(
      (tipoProducto) => tipoProducto.id === idTipoProducto
    );

    try {
      const header = await authHeader();

      if (indexTipoProducto !== -1) {
        await axios.delete(`${URL_TIPO_ARREGLOS}/${idTipoProducto}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexTipoProducto = idTipoProducto.replace("array", "");
      }

      const seleccionada = arreglos.productos;

      const newObject = {
        ...arreglos,
        productos: seleccionada
          .slice(0, indexTipoProducto)
          .concat(seleccionada.slice(indexTipoProducto + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarDataProducto =
  (idTipoProducto, data) => (dispatch, getState) => {
    const { arreglos } = getState().arreglosReducer;

    let indexTipoProducto = arreglos.productos.findIndex(
      (tipoProducto) => tipoProducto.id === idTipoProducto
    );

    if (indexTipoProducto === -1) {
      indexTipoProducto = idTipoProducto.replace("array", "");
    }

    const tipoProducto = {
      tipo: data.tipo,
      editar: true,
      arregloProductos: data.arregloProductos.map((producto) => ({
        id: producto.id,
        arregloTipoProductoId: producto.arregloTipoProductoId,
        producto: producto.producto,
        precio: producto.precio,
      })),
    };

    tipoProducto.arregloProductos.map((producto) => {
      if (producto.id === undefined) {
        delete producto.id;
      }
    });

    if (data.id) {
      tipoProducto.id = data.id;
    }

    const newObject = {
      ...arreglos,
    };

    newObject.productos = [...arreglos.productos];

    newObject.productos[indexTipoProducto] = {
      ...tipoProducto,
      editar: true,
      arregloProductos: [
        ...tipoProducto.arregloProductos,
        {
          producto: "",
          precio: "",
        },
      ],
    };

    dispatch({
      type: ACTUALIZAR,
      payload: newObject,
    });
  };

export const eliminarDataPrametro =
  (idTipoProducto, idProducto) => async (dispatch, getState) => {
    const { arreglos } = getState().arreglosReducer;

    dispatch({
      type: CARGANDO,
    });

    //Indexes
    let indexTipoProducto = arreglos.productos.findIndex(
      (tipoProducto) => tipoProducto.id === idTipoProducto
    );

    if (indexTipoProducto === -1) {
      indexTipoProducto = idTipoProducto.replace("array", "");
    }

    let indexProducto = arreglos.productos[
      indexTipoProducto
    ].arregloProductos.findIndex((producto) => producto.id === idProducto);

    //DELETE LOGIC
    try {
      const header = await authHeader();
      if (indexProducto !== -1) {
        await axios.delete(`${URL_VALOR_ARREGLOS}/${idProducto}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexProducto = idProducto.replace("array", "");
      }
      const seleccionada =
        arreglos.productos[indexTipoProducto].arregloProductos;

      const newObject = {
        ...arreglos,
      };

      newObject.productos = [...arreglos.productos];

      newObject.productos[indexTipoProducto] = {
        ...arreglos.productos[indexTipoProducto],
        arregloProductos: seleccionada
          .slice(0, indexProducto)
          .concat(seleccionada.slice(indexProducto + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const editarArreglo =
  (idTipoProducto, data) => async (dispatch, getState) => {
    const { arreglos } = getState().arreglosReducer;
    dispatch({
      type: CARGANDO,
    });

    const datos = {
      datos: [data],
    };

    try {
      const header = await authHeader();

      const rtaPost = await axios.post(URL_TIPO_ARREGLOS, datos, {
        headers: {
          ...header,
        },
      });

      const rtaData = await rtaPost.data[0];

      let indexTipoProducto = arreglos.productos.findIndex(
        (tipoProducto) => tipoProducto.id === rtaData.id
      );

      const tipoProducto = {
        tipo: rtaData.tipo,
        editar: false,
        arregloProductos: rtaData.arregloProductos.map((producto) => ({
          id: producto.id,
          arregloTipoProductoId: producto.arregloTipoProductoId,
          producto: producto.producto,
          precio: producto.precio,
        })),
      };

      if (indexTipoProducto === -1) {
        indexTipoProducto = idTipoProducto.replace("array", "");
      }

      const newObject = {
        ...arreglos,
      };

      newObject.productos = [...arreglos.productos];

      newObject.productos[indexTipoProducto] = {
        ...tipoProducto,
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };
