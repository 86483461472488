import React, { useLayoutEffect } from "react";
import { connect } from "react-redux";

import "./Cuenta.css";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button/Button.jsx";
import * as cuentaActions from "../../redux/actions/cuentaActions";

const Cuenta = (props) => {
  const { user, cuentaTraerTodos, logoutCuenta, isLoggedIn } = props;

  if (!isLoggedIn) {
    props.history.push("/login");
  }

  useLayoutEffect(() => {
    if (!Object.keys(user).length) {
      cuentaTraerTodos();
    }
  });

  const currentUser = user?.user || {};

  const cerrarSesion = async () => {
    await logoutCuenta();
  };

  return (
    <div className="cuenta">
      <div className="cuenta__item-titulo">
        <p onClick={() => cerrarSesion()} className="cuenta__link">
          Cerrar sesion
        </p>
        <p className="cuenta__titulo-principal">Cuenta</p>
      </div>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Nombre</p>
        <p className="cuenta__data">{currentUser?.nombre}</p>
      </div>

      <hr className="cuenta__line"></hr>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Apellidos</p>
        <p className="cuenta__data">{currentUser?.apellido}</p>
      </div>

      <hr className="cuenta__line"></hr>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Cedula</p>
        <p className="cuenta__data">{currentUser?.cedula}</p>
      </div>

      <hr className="cuenta__line"></hr>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Telefono</p>
        <p className="cuenta__data">{currentUser?.telefono}</p>
      </div>

      <hr className="cuenta__line"></hr>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Email</p>
        <p className="cuenta__data">{currentUser?.email}</p>
      </div>

      <hr className="cuenta__line"></hr>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Facebook</p>
        <p className="cuenta__data">{currentUser?.facebook}</p>
      </div>

      <hr className="cuenta__line"></hr>

      <div className="cuenta__item">
        <p className="cuenta__titulo-secundario">Instagram</p>
        <p className="cuenta__data">{currentUser?.instagram}</p>
      </div>

      <div className="cuenta__btn">
        <Link className="cuenta__link-btn" to="/cuenta-editar">
          <Button className="btn--large">Editar</Button>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.cuentaReducer;
};

export default connect(mapStateToProps, cuentaActions)(Cuenta);
