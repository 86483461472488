import axios from "axios";

import { login, logout } from "../../services/auth.service";

import { authHeader } from "../../services/user.service";

import {
  CARGANDO,
  ERROR,
  TRAER_TODOS,
  ACTUALIZAR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../types/CuentaTypes";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const URL = `${URL_HOST}/api/v1`;
const URL_USERS = `${URL}/users`;

export const cuentaTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const respuesta = {
      id: 0,
      nombres: "Carlos Jose",
      apellidos: "Rodriguez Garcia",
      cedula: "5544465452",
      telefono: "1354545420",
      razonSocial: "ejemplo",
      email: "khffsk@jkfghshf.com",
      paginaWeb: "example.com",
      facebook: "Jose Armeti",
      instagram: "J_arme",
    };

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: ERROR,
      payload: "Informacion de clientes no disponible",
    });
  }
};

export const actualizarCuenta = (data) => async (dispatch, getState) => {
  const { user } = getState().cuentaReducer;
  dispatch({
    type: CARGANDO,
  });
  try {
    const header = await authHeader();

    const newObject = {
      email: data.email,
      nombre: data.nombre,
      apellido: data.apellido,
      cedula: data.cedula,
      telefono: data.telefono,
      facebook: data.facebook,
      instagram: data.instagram,
    };

    const rtaPatch = await axios.patch(`${URL_USERS}/${data.id}`, newObject, {
      headers: {
        ...header,
      },
    });
    const rtaData = await rtaPatch.data;

    const updatedUser = {
      ...user,
      user: { ...rtaData },
    };

    localStorage.setItem("user", JSON.stringify(updatedUser));

    dispatch({
      type: ACTUALIZAR,
      payload: updatedUser,
    });
  } catch (error) {
    console.log("Error: ", error);
    dispatch({
      type: ERROR,
      payload: "Informacion de clientes no disponible",
    });
  }
};

export const loginCuenta = (username, password) => async (dispatch) => {
  try {
    const data = await login(username, password);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: data },
    });
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: LOGIN_FAIL,
      payload: message,
    });
  }
};

export const logoutCuenta = () => (dispatch) => {
  logout();

  dispatch({
    type: LOGOUT,
  });
};
