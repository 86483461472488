import React from "react";
import { useHistory } from "react-router";

import "./Button.css";

/*componente de boton que recibe como parametros las propiedades de un boton de html, usamos un estilo general "btn" para poner las propiedades que se repiten en todos los botones y aparte clasificamos el resto de las variables con clases extras*/
export const Button = ({ children, onClick, type, className }) => (
  <button onClick={onClick} type={type} className={`btn ${className}`}>
    {children}
  </button>
);

export const ButtonTab = ({ children, onClick, type, selected }) => (
  <button
    onClick={onClick}
    type={type}
    className={`btn btn__select ${selected ? "btn__select--white" : ""}`}
  >
    {children}
  </button>
);

export const ButtonBack = ({ type }) => {
  let history = useHistory();

  return (
    <button onClick={() => history.goBack()} type={type} className={`btn__back`}>
      <svg className="back-arrow" />
    </button>
  );
};
