import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

import CurrencyInput from "react-currency-input-field";
import Spinner from "../../../components/Spinner/Spiner";
import Fatal from "../../../components/Fatal/Fatal";

import "./ReservarArreglos.css";
import { Button } from "../../../components/Button/Button";

const ReservarArreglos = (props) => {
  const history = useHistory();

  const {
    companyId,
    id,
    agregarCliente,
    agregarUbicacion,
    editarPedido,
    eliminarPedido,
    agregarTipoBocadito,
    eliminarTipoBocadito,
    agregarTipoArreglo,
    eliminarTipoArreglo,
    agregarTipoProducto,
    eliminarTipoProducto,
    agregarProducto,
    eliminarProducto,
    agregarBocadito,
    eliminarBocadito,
    clientes,
    clienteActual,
    pedidos,
    pedido,
    bocaditos,
    arreglos,
    ubicacion,
    cargando,
    error,
  } = props;

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const pedidoActual = pedido ? pedido : "";

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      tema:
        pedidoActual && pedidoActual.tipoPedidoId === 3 && pedidoActual.tema,
      notas:
        pedidoActual && pedidoActual.tipoPedidoId === 3 && pedidoActual.notas,
      hora:
        pedidoActual && pedidoActual.tipoPedidoId === 3 && pedidoActual.hora,
      fecha:
        pedidoActual &&
        pedidoActual.tipoPedidoId === 3 &&
        id !== undefined &&
        pedidoActual.fecha.toISOString().substr(0, 10),
    },
  });
  const [editarCliente, setEditarCliente] = useState(false);
  const [editarUbicacion, setEditarUbicacion] = useState(false);
  const [numeroArreglos, setNumeroArreglos] = useState([
    {
      tipo: "",
      tipoArregloCajasCupcakes: [],
      tipoArregloCajasDulces: [],
      tipoArregloDesayunos: [],
    },
  ]);
  const [numeroBocaditos, setNumeroBocaditos] = useState([
    {
      pedidoId: 1,
      bocadito: "",
      cantidad: "",
      tipoBocaditosParametros: [],
    },
  ]);
  const [numeroTipoProductos, setNumeroTipoProductos] = useState([
    {
      id: "",
      tipoDeArregloId: "",
      tipoDeProducto: "",
      productos: [
        {
          tipoArregloDesayu: 1,
          producto: "",
          cantidad: "",
        },
      ],
    },
  ]);
  const [numeroProducto, setNumeroProducto] = useState([
    {
      tipoArregloDesayu: 1,
      producto: "",
      cantidad: "",
    },
  ]);
  const [numeroClicks, setNumeroClicks] = useState(0);

  useEffect(() => {
    if (
      watch("cliente") !== undefined &&
      Number(watch("cliente")) !== clienteSeleccionado?.id
    ) {
      setClienteSeleccionado(
        clientes.clientes?.filter(
          (cliente) => cliente.id === Number(watch("cliente"))
        )[0]
      );
    }
  }, [clienteActual, watch(), clientes.clientes]);

  /* empieza logica de cliente y ubicacion */

  const ubicacionSeleccionada = clienteSeleccionado?.ubicaciones.filter(
    (ubicacion, index) => ubicacion.id === Number(watch("ubicacion"))
  )[0];

  const indexUbicacionSeleccionada =
    clienteSeleccionado && ubicacionSeleccionada
      ? clienteSeleccionado[0]?.ubicaciones.findIndex(
          (ubicacion) =>
            ubicacion.numeroCasa === ubicacionSeleccionada[0]?.numeroCasa
        )
      : "";

  function traerInfoNuevoCliente() {
    const indexNuevoCliente = clientes.clientes?.length - 1;

    const nuevoClienteInfo = clientes.clientes
      ? clientes.clientes[indexNuevoCliente]
      : "";

    return nuevoClienteInfo;
  }
  function agregarNuevoCliente() {
    agregarCliente();
    setEditarCliente(true);
  }

  function agregarNuevaUbicacion() {
    agregarUbicacion(clienteSeleccionado.id);
    setEditarUbicacion(true);
  }

  /* termina logica de cliente y ubicacion */

  /* empieza logica de Arreglos y sus productos */
  const bocaditoCupcake = bocaditos?.filter(
    (bocadito, indexBocadito) => bocadito.producto === "Cupcakes"
  )[0];

  function traerBocaditoParametroActual(parametro1) {
    const bocaditoCupcake1 = bocaditos?.filter(
      (bocadito, indexBocadito) => bocadito.producto === "Cupcakes"
    )[0];
    const parametroActual = bocaditoCupcake1?.parametros?.filter(
      (parametro) => parametro.parametro === parametro1
    )[0];

    return parametroActual;
  }

  function traerBocaditoParametroActual1(bocadito1, parametro1) {
    const bocaditoAcutual1 = bocaditos?.filter(
      (bocadito, indexBocadito) => bocadito.producto === bocadito1
    )[0];
    const parametroActual = bocaditoAcutual1?.parametros?.filter(
      (parametro) => parametro.parametro === parametro1
    )[0];

    return parametroActual;
  }

  function traerTipoArregloActual(idTipoArreglo) {
    const arregloActual = pedidoActual.tipoDeArreglos?.filter(
      (tipoArreglo) => tipoArreglo.id === idTipoArreglo
    )[0];
    return arregloActual;
  }

  function ponerTipoArreglo() {
    if (cargando) {
      return <Spinner />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return pedidoActual && pedidoActual.tipoPedidoId === 3
      ? pedidoActual.tipoDeArreglos?.map((tipoArreglo, indexPedido) => {
          const idTipoArreglo = tipoArreglo?.id || `array${indexPedido}`;

          return (
            <div key={idTipoArreglo}>
              <label
                htmlFor={`tipoArreglo${idTipoArreglo}`}
                className="titulo-reservas"
              >
                Tipo de Arreglo
              </label>
              <div className="center-reservas">
                <select
                  {...register(`tipoArreglo${idTipoArreglo}`)}
                  name={`tipoArreglo${idTipoArreglo}`}
                  id={`tipoArreglo${idTipoArreglo}`}
                  className="select input--xxlarge"
                >
                  {tipoArreglo.tipo ? (
                    <option value={tipoArreglo.tipo}>{tipoArreglo.tipo}</option>
                  ) : (
                    <option value="">Arreglos...</option>
                  )}
                  {arreglos &&
                    arreglos.tipoDeArreglo?.map((arreglo, indexTipoArreglo) => {
                      return (
                        <option key={indexTipoArreglo} value={arreglo}>
                          {arreglo}
                        </option>
                      );
                    })}
                </select>
                <svg
                  onClick={() => eliminarArreglo(idTipoArreglo)}
                  className="delete"
                />
              </div>

              {getValues(`tipoArreglo${idTipoArreglo}`)
                ? ponerParametrosArreglos(
                    getValues(`tipoArreglo${idTipoArreglo}`),
                    idTipoArreglo
                  )
                : ""}

              <hr className="reservas-arreglos__line"></hr>
            </div>
          );
        })
      : !pedidoActual &&
          numeroArreglos.map((elemento, indexArreglo) => {
            const idTipoArreglo = `array${indexArreglo}`;
            return (
              <div key={idTipoArreglo}>
                <label
                  htmlFor={`tipoArreglo${idTipoArreglo}`}
                  className="titulo-reservas"
                >
                  Tipo de Arreglo
                </label>
                <div className="center-reservas">
                  <select
                    {...register(`tipoArreglo${idTipoArreglo}`)}
                    name={`tipoArreglo${idTipoArreglo}`}
                    id={`tipoArreglo${idTipoArreglo}`}
                    className="select input--xxlarge"
                  >
                    <option value="">Arreglos...</option>
                    {arreglos
                      ? arreglos.tipoDeArreglo?.map(
                          (arreglo, indexTipoArreglo) => (
                            <option key={indexTipoArreglo} value={arreglo}>
                              {arreglo}
                            </option>
                          )
                        )
                      : ""}
                  </select>
                  <svg
                    onClick={() => eliminarArreglo(idTipoArreglo)}
                    className="delete"
                  />
                </div>

                {getValues(`tipoArreglo${idTipoArreglo}`)
                  ? ponerParametrosArreglos(
                      getValues(`tipoArreglo${idTipoArreglo}`),
                      idTipoArreglo
                    )
                  : ""}

                <hr className="reservas-arreglos__line"></hr>
              </div>
            );
          });
  }

  function ponerParametrosArreglos(arreglo, idTipoArreglo) {
    const parametros = {
      "Caja cupcakes": () =>
        !traerTipoArregloActual(idTipoArreglo) ||
        traerTipoArregloActual(idTipoArreglo).tipo !== "Caja cupcakes" ? (
          <div className="reservar-bocaditos__container-cupcakes">
            <div>
              <label
                htmlFor={`cantCupcakes${idTipoArreglo}`}
                className="titulo-reservas"
              >
                Cant
              </label>
              <input
                {...register(`cantCupcakes${idTipoArreglo}`, {
                  pattern: {
                    value: /^\d+$/,
                    message: "This input is number only.",
                  },
                })}
                name={`cantCupcakes${idTipoArreglo}`}
                id={`cantCupcakes${idTipoArreglo}`}
                placeholder="1"
                className="input input--xxsmall"
              />
            </div>
            {bocaditoCupcake?.parametros.map((parametro, indexParametro) => (
              <div key={indexParametro}>
                <label
                  htmlFor={`${parametro.parametro}Cupcakes${idTipoArreglo}`}
                  className="titulo-reservas"
                >
                  {parametro.parametro}
                </label>
                <div className="center-reservas">
                  <select
                    {...register(
                      `${parametro.parametro}Cupcakes${idTipoArreglo}`
                    )}
                    name={`${parametro.parametro}Cupcakes${idTipoArreglo}`}
                    id={`${parametro.parametro}Cupcakes${idTipoArreglo}`}
                    className="select input--medium"
                  >
                    <option value="">parametro...</option>

                    {parametro.data.map((dato, indexDato) => (
                      <option key={indexDato} value={dato.valor}>
                        {dato.valor}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        ) : (
          traerTipoArregloActual(idTipoArreglo)?.tipo === "Caja cupcakes" && (
            <div className="reservar-bocaditos__container-cupcakes">
              <div>
                <label
                  htmlFor={`cantCupcakes${idTipoArreglo}`}
                  className="titulo-reservas"
                >
                  Cant
                </label>
                <input
                  {...register(`cantCupcakes${idTipoArreglo}`)}
                  name={`cantCupcakes${idTipoArreglo}`}
                  id={`cantCupcakes${idTipoArreglo}`}
                  defaultValue={
                    traerTipoArregloActual(idTipoArreglo)
                      .tipoArregloCajasCupcakes[0]?.cantidad
                  }
                  placeholder="1"
                  className="input input--xxsmall"
                />
              </div>
              {traerTipoArregloActual(
                idTipoArreglo
              ).tipoArregloCajasCupcakes[0]?.parametros?.map(
                (parametro, indexParametro) => (
                  <div key={indexParametro}>
                    <label
                      htmlFor={`${parametro.parametro}Cupcakes${idTipoArreglo}`}
                      className="titulo-reservas"
                    >
                      {parametro.parametro}
                    </label>
                    <div className="center-reservas">
                      <select
                        {...register(
                          `${parametro.parametro}Cupcakes${idTipoArreglo}`
                        )}
                        name={`${parametro.parametro}Cupcakes${idTipoArreglo}`}
                        id={`${parametro.parametro}Cupcakes${idTipoArreglo}`}
                        className="select input--medium"
                      >
                        {pedidoActual ? (
                          <option value={parametro.valor}>
                            {parametro.valor}
                          </option>
                        ) : (
                          <option value="">parametro...</option>
                        )}

                        {traerBocaditoParametroActual(
                          parametro.parametro
                        )?.data?.map((dato, indexDato) => (
                          <option key={indexDato} value={dato.valor}>
                            {dato.valor}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )
              )}
            </div>
          )
        ),
      "Caja de dulces": () => (
        <div>
          {ponerParametrosBocaditos(idTipoArreglo)}
          <div className="reservar-bocaditos__btn">
            <button
              type="button"
              onClick={() => agregarBocaditos(idTipoArreglo)}
              className="btn btn--small"
            >
              Adicional
            </button>
          </div>
        </div>
      ),
      Desayuno: () => ponerTipoProductos(idTipoArreglo),
    };

    return parametros[arreglo]();
  }

  function agregarArreglo() {
    if (pedidoActual && pedidoActual.tipoPedidoId === 3) {
      agregarTipoArreglo(pedidoActual.id, asignar(getValues()));
      setNumeroClicks(numeroClicks + 1);
    } else {
      setNumeroArreglos((prev) => [
        ...prev,
        {
          id: "",
          tipo: "",
          tipoArregloCajasCupcakes: [],
          tipoArregloCajasDulces: [],
          tipoArregloDesayunos: [],
        },
      ]);
    }
  }

  function ponerTipoProductos(idTipoArreglo) {
    return (
      <div>
        {!traerTipoArregloActual(idTipoArreglo) ||
        traerTipoArregloActual(idTipoArreglo).tipo !== "Desayuno"
          ? numeroTipoProductos.map((numTipoProducto, indexNumTipoProducto) => {
              const idTipoProducto = `array${indexNumTipoProducto}`;
              return (
                <div key={idTipoProducto}>
                  <div className="reservar-bocaditos__container-bocaditos">
                    <div>
                      <label
                        htmlFor={`producto${idTipoProducto}arreglo${idTipoArreglo}`}
                        className="titulo-reservas"
                      >
                        Producto
                      </label>
                      <select
                        {...register(
                          `producto${idTipoProducto}arreglo${idTipoArreglo}`
                        )}
                        name={`producto${idTipoProducto}arreglo${idTipoArreglo}`}
                        id={`producto${idTipoProducto}arreglo${idTipoArreglo}`}
                        className="select input--xlarge"
                      >
                        <option value="">Producto...</option>
                        {arreglos.productos?.map((producto, indexProducto) => (
                          <option key={indexProducto} value={producto.tipo}>
                            {producto.tipo}
                          </option>
                        ))}
                      </select>
                      <svg
                        onClick={() => eliminarTipoProductos(idTipoProducto)}
                        className="delete"
                      />
                    </div>
                  </div>
                  {getValues(
                    `producto${idTipoProducto}arreglo${idTipoArreglo}`
                  ) !== undefined &&
                    numTipoProducto.productos.map((producto, indexProducto) => {
                      const idProducto = `array${indexProducto}`;
                      return ponerParametroDesayunos(
                        idTipoArreglo,
                        idProducto,
                        producto,
                        idTipoProducto
                      );
                    })}
                  <div className="reservar-bocaditos__btn">
                    <button
                      type="button"
                      onClick={() =>
                        agregarNuevoProducto(idTipoArreglo, idTipoProducto)
                      }
                      className="btn btn--small"
                    >
                      Producto Adicional
                    </button>
                  </div>
                </div>
              );
            })
          : traerTipoArregloActual(idTipoArreglo).tipo === "Desayuno" &&
            traerTipoArregloActual(idTipoArreglo).tipoArregloDesayunos?.map(
              (tipoProducto, indexTipoProducto) => {
                const idTipoProducto =
                  tipoProducto?.id || `array${indexTipoProducto}`;
                return (
                  <div key={idTipoProducto}>
                    <div className="reservar-bocaditos__container-bocaditos">
                      <div>
                        <label
                          htmlFor={`producto${idTipoProducto}arreglo${idTipoArreglo}`}
                          className="titulo-reservas"
                        >
                          Producto
                        </label>
                        <select
                          {...register(
                            `producto${idTipoProducto}arreglo${idTipoArreglo}`
                          )}
                          className="select input--xlarge"
                        >
                          {tipoProducto?.tipoDeProducto ? (
                            <option value={tipoProducto?.tipoDeProducto}>
                              {tipoProducto?.tipoDeProducto}
                            </option>
                          ) : (
                            <option value="">Producto...</option>
                          )}
                          {arreglos.productos?.map(
                            (producto, indexProducto) => (
                              <option key={indexProducto} value={producto.tipo}>
                                {producto.tipo}
                              </option>
                            )
                          )}
                        </select>
                        <svg
                          onClick={() =>
                            eliminarTipoProductos(idTipoProducto, idTipoArreglo)
                          }
                          className="delete"
                        />
                      </div>
                    </div>
                    {getValues(
                      `producto${idTipoProducto}arreglo${idTipoArreglo}`
                    ) !== undefined &&
                      tipoProducto.productos.map((producto, indexProducto) => {
                        const idProducto =
                          producto?.id || `array${indexProducto}`;
                        return ponerParametroDesayunos(
                          idTipoArreglo,
                          idProducto,
                          producto,
                          idTipoProducto
                        );
                      })}
                    <div className="reservar-bocaditos__btn">
                      <button
                        type="button"
                        onClick={() =>
                          agregarNuevoProducto(idTipoArreglo, idTipoProducto)
                        }
                        className="btn btn--small"
                      >
                        Producto Adicional
                      </button>
                    </div>
                  </div>
                );
              }
            )}
        <div className="reservar-bocaditos__btn">
          <button
            type="button"
            onClick={() => agregarNuevoTipoProducto(idTipoArreglo)}
            className="btn btn--medium"
          >
            Tipo Producto Adicional
          </button>
        </div>
      </div>
    );
  }

  function agregarNuevoTipoProducto(idTipoArreglo) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Desayuno") {
      agregarTipoProducto(pedidoActual.id, idTipoArreglo, asignar(getValues()));
      setNumeroClicks(numeroClicks + 1);
    } else {
      setNumeroTipoProductos((prev) => [
        ...prev,
        {
          id: "",
          tipoDeArregloId: "",
          tipoDeProducto: "",
          productos: [
            {
              tipoArregloDesayu: 1,
              producto: "",
              cantidad: "",
            },
          ],
        },
      ]);
    }
  }

  function productoActual(idTipoProducto, idTipoArreglo) {
    const productoActual = arreglos.productos?.filter(
      (producto) =>
        producto.tipo ===
        getValues(`producto${idTipoProducto}arreglo${idTipoArreglo}`)
    )[0];

    return productoActual;
  }

  function ponerParametroDesayunos(
    idTipoArreglo,
    idProducto,
    producto,
    idTipoProducto
  ) {
    const productoActual1 = productoActual(idTipoProducto, idTipoArreglo);
    const produtosActual = productoActual1?.arregloProductos?.map(
      (producto) => producto.producto
    );
    return !producto || !produtosActual?.includes(producto?.producto) ? (
      <div>
        <div className="reservar-arreglos__container-bocaditos">
          <div>
            <label
              htmlFor={`${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
              className="titulo-reservas"
            >
              {`${productoActual1?.tipo}`}
            </label>
            <select
              {...register(
                `${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
              )}
              className="select input--xlarge"
            >
              <option value="">Producto...</option>
              {productoActual1?.arregloProductos.map(
                (arregloProducto, indexDato) => (
                  <option
                    key={arregloProducto.id}
                    value={arregloProducto.producto}
                  >
                    {arregloProducto.producto}
                  </option>
                )
              )}
            </select>
            <svg
              onClick={() =>
                eliminarProductos(idTipoProducto, idTipoArreglo, idProducto)
              }
              className="delete"
            />
          </div>

          <div>
            <label
              htmlFor={`cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
              className="titulo-reservas"
            >
              Cant
            </label>
            <div className="center-reservas">
              <input
                {...register(
                  `cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                )}
                name={`cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
                id={`cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
                placeholder="1"
                className="input input--xxsmall"
              />
            </div>
          </div>
        </div>
      </div>
    ) : (
      producto && produtosActual.includes(producto.producto) && (
        <div>
          <div className="reservar-arreglos__container-bocaditos">
            <div>
              <label
                htmlFor={`${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
                className="titulo-reservas"
              >
                {`${productoActual1?.tipo}`}
              </label>
              <select
                {...register(
                  `${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                )}
                className="select input--xlarge"
              >
                {producto ? (
                  <option value={producto.producto}>{producto.producto}</option>
                ) : (
                  <option value="">Producto...</option>
                )}
                {productoActual1?.arregloProductos.map(
                  (arregloProducto, indexDato) => (
                    <option
                      key={arregloProducto.id}
                      value={arregloProducto.producto}
                    >
                      {arregloProducto.producto}
                    </option>
                  )
                )}
              </select>
              <svg
                onClick={() =>
                  eliminarProductos(idTipoProducto, idTipoArreglo, idProducto)
                }
                className="delete"
              />
            </div>

            <div>
              <label
                htmlFor={`cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
                className="titulo-reservas"
              >
                Cant
              </label>
              <div className="center-reservas">
                <input
                  {...register(
                    `cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                  )}
                  name={`cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
                  id={`cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`}
                  defaultValue={producto?.cantidad}
                  placeholder="1"
                  className="input input--xxsmall"
                />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  function agregarNuevoProducto(idTipoArreglo, idTipoProducto) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Desayuno") {
      agregarProducto(
        pedidoActual.id,
        idTipoArreglo,
        idTipoProducto,
        asignar(getValues())
      );
      setNumeroClicks(numeroClicks + 1);
    } else {
      const indexTipoProducto = idTipoProducto.replace("array", "");
      //1.-creamos una copia de todos los niveles de numeroTipoProductos
      //2.-agregamos un producto mas a la lista
      //3.- se la pasamos a setNumeroTipoProductos
      const newArray = numeroTipoProductos;
      newArray[indexTipoProducto] = {
        ...numeroTipoProductos[indexTipoProducto],
      };
      newArray[indexTipoProducto].productos = [
        ...numeroTipoProductos[indexTipoProducto].productos,
        {
          tipoArregloDesayu: 1,
          producto: "",
          cantidad: "",
        },
      ];

      setNumeroTipoProductos([...newArray]);
    }
  }

  /* termina logica de Arreglos y sus productos */

  /* empieza logica de Bocaditos y parametros */

  function traerBocaditoActual(idPedidoBocadito, idTipoArreglo) {
    const bocaditoEncontrado = bocaditos?.filter(
      (bocadito) =>
        bocadito.producto ===
        getValues(`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`)
    );

    return bocaditoEncontrado[0];
  }

  function bocaditoActual(indexElemento, indexArreglo) {
    let bocaditoActual = [];

    bocaditoActual = bocaditos?.filter(
      (bocadito) =>
        bocadito.producto ===
        getValues(`bocadito${indexElemento}arreglo${indexArreglo}`)
    );
    return bocaditoActual;
  }

  function ponerParametrosBocaditos(idTipoArreglo) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo !== "Caja de dulces") {
      return numeroBocaditos.map((elemento, indexBocadito) => {
        const idPedidoBocadito = `array${indexBocadito}`;
        return (
          <div key={idPedidoBocadito}>
            <div className="reservar-bocaditos__container-bocaditos">
              <div>
                <label
                  htmlFor={`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                  className="titulo-reservas"
                >
                  Bocadito
                </label>
                <select
                  {...register(
                    `bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                  )}
                  name={`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                  id={`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                  className="select input--medium"
                >
                  <option value="">Bocaditos...</option>
                  {bocaditos.map((bocadito, indexBocadito) => (
                    <option key={indexBocadito}>{bocadito.producto}</option>
                  ))}
                </select>
                <svg
                  onClick={() => eliminarBocaditos(idPedidoBocadito)}
                  className="delete"
                />
              </div>

              <div>
                <label
                  htmlFor={`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                  className="titulo-reservas"
                >
                  Cant
                </label>
                <input
                  {...register(
                    `cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                  )}
                  name={`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                  id={`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                  placeholder="1"
                  className="input input--xxsmall"
                />
              </div>
              {bocaditoActual(
                idPedidoBocadito,
                idTipoArreglo
              )[0]?.parametros.map((parametro, indexParametro) => (
                <div key={indexParametro}>
                  <label
                    htmlFor={`bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`}
                    className="titulo-reservas"
                  >
                    {parametro.parametro}
                  </label>
                  <div className="center-reservas">
                    <select
                      {...register(
                        `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                      )}
                      name={`bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`}
                      id={`bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`}
                      className="select input--medium"
                    >
                      <option value="">parametro...</option>
                      {parametro.data.map((dato, indexDato) => (
                        <option key={indexDato} value={dato.valor}>
                          {dato.valor}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      });
    } else if (
      traerTipoArregloActual(idTipoArreglo).tipo === "Caja de dulces"
    ) {
      return traerTipoArregloActual(idTipoArreglo).tipoArregloCajasDulces.map(
        (pedidoBocadito, indexPedidoBocadito) => {
          const idPedidoBocadito =
            pedidoBocadito?.id || `array${indexPedidoBocadito}`;
          const bocaditoActual = traerBocaditoActual(
            idPedidoBocadito,
            idTipoArreglo
          );
          return (
            <div key={idPedidoBocadito}>
              {
                <div className="reservar-bocaditos__container-bocaditos">
                  <div>
                    <label
                      htmlFor={`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                      className="titulo-reservas"
                    >
                      Bocadito
                    </label>
                    <select
                      {...register(
                        `bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                      )}
                      name={`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                      id={`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                      className="select input--medium"
                    >
                      {pedidoBocadito.bocadito ? (
                        <option value={pedidoBocadito.bocadito}>
                          {pedidoBocadito?.bocadito}
                        </option>
                      ) : (
                        !pedidoBocadito.bocadito && (
                          <option value="">Bocaditos...</option>
                        )
                      )}
                      {bocaditos.map((bocadito, indexBocadito) => (
                        <option key={indexBocadito}>{bocadito.producto}</option>
                      ))}
                    </select>
                    <svg
                      onClick={() =>
                        eliminarBocaditos(idPedidoBocadito, idTipoArreglo)
                      }
                      className="delete"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor={`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`}
                      className="titulo-reservas"
                    >
                      Cant
                    </label>
                    <input
                      {...register(
                        `cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                      )}
                      defaultValue={pedidoBocadito.cantidad}
                      placeholder="1"
                      className="input input--xxsmall"
                    />
                  </div>
                  {/* primero validamos que el valor del bocadito no es undefined, y de hay si el valor es igual al que nos vino con el pedido*/}
                  {getValues(
                    `bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                  ) !== undefined &&
                  getValues(
                    `bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                  ) === pedidoBocadito?.bocadito
                    ? pedidoBocadito.parametros?.map(
                        (parametro, indexParametro) => (
                          <div key={indexParametro}>
                            <label
                              htmlFor={`bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`}
                              className="titulo-reservas"
                            >
                              {parametro.parametro
                                ? parametro.parametro
                                : "undefined"}
                            </label>
                            <div className="center-reservas">
                              <select
                                {...register(
                                  `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                                )}
                                className="select input--medium"
                              >
                                {pedidoBocadito.parametros &&
                                pedidoBocadito.parametros[indexParametro] ? (
                                  <option value={parametro.valor}>
                                    {parametro.valor}
                                  </option>
                                ) : (
                                  <option value="">parametro...</option>
                                )}
                                {traerBocaditoParametroActual1(
                                  pedidoBocadito.bocadito,
                                  parametro.parametro
                                )?.data.map((dato, indexDato) => (
                                  <option key={indexDato} value={dato.valor}>
                                    {dato.valor}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )
                      )
                    : getValues(
                        `bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                      ) !== undefined &&
                      getValues(
                        `bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
                      ) !== pedidoBocadito?.bocadito &&
                      bocaditoActual?.parametros.map(
                        (parametro, indexParametro) => (
                          <div key={indexParametro}>
                            <label
                              htmlFor={`bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`}
                              className="titulo-reservas"
                            >
                              {parametro.parametro
                                ? parametro.parametro
                                : "undefined"}
                            </label>
                            <div className="center-reservas">
                              <select
                                {...register(
                                  `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                                )}
                                className="select input--medium"
                              >
                                <option value="">parametro...</option>
                                {traerBocaditoParametroActual1(
                                  bocaditoActual.producto,
                                  parametro.parametro
                                ).data.map((dato, indexDato) => (
                                  <option key={indexDato} value={dato.valor}>
                                    {dato.valor}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )
                      )}
                </div>
              }
            </div>
          );
        }
      );
    }
  }

  function agregarBocaditos(idTipoArreglo) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Caja de dulces") {
      setNumeroClicks(numeroClicks + 1);
      agregarBocadito(pedidoActual.id, idTipoArreglo, asignar(getValues()));
    } else {
      setNumeroBocaditos((prev) => [
        ...prev,
        {
          pedidoId: "",
          bocadito: "",
          cantidad: "",
          tipoBocaditosParametros: [],
        },
      ]);
    }
  }

  function eliminarBocaditos(idPedidoBocadito, idTipoArreglo) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Caja de dulces") {
      eliminarBocadito(
        pedidoActual.id,
        idTipoArreglo,
        idPedidoBocadito,
        asignar(getValues())
      );
      setNumeroClicks(numeroClicks + 1);
    } else {
      const indexPedidoBocadito = idPedidoBocadito.replace("array", "");
      const newArray = [
        ...numeroBocaditos.slice(0, indexPedidoBocadito),
        ...numeroBocaditos.slice(indexPedidoBocadito + 1),
      ];
      setNumeroBocaditos(newArray);
    }
  }

  function eliminarArreglo(idTipoArreglo) {
    if (pedidoActual && pedidoActual.tipoPedidoId === 3) {
      eliminarTipoArreglo(pedidoActual.id, idTipoArreglo, asignar(getValues()));
      setNumeroClicks(numeroClicks + 1);
    } else {
      const arreglos = [...numeroArreglos];
      const indexArreglo = idTipoArreglo.replace("array", "");
      const newArray = arreglos
        .slice(0, indexArreglo)
        .concat(arreglos.slice(indexArreglo + 1));

      setNumeroArreglos([...newArray]);
    }
  }

  function eliminarTipoProductos(idTipoProducto, idTipoArreglo) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Desayuno") {
      eliminarTipoProducto(
        pedido.id,
        idTipoArreglo,
        idTipoProducto,
        asignar(getValues())
      );
      setNumeroClicks(numeroClicks + 1);
    } else {
      const indexTipoProducto = idTipoProducto.replace("array", "");

      const numeroTipoProductos1 = [...numeroTipoProductos];
      const newArray = numeroTipoProductos1
        .slice(0, indexTipoProducto)
        .concat(numeroTipoProductos1.slice(indexTipoProducto + 1));

      setNumeroTipoProductos([...newArray]);
    }
  }

  function eliminarProductos(idTipoProducto, idTipoArreglo, idProducto) {
    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Desayuno") {
      eliminarProducto(
        pedido.id,
        idTipoArreglo,
        idTipoProducto,
        idProducto,
        asignar(getValues())
      );
      setNumeroClicks(numeroClicks + 1);
    } else {
      const indexTipoProducto = idTipoProducto.replace("array", "");
      const indexProducto = idProducto.replace("array", "");
      const numeroTipoProductos1 = [...numeroTipoProductos];
      numeroTipoProductos1[indexTipoProducto] = {
        ...numeroTipoProductos[indexTipoProducto],
      };

      numeroTipoProductos1[indexTipoProducto].productos = [
        ...numeroTipoProductos[indexTipoProducto].productos
          .slice(0, indexProducto)
          .concat(
            numeroTipoProductos1[indexTipoProducto].productos.slice(
              indexProducto + 1
            )
          ),
      ];

      setNumeroTipoProductos([...numeroTipoProductos1]);
    }
  }

  /* termina logica de Bocaditos y parametros */

  /* comienza logica de precio */
  function productosSeleccionados() {
    let array = [];
    if (pedidoActual && pedidoActual.tipoPedidoId === 3) {
      pedidoActual.arreglos.forEach((pedidoArreglos, indexArreglo) => {
        if (getValues(`tipoArreglo${indexArreglo}`) === "Desayuno") {
          pedidoActual.arreglos[indexArreglo].productos?.forEach(
            (numProducto, indexNumProducto) => {
              const tipoProducto = getValues(
                `producto${indexNumProducto}arreglo${indexArreglo}`
              );
              const producto1 = getValues(
                `${tipoProducto}${indexNumProducto}arreglo${indexArreglo}`
              );
              const seleccionado = productoActual(
                indexNumProducto,
                indexArreglo
              )
                ? productoActual(
                    indexNumProducto,
                    indexArreglo
                  )[0]?.data.filter(
                    (producto, indexProducto) => producto.producto == producto1
                  )
                : "";

              array.push(seleccionado ? seleccionado[0] : "");
            }
          );
        }
      });
    } else {
      numeroArreglos.map((elemento, indexArreglo) => {
        if (getValues(`tipoArreglo${indexArreglo}`) === "Desayuno") {
          numeroProducto.map((numProducto, indexNumProducto) => {
            const tipoProducto = getValues(
              `producto${indexNumProducto}arreglo${indexArreglo}`
            );
            const producto1 = getValues(
              `${tipoProducto}${indexNumProducto}arreglo${indexArreglo}`
            );
            const seleccionado = productoActual(indexNumProducto, indexArreglo)
              ? productoActual(indexNumProducto, indexArreglo)[0]?.data.filter(
                  (producto, indexProducto) => producto.producto == producto1
                )
              : "";

            array.push(seleccionado ? seleccionado[0] : "");
          });
        }
      });
    }

    return array;
  }

  function obtenerPrecioBocaditos() {
    let precio = 0;
    if (pedidoActual && pedidoActual.tipoPedidoId === 3) {
      pedidoActual.tipoDeArreglos.forEach((tipoArreglo, indexPedido) => {
        const idTipoArreglo = tipoArreglo?.id || `array${indexPedido}`;

        if (tipoArreglo.tipo === "Caja de dulces") {
          tipoArreglo.tipoArregloCajasDulces?.forEach(
            (pedidoBocadito, indexPedidoBocadito) => {
              const idPedidoBocadito =
                pedidoBocadito?.id || `array${indexPedidoBocadito}`;

              const precioBocadito = traerBocaditoActual(
                idPedidoBocadito,
                idTipoArreglo
              )?.precio;
              const cant = getValues(
                `cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
              );
              precio = precio + precioBocadito * cant;
            }
          );
        } else if (tipoArreglo.tipo === "") {
          numeroBocaditos?.forEach((pedidoBocadito, indexPedidoBocadito) => {
            const idPedidoBocadito =
              pedidoBocadito?.id || `array${indexPedidoBocadito}`;

            const precioBocadito = traerBocaditoActual(
              idPedidoBocadito,
              idTipoArreglo
            )?.precio;
            const cant = getValues(
              `cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
            );
            precio = precio + precioBocadito * cant;
          });
        }
      });
    } else {
      numeroArreglos.forEach((arreglo, indexArreglo) => {
        const idTipoArreglo = `array${indexArreglo}`;

        const tipoDeArreglo = getValues(`tipoArreglo${idTipoArreglo}`);

        if (tipoDeArreglo === "Caja de dulces") {
          numeroBocaditos?.forEach((pedidoBocadito, indexPedidoBocadito) => {
            const idPedidoBocadito =
              pedidoBocadito?.id || `array${indexPedidoBocadito}`;

            const precioBocadito = traerBocaditoActual(
              idPedidoBocadito,
              idTipoArreglo
            )?.precio;
            const cant = getValues(
              `cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`
            );
            precio = precio + precioBocadito * cant;
          });
        }
      });
    }
    return precio;
  }

  function obtenerPrecioCajaCupcakes() {
    let precio = 0;

    if (id !== undefined) {
      pedidoActual.tipoDeArreglos.forEach((tipoArreglo, indexPedido) => {
        const idTipoArreglo = tipoArreglo?.id || `array${indexPedido}`;
        const tipoDeArreglo = getValues(`tipoArreglo${idTipoArreglo}`);

        if (tipoDeArreglo === "Caja cupcakes") {
          const precioCupcakes = bocaditoCupcake?.precio;
          const cant = getValues(`cantCupcakes${idTipoArreglo}`);
          precio = precioCupcakes * cant;
        }
      });
    } else {
      numeroArreglos.forEach((arreglo, indexArreglo) => {
        const idTipoArreglo = `array${indexArreglo}`;

        const tipoArreglo = getValues(`tipoArreglo${idTipoArreglo}`);

        if (tipoArreglo === "Caja cupcakes") {
          const precioCupcakes = bocaditoCupcake.precio;
          const cant = getValues(`cantCupcakes${idTipoArreglo}`);

          precio = precioCupcakes * cant;
        }
      });
    }
    return precio;
  }

  function obtenerPrecioDesayuno() {
    let precio = 0;
    if (pedidoActual && pedidoActual.tipoPedidoId === 3) {
      pedidoActual.tipoDeArreglos.forEach((tipoArreglo, indexPedido) => {
        const idTipoArreglo = tipoArreglo?.id || `array${indexPedido}`;
        const tipoDeArreglo = getValues(`tipoArreglo${idTipoArreglo}`);
        if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Desayuno") {
          tipoArreglo.tipoArregloDesayunos?.forEach(
            (tipoProducto, indexTipoProducto) => {
              const idTipoProducto =
                tipoProducto?.id || `array${indexTipoProducto}`;

              tipoProducto.productos.map((producto, indexProducto) => {
                const idProducto = producto?.id || `array${indexProducto}`;

                const tipoProducto1 = getValues(
                  `producto${idTipoProducto}arreglo${idTipoArreglo}`
                );

                const productoActual1 = productoActual(
                  idTipoProducto,
                  idTipoArreglo
                );
                const producto1 = getValues(
                  `${tipoProducto1}${idProducto}arreglo${idTipoArreglo}`
                );

                const cant = getValues(
                  `cant${tipoProducto1}${idProducto}arreglo${idTipoArreglo}`
                );
                //Arreglar esto no aparece precio
                const precioProducto = productoActual1?.arregloProductos?.find(
                  (producto) => producto.producto === producto1
                )?.precio;

                if (precioProducto > 0) {
                  precio = precio + cant * precioProducto;
                }
              });
            }
          );
        } else {
          numeroTipoProductos.forEach(
            (numtipoProducto, indexNumTipoProducto) => {
              const idTipoProducto = `array${indexNumTipoProducto}`;

              numtipoProducto.productos.map((producto, indexProducto) => {
                const idProducto = `array${indexProducto}`;

                const tipoProducto1 = getValues(
                  `producto${idTipoProducto}arreglo${idTipoArreglo}`
                );

                const productoActual1 = productoActual(
                  idTipoProducto,
                  idTipoArreglo
                );
                const producto1 = getValues(
                  `${tipoProducto1}${idProducto}arreglo${idTipoArreglo}`
                );

                const cant = getValues(
                  `cant${tipoProducto1}${idProducto}arreglo${idTipoArreglo}`
                );
                //Arreglar esto no aparece precio
                const precioProducto = productoActual1?.arregloProductos?.find(
                  (producto) => producto.producto === producto1
                )?.precio;

                if (precioProducto > 0) {
                  precio = precio + cant * precioProducto;
                }
              });
            }
          );
        }
      });
    } else {
      numeroArreglos.forEach((arreglo, indexArreglo) => {
        const idTipoArreglo = `array${indexArreglo}`;

        const tipoArreglo = getValues(`tipoArreglo${idTipoArreglo}`);
        if (tipoArreglo === "Desayuno") {
          numeroTipoProductos.forEach(
            (numtipoProducto, indexNumTipoProducto) => {
              const idTipoProducto = `array${indexNumTipoProducto}`;

              numtipoProducto.productos.map((producto, indexProducto) => {
                const idProducto = `array${indexProducto}`;

                const tipoProducto1 = getValues(
                  `producto${idTipoProducto}arreglo${idTipoArreglo}`
                );

                const productoActual1 = productoActual(
                  idTipoProducto,
                  idTipoArreglo
                );
                const producto1 = getValues(
                  `${tipoProducto1}${idProducto}arreglo${idTipoArreglo}`
                );

                const cant = getValues(
                  `cant${tipoProducto1}${idProducto}arreglo${idTipoArreglo}`
                );
                //Arreglar esto no aparece precio
                const precioProducto = productoActual1?.arregloProductos?.find(
                  (producto) => producto.producto === producto1
                )?.precio;

                if (precioProducto > 0) {
                  precio = precio + cant * precioProducto;
                }
              });
            }
          );
        }
      });
    }

    return precio;
  }

  function precioFinal() {
    const precioBocadito = obtenerPrecioBocaditos()
      ? obtenerPrecioBocaditos()
      : 0;
    const precioCajaCupcakes = obtenerPrecioCajaCupcakes()
      ? obtenerPrecioCajaCupcakes()
      : 0;
    const precioDesayuno = obtenerPrecioDesayuno()
      ? obtenerPrecioDesayuno()
      : 0;

    const precio = precioBocadito + precioCajaCupcakes + precioDesayuno;

    return precio;
  }

  /* termina logica de precio */
  function obtenerBocaditoActual(
    data,
    idTipoArreglo,
    tipoDeArreglo,
    tipoArreglo
  ) {
    let array = [];

    if (
      tipoDeArreglo === "Caja de dulces" &&
      tipoArreglo &&
      tipoArreglo.tipoArregloCajasDulces.length !== 0
    ) {
      tipoArreglo?.tipoArregloCajasDulces?.forEach(
        (pedidoBocadito, indexPedidoBocadito) => {
          const idPedidoBocadito =
            pedidoBocadito?.id || `array${indexPedidoBocadito}`;

          const bocaditoActual = traerBocaditoActual(
            idPedidoBocadito,
            idTipoArreglo
          );

          array.push({
            bocadito:
              data[`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`],
            cantidad: Number(
              data[`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`]
            ),
            id: pedidoBocadito.id || null,
            tipoDeArregloId: pedidoBocadito.tipoDeArregloId || 1,
            parametros:
              pedidoBocadito.parametros.length !== 0
                ? pedidoBocadito.parametros?.map(
                    (parametro, parametroIndex) => ({
                      id: parametro.id,
                      parametro: parametro.parametro,
                      tipoArregloCajaDulcesId: parametro.tipoArregloCaj,
                      valor:
                        data[
                          `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                        ],
                    })
                  )
                : bocaditoActual.parametros?.map(
                    (parametro, parametroIndex) => ({
                      id: null,
                      parametro: parametro.parametro,
                      tipoArregloCajaDulcesId: 1,
                      valor:
                        data[
                          `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                        ],
                    })
                  ),
          });
        }
      );
    } else if (
      tipoDeArreglo === "Caja de dulces" &&
      tipoArreglo &&
      tipoArreglo.tipoArregloCajasDulces.length === 0
    ) {
      numeroBocaditos.forEach((pedidoBocadito, indexPedidoBocadito) => {
        const idPedidoBocadito = `array${indexPedidoBocadito}`;

        const bocaditoActual = traerBocaditoActual(
          idPedidoBocadito,
          idTipoArreglo
        );

        array.push({
          bocadito: data[`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`],
          cantidad: Number(
            data[`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`]
          ),
          id: pedidoBocadito.id || null,
          tipoDeArregloId: pedidoBocadito.tipoDeArregloId || 1,
          parametros: bocaditoActual?.parametros?.map(
            (parametro, parametroIndex) => ({
              id: null,
              parametro: parametro.parametro,
              tipoArregloCajaDulcesId: 1,
              valor:
                data[
                  `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                ],
            })
          ),
        });
      });
    } else {
      numeroBocaditos.forEach((pedidoBocadito, indexPedidoBocadito) => {
        const idPedidoBocadito = `array${indexPedidoBocadito}`;

        const bocaditoActual = traerBocaditoActual(
          idPedidoBocadito,
          idTipoArreglo
        );

        array.push({
          bocadito: data[`bocadito${idPedidoBocadito}arreglo${idTipoArreglo}`],
          cantidad: Number(
            data[`cantBocadito${idPedidoBocadito}arreglo${idTipoArreglo}`]
          ),
          id: pedidoBocadito.id || null,
          tipoDeArregloId: pedidoBocadito.tipoDeArregloId || 1,
          parametros: bocaditoActual?.parametros?.map(
            (parametro, parametroIndex) => ({
              id: null,
              parametro: parametro.parametro,
              tipoArregloCajaDulcesId: 1,
              valor:
                data[
                  `bocadito${idPedidoBocadito}${parametro.parametro}arreglo${idTipoArreglo}`
                ],
            })
          ),
        });
      });
    }
    return array;
  }

  function obtenerProductoActual(
    data,
    idTipoArreglo,
    tipoDeArreglo,
    tipoArreglo
  ) {
    let array = [];

    if (traerTipoArregloActual(idTipoArreglo)?.tipo === "Desayuno") {
      tipoArreglo.tipoArregloDesayunos?.forEach(
        (tipoProducto, indexTipoProducto) => {
          const idTipoProducto =
            tipoProducto?.id || `array${indexTipoProducto}`;
          array.push({
            id: tipoProducto.id || null,
            tipoDeArregloId: tipoProducto.tipoDeArregloId || 1,
            tipoDeProducto:
              tipoProducto.tipoDeProducto ||
              data[`producto${idTipoProducto}arreglo${idTipoArreglo}`],
            productos: tipoProducto.productos.map((producto, indexProducto) => {
              const idProducto = producto?.id || `array${indexProducto}`;

              const productoActual1 = productoActual(
                idTipoProducto,
                idTipoArreglo
              );
              return {
                cantidad: Number(
                  data[
                    `cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                  ]
                ),
                id: producto.id || null,
                producto:
                  data[
                    `${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                  ],
                tipoArregloDesayunoId: producto.tipoArregloDesayu || 1,
              };
            }),
          });
        }
      );
    } else {
      numeroTipoProductos.map((numTipoProducto, indexNumTipoProducto) => {
        const idTipoProducto = `array${indexNumTipoProducto}`;

        array.push({
          id: null,
          tipoDeArregloId: 1,
          tipoDeProducto:
            data[`producto${idTipoProducto}arreglo${idTipoArreglo}`],
          productos: numTipoProducto.productos.map(
            (producto, indexProducto) => {
              const idProducto = `array${indexProducto}`;

              const productoActual1 = productoActual(
                idTipoProducto,
                idTipoArreglo
              );
              return {
                cantidad: Number(
                  data[
                    `cant${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                  ]
                ),
                id: null,
                producto:
                  data[
                    `${productoActual1?.tipo}${idProducto}arreglo${idTipoArreglo}`
                  ],
                tipoArregloDesayunoId: 1,
              };
            }
          ),
        });
      });
    }
    return array;
  }

  function obtenerArregloActual(data) {
    let array = [];
    let tipoDeArreglo = "";

    if (pedidoActual && pedidoActual.tipoPedidoId === 3) {
      pedidoActual.tipoDeArreglos.forEach((tipoArreglo, indexPedido) => {
        const idTipoArreglo = tipoArreglo?.id || `array${indexPedido}`;
        tipoDeArreglo = data[`tipoArreglo${idTipoArreglo}`];

        if (tipoDeArreglo === "Caja cupcakes") {
          const arreglo = {
            id: tipoArreglo.id || null,
            pedidoId: tipoArreglo.pedidoId || 1,
            tipo: tipoArreglo.tipo || tipoDeArreglo,
            tipoArregloCajasDulces: [],
            tipoArregloDesayunos: [],
            tipoArregloCajasCupcakes: [
              {
                id: tipoArreglo.tipoArregloCajasCupcakes[0]?.id || null,
                cantidad: Number(data[`cantCupcakes${idTipoArreglo}`]),
                tipoDeArregloId:
                  tipoArreglo.tipoArregloCajasCupcakes[0]?.tipoDeArregloId || 1,
                parametros:
                  tipoArreglo.tipoArregloCajasCupcakes[0]?.parametros?.map(
                    (parametro, indexParametro) => ({
                      id: parametro.id || null,
                      parametro: parametro.parametro,
                      tipoArregloCajaCupcakesId: parametro.tipoArregloC || 1,
                      valor:
                        data[`${parametro.parametro}Cupcakes${idTipoArreglo}`],
                    })
                  ) ||
                  bocaditoCupcake.parametros.map(
                    (parametro, indexParametro) => ({
                      id: null,
                      parametro: parametro.parametro,
                      tipoArregloCajaCupcakesId: 1,
                      valor:
                        data[`${parametro.parametro}Cupcakes${idTipoArreglo}`],
                    })
                  ),
              },
            ],
          };
          if (arreglo.id === null) {
            delete arreglo.id;
          }

          if (arreglo.tipoArregloCajasCupcakes[0].id === null) {
            delete arreglo.tipoArregloCajasCupcakes[0].id;
          }

          arreglo.tipoArregloCajasCupcakes[0].parametros.forEach(
            (parametro) => {
              if (parametro.id === null) {
                delete parametro.id;
              }
            }
          );
          array.push(arreglo);
        } else if (tipoDeArreglo === "Caja de dulces") {
          const arreglo = {
            id: tipoArreglo.id || null,
            pedidoId: tipoArreglo.pedidoId || 1,
            tipo: tipoArreglo.tipo || tipoDeArreglo,
            tipoArregloCajasCupcakes: [],
            tipoArregloDesayunos: [],
            tipoArregloCajasDulces: obtenerBocaditoActual(
              data,
              idTipoArreglo,
              tipoDeArreglo,
              tipoArreglo
            ),
          };
          if (arreglo.id === null) {
            delete arreglo.id;
          }
          arreglo.tipoArregloCajasDulces.forEach((bocadito) => {
            if (bocadito.id === null) {
              delete bocadito.id;
            }
            bocadito.parametros.forEach((parametro) => {
              if (parametro.id === null) {
                delete parametro.id;
              }
            });
          });
          array.push(arreglo);
        } else if (tipoDeArreglo === "Desayuno") {
          const arreglo = {
            id: tipoArreglo.id || null,
            pedidoId: tipoArreglo.pedidoId || 1,
            tipo: tipoArreglo.tipo || tipoDeArreglo,
            tipoArregloCajasCupcakes: [],
            tipoArregloCajasDulces: [],
            tipoArregloDesayunos: obtenerProductoActual(
              data,
              idTipoArreglo,
              tipoDeArreglo,
              tipoArreglo
            ),
          };

          if (arreglo.id === null) {
            delete arreglo.id;
          }

          arreglo.tipoArregloDesayunos.forEach((tipoProducto) => {
            if (tipoProducto.id == null) {
              delete tipoProducto.id;
            }

            tipoProducto.productos.forEach((producto) => {
              if (producto.id === null) {
                delete producto.id;
              }
            });
          });
          array.push(arreglo);
        }
      });
    } else {
      numeroArreglos.map((arreglo, indexArreglo) => {
        const idTipoArreglo = `array${indexArreglo}`;

        tipoDeArreglo = data[`tipoArreglo${idTipoArreglo}`];

        let parametrosCupcakes = [];
        bocaditoCupcake[0]?.parametros.map((parametro, indexParametro) =>
          parametrosCupcakes.push({
            parametro: parametro.parametro,
            valor: getValues(`${parametro.parametro}Cupcakes${indexArreglo}`),
          })
        );

        if (tipoDeArreglo === "Caja cupcakes") {
          const arreglo = {
            id: null,
            pedidoId: 1,
            tipo: tipoDeArreglo,
            tipoArregloCajasDulces: [],
            tipoArregloDesayunos: [],
            tipoArregloCajasCupcakes: [
              {
                id: null,
                cantidad: Number(data[`cantCupcakes${idTipoArreglo}`]),
                tipoDeArregloId: 1,
                parametros: bocaditoCupcake.parametros.map(
                  (parametro, indexParametro) => ({
                    id: null,
                    parametro: parametro.parametro,
                    tipoArregloCajaCupcakesId: 1,
                    valor:
                      data[`${parametro.parametro}Cupcakes${idTipoArreglo}`],
                  })
                ),
              },
            ],
          };
          if (arreglo.id === null) {
            delete arreglo.id;
          }

          if (arreglo.tipoArregloCajasCupcakes[0].id === null) {
            delete arreglo.tipoArregloCajasCupcakes[0].id;
          }

          arreglo.tipoArregloCajasCupcakes[0].parametros.forEach(
            (parametro) => {
              if (parametro.id === null) {
                delete parametro.id;
              }
            }
          );
          array.push(arreglo);
        } else if (tipoDeArreglo === "Caja de dulces") {
          const arreglo = {
            id: null,
            pedidoId: 1,
            tipo: tipoDeArreglo,
            tipoArregloCajasCupcakes: [],
            tipoArregloDesayunos: [],
            tipoArregloCajasDulces: obtenerBocaditoActual(
              data,
              idTipoArreglo,
              tipoDeArreglo
            ),
          };
          if (arreglo.id === null) {
            delete arreglo.id;
          }
          arreglo.tipoArregloCajasDulces.forEach((bocadito) => {
            if (bocadito.id === null) {
              delete bocadito.id;
            }
            bocadito.parametros.forEach((parametro) => {
              if (parametro.id === null) {
                delete parametro.id;
              }
            });
          });
          array.push(arreglo);
        } else if (tipoDeArreglo === "Desayuno") {
          const arreglo = {
            id: null,
            pedidoId: 1,
            tipo: tipoDeArreglo,
            tipoArregloCajasCupcakes: [],
            tipoArregloCajasDulces: [],
            tipoArregloDesayunos: obtenerProductoActual(
              data,
              idTipoArreglo,
              tipoDeArreglo
            ),
          };

          if (arreglo.id === null) {
            delete arreglo.id;
          }

          arreglo.tipoArregloDesayunos.forEach((tipoProducto) => {
            if (tipoProducto.id == null) {
              delete tipoProducto.id;
            }

            tipoProducto.productos.forEach((producto) => {
              if (producto.id === null) {
                delete producto.id;
              }
            });
          });
          array.push(arreglo);
        }
      });
    }
    array.forEach((arreglo) => {
      if (arreglo.id === null) {
        delete arreglo.id;
      }
    });

    return array;
  }

  function asignar(data) {
    const objeto = {
      companyId: companyId,
      clienteId: data["cliente"],
      ubicacionId: data["ubicacion"],
      fecha: data["fecha"],
      hora: data["hora"],
      id: pedido?.id || null,
      precio: data["miPrecio"]
        ? data["miPrecio"]
        : Number(precioFinal().toFixed(2)),
      tipoDeArreglos: obtenerArregloActual(data),
      tema: data["tema"],
      notas: data["notas"],
      tipoPedidoId: 3,
    };
    if (objeto.id === null) {
      delete objeto.id;
    }

    return objeto;
  }

  return (
    <div className="reservar-arreglos">
      {id !== undefined && pedido && clienteActual && ubicacion ? (
        <form
          onSubmit={handleSubmit((data) => {
            editarPedido(asignar(data), pedidoActual.id);
            history.push("/pedidos");
          })}
        >
          <div>
            <label htmlFor="cliente" className="titulo-reservas">
              Cliente
            </label>
            <div className="center-reservas">
              <select
                {...register("cliente", {
                  required: true,
                  valueAsNumber: true,
                })}
                name="cliente"
                id="cliente"
                className="select input--xxlarge"
              >
                {pedidoActual?.tipoPedidoId === 3 ? (
                  <option value={clienteActual.id}>
                    {`${clienteActual?.nombre} ${clienteActual?.apellido}`}
                  </option>
                ) : (
                  <option value="">clientes...</option>
                )}
                {clientes.clientes?.map((clienteSeleccion, index) => (
                  <option
                    key={index}
                    value={clienteSeleccion.id}
                  >{`${clienteSeleccion.nombre} ${clienteSeleccion.apellido}`}</option>
                ))}
              </select>

              <svg
                onClick={() => agregarNuevoCliente()}
                className="icono-agregar-cliente"
              ></svg>
              {editarCliente ? (
                <Redirect
                  to={{
                    pathname: `/editar-cliente/${traerInfoNuevoCliente().id}`,
                    state: {
                      indexCliente: clientes.clientes?.length - 1,
                    },
                  }}
                />
              ) : (
                ""
              )}
              <Link
                to={{
                  pathname: `/editar-cliente/${clienteSeleccionado?.id}`,
                  state: {
                    indexCliente: undefined,
                  },
                }}
              >
                <svg className="icono-edit"></svg>
              </Link>
            </div>
          </div>

          {ponerTipoArreglo()}

          <div className="reservar-arreglos__btn">
            <button
              onClick={() => agregarArreglo()}
              type="button"
              className="btn btn--medium"
            >
              Arreglo Adicional
            </button>
          </div>

          <div>
            <label htmlFor="tema" className="titulo-reservas">
              Tema
            </label>

            <textarea
              type="text"
              {...register("tema", {
                value: pedido?.tema,
              })}
              id="tema"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="notas" className="titulo-reservas">
              Notas
            </label>
            <textarea
              type="text"
              {...register("notas", {
                value: pedido?.notas,
              })}
              id="notas"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="ubicacion" className="titulo-reservas">
              Ubicacion
            </label>
            <div className="center-reservas">
              <select
                {...register("ubicacion", { valueAsNumber: true })}
                id="ubicacion"
                className="select input--xxlarge"
              >
                {pedido && ubicacion ? (
                  <option value={ubicacion?.id}>
                    {`${ubicacion?.calles} ${ubicacion?.numeroCasa}`}
                  </option>
                ) : (
                  <option value="">Ubicacion...</option>
                )}
                {clienteSeleccionado
                  ? clienteSeleccionado?.ubicaciones.map((ubicacion, index) => (
                      <option key={index} value={ubicacion.id}>
                        {ubicacion.calles} {ubicacion.numeroCasa}
                      </option>
                    ))
                  : ""}
              </select>

              <svg
                onClick={(e) => {
                  agregarNuevaUbicacion();
                }}
                className="icono-agregar"
              />
              {editarUbicacion && (
                <Redirect
                  to={{
                    pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${undefined}`,
                    state: {
                      ubicacionIndex:
                        clienteSeleccionado?.ubicaciones?.length - 1,
                    },
                  }}
                ></Redirect>
              )}

              <Link
                to={{
                  pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${ubicacionSeleccionada?.id}`,
                  state: {
                    ubicacionIndex: ubicacionSeleccionada
                      ? indexUbicacionSeleccionada
                      : "",
                  },
                }}
              >
                <svg className={`icono-edit`} />
              </Link>
            </div>
          </div>

          <div className="reservar-torta__container-tiempo">
            <div>
              <label htmlFor="fecha" className="titulo-reservas">
                Fecha
              </label>
              <input
                type="date"
                {...register("fecha", {
                  valueAsDate: true,
                  value: pedido?.fecha.toISOString().substr(0, 10),
                })}
                id="fecha"
                placeholder="20/12/2021"
                className="input input--fit"
              />
            </div>

            <div>
              <label htmlFor="hora" className="titulo-reservas">
                Hora de entrega
              </label>
              <input
                type="time"
                {...register("hora", { value: pedido?.hora })}
                defaultValue={pedido?.hora}
                id="hora"
                placeholder="00:00"
                className="input input--fit"
              />
            </div>
          </div>

          <div className="reservar-torta__container-precio">
            <div>
              <label htmlFor="precio" className="titulo-reservas">
                Precio
              </label>
              {
                <CurrencyInput
                  {...register("precio", {
                    valueAsNumber: true,
                    value: Number(precioFinal().toFixed(2)),
                  })}
                  id="precio"
                  placeholder="$0.00"
                  value={precioFinal().toFixed(2)}
                  className="input input--small"
                />
              }
            </div>

            <div>
              <label htmlFor="miPrecio" className="titulo-reservas">
                Mi Precio
              </label>
              <CurrencyInput
                {...register("miPrecio", {
                  valueAsNumber: true,
                })}
                id="miPrecio"
                placeholder="$0.00"
                className="input input--small"
              />
            </div>
          </div>

          <div className="reservar-torta__btn">
            <Button className="btn--large">Reservar</Button>
          </div>
          <div className="reservar-torta__btn">
            <Button
              type="button"
              onClick={() => {
                eliminarPedido(pedido.id);
                history.push("/pedidos");
              }}
              className="btn--medium"
            >
              Eliminar pedido
            </Button>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleSubmit((data) => {
            editarPedido(asignar(data), pedidoActual.id);
            history.push("/pedidos");
          })}
        >
          <div>
            <label htmlFor="cliente" className="titulo-reservas">
              Cliente
            </label>
            <div className="center-reservas">
              <select
                {...register("cliente", {
                  required: true,
                  valueAsNumber: true,
                })}
                name="cliente"
                id="cliente"
                className="select input--xxlarge"
              >
                {pedidoActual?.tipoPedidoId === 3 ? (
                  <option value={clienteActual?.id}>
                    {`${clienteActual?.nombre} ${clienteActual?.apellido}`}
                  </option>
                ) : (
                  <option value="">clientes...</option>
                )}
                {clientes.clientes?.map((clienteSeleccion, index) => (
                  <option
                    key={index}
                    value={clienteSeleccion.id}
                  >{`${clienteSeleccion.nombre} ${clienteSeleccion.apellido}`}</option>
                ))}
              </select>

              <svg
                onClick={() => agregarNuevoCliente()}
                className="icono-agregar-cliente"
              ></svg>
              {editarCliente ? (
                <Redirect
                  to={{
                    pathname: `/editar-cliente/${traerInfoNuevoCliente().id}`,
                    state: {
                      indexCliente: clientes.clientes?.length - 1,
                    },
                  }}
                />
              ) : (
                ""
              )}
              <Link
                to={{
                  pathname: `/editar-cliente/${clienteSeleccionado?.id}`,
                  state: {
                    indexCliente: undefined,
                  },
                }}
              >
                <svg className="icono-edit"></svg>
              </Link>
            </div>
          </div>

          {ponerTipoArreglo()}

          <div className="reservar-arreglos__btn">
            <button
              onClick={() => agregarArreglo()}
              type="button"
              className="btn btn--medium"
            >
              Arreglo Adicional
            </button>
          </div>

          <div>
            <label htmlFor="tema" className="titulo-reservas">
              Tema
            </label>

            <textarea
              type="text"
              {...register("tema", {
                value: pedido?.tema,
              })}
              id="tema"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="notas" className="titulo-reservas">
              Notas
            </label>
            <textarea
              type="text"
              {...register("notas", {
                value: pedido?.notas,
              })}
              id="notas"
              className="text-area"
            />
          </div>

          <div>
            <label htmlFor="ubicacion" className="titulo-reservas">
              Ubicacion
            </label>
            <div className="center-reservas">
              <select
                {...register("ubicacion", { valueAsNumber: true })}
                id="ubicacion"
                className="select input--xxlarge"
              >
                {pedido && ubicacion ? (
                  <option value={ubicacion?.id}>
                    {`${ubicacion?.calles} ${ubicacion?.numeroCasa}`}
                  </option>
                ) : (
                  <option value="">Ubicacion...</option>
                )}
                {clienteSeleccionado
                  ? clienteSeleccionado?.ubicaciones.map((ubicacion, index) => (
                      <option key={index} value={ubicacion.id}>
                        {ubicacion.calles} {ubicacion.numeroCasa}
                      </option>
                    ))
                  : ""}
              </select>

              <svg
                onClick={(e) => {
                  agregarNuevaUbicacion();
                }}
                className="icono-agregar"
              />
              {editarUbicacion && (
                <Redirect
                  to={{
                    pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${undefined}`,
                    state: {
                      ubicacionIndex:
                        clienteSeleccionado?.ubicaciones?.length - 1,
                    },
                  }}
                ></Redirect>
              )}

              <Link
                to={{
                  pathname: `/editar-ubicacion/${clienteSeleccionado?.id}/${ubicacionSeleccionada?.id}`,
                  state: {
                    ubicacionIndex: ubicacionSeleccionada
                      ? indexUbicacionSeleccionada
                      : "",
                  },
                }}
              >
                <svg className={`icono-edit`} />
              </Link>
            </div>
          </div>

          <div className="reservar-torta__container-tiempo">
            <div>
              <label htmlFor="fecha" className="titulo-reservas">
                Fecha
              </label>
              <input
                type="date"
                {...register("fecha", {
                  valueAsDate: true,
                  value: pedido?.fecha.toISOString().substr(0, 10),
                })}
                id="fecha"
                placeholder="20/12/2021"
                className="input input--fit"
              />
            </div>

            <div>
              <label htmlFor="hora" className="titulo-reservas">
                Hora de entrega
              </label>
              <input
                type="time"
                {...register("hora", { value: pedido?.hora })}
                defaultValue={pedido?.hora}
                id="hora"
                placeholder="00:00"
                className="input input--fit"
              />
            </div>
          </div>

          <div className="reservar-torta__container-precio">
            <div>
              <label htmlFor="precio" className="titulo-reservas">
                Precio
              </label>
              {
                <CurrencyInput
                  {...register("precio", {
                    valueAsNumber: true,
                    value: Number(precioFinal().toFixed(2)),
                  })}
                  id="precio"
                  placeholder="$0.00"
                  value={precioFinal().toFixed(2)}
                  className="input input--small"
                />
              }
            </div>

            <div>
              <label htmlFor="miPrecio" className="titulo-reservas">
                Mi Precio
              </label>
              <CurrencyInput
                {...register("miPrecio", {
                  valueAsNumber: true,
                })}
                id="miPrecio"
                placeholder="$0.00"
                className="input input--small"
              />
            </div>
          </div>

          <div className="reservar-torta__btn">
            <Button className="btn--large">Reservar</Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ReservarArreglos;
