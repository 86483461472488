import React from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import * as cuentaActions from "../../redux/actions/cuentaActions";

import "./Login.css";
import { Link } from "react-router-dom";

const Login = (props) => {
  const { loginCuenta, isLoggedIn } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  if (!isLoggedIn) {
    console.log("ingresa");
  } else {
    props.history.push("/cuenta");
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (data) => {
          await loginCuenta(data.email, data.password);
          await props.history.push("/cuenta");
        })}
      >
        <div className="login__item-titulo">
          <p className="login__titulo-principal">Log in</p>
        </div>

        <div className="login__item-container">
          <div className="login__item">
            <label htmlFor="usuario" className="login__titulo-secundario">
              Email
            </label>
            <input
              {...register("email", {
                required: {
                  value: true,
                  message: "email es requerido",
                },
              })}
              type="email"
              name="email"
              id="email"
              className="input input--xxlarge"
              placeholder="Email"
            />
            {errors.email && (
              <p className="login__titulo-secundario">{errors.email.message}</p>
            )}
          </div>

          <div className="login__item">
            <label htmlFor="contrasena" className="login__titulo-secundario">
              Password
            </label>
            <input
              {...register("password", {
                required: {
                  value: true,
                  message: "Contraseña es requerido",
                },
                minLength: {
                  value: 6,
                  message: "Se necesita un minimo de 6 caracteres",
                },
                validate: () => {},
              })}
              name="password"
              id="password"
              className="input input--xxlarge"
              placeholder="Password"
            />
            {errors.password && (
              <p className="login__titulo-secundario">
                {errors.password.message}
              </p>
            )}
          </div>

          <div className="login__btn">
            <button type="submit" className="btn btn--large-short">
              Log in
            </button>
          </div>

          <div className="login__item">
            <Link className="login__link" to="/register">
              nuevo usuario? registrate!
            </Link>
          </div>

          <div className="login__item">
            <Link className="login__link" to="/recover-password">
              olvidaste tu contraseña?
            </Link>
          </div>

          <div className="login__item">
            <Link className="login__link">desea probar el demo?</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.cuentaReducer;
};

export default connect(mapStateToProps, cuentaActions)(Login);
