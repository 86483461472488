import React, { useEffect } from "react";
import { connect } from "react-redux";

import "./Productos.css";

import { Navtab } from "../../components/Navtab/Navtab";
import { ButtonTab } from "../../components/Button/Button";
import ProductosTorta from "./ProductosTorta/ProductosTorta";
import ProductosBocaditos from "./ProductosBocaditos/ProductosBocaditos";
import ProductosArreglos from "./ProductosArreglos/ProductosArreglos";
import * as tabsProdutosActions from "../../redux/actions/tabProductosActions";

const Productos = (props) => {
  const { tabProductosTraerTodos, toggleTabProductos } = props;
  const { tabProductos } = props.tabProductosReducer;
  const { isLoggedIn, user } = props.cuentaReducer;

  if (!isLoggedIn) {
    props.history.push("/login");
  }

  const companyId = user?.user?.companyId;

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    if (!Object.keys(tabProductos).length) {
      tabProductosTraerTodos();
    }
  });

  return (
    <div>
      <p className="productos__titulo">Productos</p>
      <Navtab>
        <ButtonTab
          onClick={() => toggleTabProductos("torta")}
          selected={tabProductos.torta}
        >
          Torta
        </ButtonTab>
        <ButtonTab
          onClick={() => toggleTabProductos("bocaditos")}
          selected={tabProductos.bocaditos}
        >
          Bocaditos
        </ButtonTab>
        <ButtonTab
          onClick={() => toggleTabProductos("arreglos")}
          selected={tabProductos.arreglos}
        >
          Arreglos
        </ButtonTab>
      </Navtab>

      <div className="productos">
        {tabProductos.torta ? (
          <ProductosTorta companyId={companyId} />
        ) : tabProductos.bocaditos ? (
          <ProductosBocaditos companyId={companyId} />
        ) : tabProductos.arreglos ? (
          <ProductosArreglos companyId={companyId} />
        ) : (
          <div>Algo salio mal</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ tabProductosReducer, cuentaReducer }) => {
  return { tabProductosReducer, cuentaReducer };
};

export default connect(mapStateToProps, tabsProdutosActions)(Productos);
