import axios from "axios";

import {
  CARGANDO,
  ERROR,
  TRAER_TODOS,
  ACTUALIZAR,
} from "../types/ClientesTypes";

import { authHeader } from "../../services/user.service";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const URL = `${URL_HOST}/api/v1`;
const URL_CLIENTES = `${URL}/clientes`;
const URL_UBICACIONES = `${URL}/ubicaciones`;

export const clientesTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const header = await authHeader();

    const rtaClientes = await axios.get(URL_CLIENTES, {
      headers: {
        ...header,
      },
    });
    const rtaData = await rtaClientes.data;
    const arrayClientes = [];

    rtaData.forEach((cliente) => {
      arrayClientes.push({
        id: cliente.id,
        nombre: cliente.nombre,
        apellido: cliente.apellido,
        cedula: cliente.cedula,
        telefono: cliente.telefono,
        email: cliente.email,
        facebook: cliente.facebook,
        instagram: cliente.instagram,
        ubicaciones: cliente.ubicaciones.map((ubicacion) => ({
          id: ubicacion.id,
          clienteId: ubicacion.clienteId,
          calles: ubicacion.calles,
          numeroCasa: ubicacion.numeroCasa,
          referencia: ubicacion.referencia,
        })),
        numeroPedidos: cliente.pedidos.length,
      });
    });
    const respuesta = {
      clientes: [...arrayClientes],
    };

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Informacion de clientes no disponible",
    });
  }
};

export const agregarCliente = () => (dispatch, getState) => {
  const { clientes } = getState().clientesReducer;

  const newObject = {
    ...clientes,
  };

  newObject.clientes = [
    ...clientes.clientes,
    {
      nombre: "",
      apellido: "",
      cedula: "",
      telefono: "",
      email: "",
      facebook: "",
      instagram: "",
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const actualizarCliente =
  (data, clienteId) => async (dispatch, getState) => {
    const { clientes } = getState().clientesReducer;

    dispatch({
      type: CARGANDO,
    });

    let indexCliente = clientes.clientes.findIndex(
      (tipoProducto) => tipoProducto.id === clienteId
    );

    try {
      const header = await authHeader();

      let rtaData = undefined;

      if (indexCliente !== -1) {
        const rtaPatch = await axios.patch(
          `${URL_CLIENTES}/${clienteId}`,
          data,
          {
            headers: {
              ...header,
            },
          }
        );
        rtaData = await rtaPatch.data;
      } else {
        if (!data.cedula) {
          data.cedula = null;
        }
        if (data.email === "") {
          data.email = null;
        }
        if (isNaN(data.telefono)) {
          data.telefono = null;
        }
        if (data.facebook === "") {
          data.facebook = null;
        }
        if (data.instagram === "") {
          data.instagram = null;
        }

        const rtaPost = await axios.post(URL_CLIENTES, data, {
          headers: {
            ...header,
          },
        });
        rtaData = await rtaPost.data;
        indexCliente = clienteId.replace("array", "");
      }

      const cliente = {
        id: rtaData.id,
        nombre: rtaData.nombre,
        apellido: rtaData.apellido,
        cedula: rtaData.cedula,
        telefono: rtaData.telefono,
        email: rtaData.email,
        facebook: rtaData.facebook,
        instagram: rtaData.instagram,
        ubicaciones: rtaData.ubicaciones?.map((ubicacion) => ({
          id: ubicacion.id,
          clienteId: ubicacion.clienteId,
          calles: ubicacion.calles,
          numeroCasa: ubicacion.numeroCasa,
          referencia: ubicacion.referencia,
        })),
        numeroPedidos: rtaData.pedidos?.length,
      };

      const newArray = {
        ...clientes,
      };

      newArray.clientes = [...clientes.clientes];

      newArray.clientes[indexCliente] = {
        ...cliente,
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newArray,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Informacion de clientes no disponible",
      });
    }
  };

export const eliminarCliente = (idCliente) => async (dispatch, getState) => {
  const { clientes } = getState().clientesReducer;

  dispatch({
    type: CARGANDO,
  });

  //Indexes
  let indexCliente = clientes.clientes.findIndex(
    (cliente) => cliente.id === idCliente
  );

  try {
    const header = await authHeader();

    if (indexCliente !== -1) {
      await axios.delete(`${URL_CLIENTES}/${idCliente}`, {
        headers: {
          ...header,
        },
      });
    } else {
      indexCliente = idCliente.replace("array", "");
    }

    const newArray = {
      clientes: clientes.clientes
        .slice(0, indexCliente)
        .concat(clientes.clientes.slice(indexCliente + 1)),
    };

    dispatch({
      type: ACTUALIZAR,
      payload: newArray,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Servicio no disponible en este momento.",
    });
  }
};

export const agregarUbicacion = (clienteId) => (dispatch, getState) => {
  const { clientes } = getState().clientesReducer;

  let indexCliente = clientes.clientes.findIndex(
    (tipoProducto) => tipoProducto.id === clienteId
  );

  const newObject = {
    ...clientes,
  };
  newObject.clientes = [...clientes.clientes];
  newObject.clientes[indexCliente] = {
    ...clientes.clientes[indexCliente],
  };
  newObject.clientes[indexCliente].ubicaciones = [
    ...clientes.clientes[indexCliente].ubicaciones,
    {
      clienteId: clientes.clientes[indexCliente].id,
      calles: "",
      numeroCasa: "",
      referencia: "",
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newObject,
  });
};

export const actualizarUbicacion =
  (data, clienteId, ubicacionId) => async (dispatch, getState) => {
    const { clientes } = getState().clientesReducer;

    let indexCliente = clientes.clientes.findIndex(
      (tipoProducto) => tipoProducto.id === clienteId
    );

    let indexUbicacion = clientes.clientes[indexCliente].ubicaciones.findIndex(
      (ubicacion) => ubicacion.id === ubicacionId
    );
    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();

      let rtaData = undefined;

      if (indexUbicacion !== -1) {
        const rtaPatch = await axios.patch(
          `${URL_UBICACIONES}/${ubicacionId}`,
          data,
          {
            headers: {
              ...header,
            },
          }
        );
        rtaData = await rtaPatch.data;
      } else {
        const rtaPost = await axios.post(URL_UBICACIONES, data, {
          headers: {
            ...header,
          },
        });
        rtaData = await rtaPost.data;
        indexUbicacion = ubicacionId.replace("array", "");
      }

      const ubicacion = {
        id: rtaData.id,
        clienteId: rtaData.clienteId,
        calles: rtaData.calles,
        numeroCasa: rtaData.numeroCasa,
        referencia: rtaData.referencia,
      };

      const newArray = {
        ...clientes,
      };

      newArray.clientes = [...clientes.clientes];

      newArray.clientes[indexCliente] = {
        ...clientes.clientes[indexCliente],
      };
      newArray.clientes[indexCliente].ubicaciones = [
        ...clientes.clientes[indexCliente].ubicaciones,
      ];
      newArray.clientes[indexCliente].ubicaciones[indexUbicacion] = {
        ...ubicacion,
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newArray,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const eliminarUbicacion =
  (clienteId, ubicacionId) => async (dispatch, getState) => {
    const { clientes } = getState().clientesReducer;

    let indexCliente = clientes.clientes.findIndex(
      (tipoProducto) => tipoProducto.id === clienteId
    );

    let indexUbicacion = clientes.clientes[indexCliente].ubicaciones.findIndex(
      (ubicacion) => ubicacion.id === ubicacionId
    );

    try {
      const header = await authHeader();

      if (indexUbicacion !== -1) {
        await axios.delete(`${URL_UBICACIONES}/${ubicacionId}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexUbicacion = ubicacionId.replace("array", "");
      }

      const newArray = {
        ...clientes,
      };

      newArray.clientes = [...clientes.clientes];

      newArray.clientes[indexCliente] = {
        ...clientes.clientes[indexCliente],
        ubicaciones: clientes.clientes[indexCliente].ubicaciones
          .slice(0, indexUbicacion)
          .concat(
            clientes.clientes[indexCliente].ubicaciones.slice(
              indexUbicacion + 1
            )
          ),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newArray,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };
