import axios from "axios";

import { authHeader } from "../../services/user.service";
import {
  CARGANDO,
  ERROR,
  TRAER_TODOS,
  ACTUALIZAR,
} from "../types/BocaditosTypes";

const URL_HOST = "https://desaldulcebackend-production.up.railway.app";
const URL = `${URL_HOST}/api/v1/bocaditos`;
const URL_TITULO_BOCADITOS = `${URL}/titulo`;
const URL_PARAMETRO_BOCADITOS = `${URL}/parametro`;
const URL_VALOR_BOCADITOS = `${URL}/valor`;

export const bocaditosTraerTodos = () => async (dispatch) => {
  dispatch({
    type: CARGANDO,
  });
  try {
    const header = await authHeader();

    const rtaBocaditos = await axios.get(URL_TITULO_BOCADITOS, {
      headers: {
        ...header,
      },
    });
    const dataBocaditos = await rtaBocaditos.data;
    const arrayBocaditos = [];

    dataBocaditos.map((bocadito) => {
      arrayBocaditos.push({
        id: bocadito.id,
        producto: bocadito.nombre,
        precio: bocadito.precio,
        parametros: bocadito.bocaditoParametros.map((parametro) => ({
          id: parametro.id,
          bocaditoId: parametro.bocaditoId,
          parametro: parametro.parametro,
          data: parametro.bocaditoParametroValores.map((dato) => ({
            id: dato.id,
            bocaditoParametroId: dato.bocaditoParametroId,
            valor: dato.valor,
          })),
        })),
      });
    });

    const respuesta = [...arrayBocaditos];

    dispatch({
      type: TRAER_TODOS,
      payload: respuesta,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Informacion de bocaditos no disponible",
    });
  }
};

export const cambioEditar = (idProducto) => (dispatch, getState) => {
  const { bocaditos } = getState().bocaditosReducer;

  let indexBocadito = bocaditos.findIndex(
    (bocadito) => bocadito.id === idProducto
  );

  if (indexBocadito === -1) {
    indexBocadito = idProducto.replace("array", "");
  }

  const seleccionada = bocaditos[indexBocadito];

  const actualizadas = [...bocaditos];
  actualizadas[indexBocadito] = {
    ...bocaditos[indexBocadito],
    editar: !seleccionada.editar,
  };

  dispatch({
    type: ACTUALIZAR,
    payload: actualizadas,
  });
};

export const agregarParametro = (idProducto, data) => (dispatch, getState) => {
  const { bocaditos } = getState().bocaditosReducer;

  let indexBocadito = bocaditos.findIndex(
    (bocadito) => bocadito.id === idProducto
  );

  if (indexBocadito === -1) {
    indexBocadito = idProducto.replace("array", "");
  }

  const parametros = data.bocaditoParametros.map((parametro) => ({
    id: parametro.id,
    bocaditoId: parametro.bocaditoId,
    parametro: parametro.parametro,
    data: parametro.bocaditoParametroValores.map((dato) => ({
      id: dato.id,
      bocaditoParametroId: dato.bocaditoParametroId,
      valor: dato.valor,
    })),
  }));

  parametros.map((parametro) => {
    if (parametro.id === undefined) {
      delete parametro.id;
    }
    parametro.data?.map((dato) => {
      if (dato.id === undefined) {
        delete dato.id;
      }
    });
  });

  const bocadito = {
    producto: data.nombre,
    precio: data.precio,
    parametros: parametros,
  };

  if (data.id) {
    bocadito.id = data.id;
  }

  const newArray = [...bocaditos];

  newArray[indexBocadito] = {
    ...bocaditos[indexBocadito],
    ...bocadito,
    editar: true,
  };

  newArray[indexBocadito].parametros = [
    ...parametros,
    {
      parametro: "",
      data: [""],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newArray,
  });
};

export const eliminarParametro =
  (idProducto, idParametro) => async (dispatch, getState) => {
    const { bocaditos } = getState().bocaditosReducer;
    dispatch({
      type: CARGANDO,
    });

    //Indexes
    let indexProducto = bocaditos.findIndex(
      (bocadito) => bocadito.id === idProducto
    );

    if (indexProducto === -1) {
      indexProducto = idProducto.replace("array", "");
    }

    let indexParametro = bocaditos[indexProducto].parametros.findIndex(
      (parametro) => parametro.id === idParametro
    );

    //DELETE LOGIC
    try {
      const header = await authHeader();

      if (indexParametro !== -1) {
        await axios.delete(`${URL_PARAMETRO_BOCADITOS}/${idParametro}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexParametro = idParametro.replace("array", "");
      }

      const seleccionada = bocaditos[indexProducto].parametros;

      const newArray = [...bocaditos];

      newArray[indexProducto] = {
        ...bocaditos[indexProducto],
        parametros: seleccionada
          .slice(0, indexParametro)
          .concat(seleccionada.slice(indexParametro + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newArray,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarOpcion =
  (idProducto, idParametro, data) => (dispatch, getState) => {
    const { bocaditos } = getState().bocaditosReducer;

    let indexProducto = bocaditos.findIndex(
      (bocadito) => bocadito.id === idProducto
    );

    if (indexProducto === -1) {
      indexProducto = idProducto.replace("array", "");
    }

    let indexParametro = bocaditos[indexProducto].parametros.findIndex(
      (parametro) => parametro.id === idParametro
    );

    if (indexParametro === -1) {
      indexParametro = idParametro.replace("array", "");
    }

    const parametros = data.bocaditoParametros.map((parametro) => ({
      id: parametro.id,
      bocaditoId: parametro.bocaditoId,
      parametro: parametro.parametro,
      data: parametro.bocaditoParametroValores.map((dato) => ({
        id: dato.id,
        bocaditoParametroId: dato.bocaditoParametroId,
        valor: dato.valor,
      })),
    }));

    const bocadito = {
      producto: data.nombre,
      precio: data.precio,
      parametros: parametros,
    };

    if (data.id) {
      bocadito.id = data.id;
    }

    const seleccionada = parametros[indexParametro].data;

    const newArray = [...bocaditos];

    newArray[indexProducto] = {
      ...bocaditos[indexProducto],
      ...bocadito,
      editar: true,
    };

    newArray[indexProducto].parametros = [...parametros];

    newArray[indexProducto].parametros[indexParametro] = {
      ...parametros[indexParametro],
      data: [...seleccionada, ""],
    };

    dispatch({
      type: ACTUALIZAR,
      payload: newArray,
    });
  };

export const eliminarOpcion =
  (idProducto, idParametro, idOpciones) => async (dispatch, getState) => {
    const { bocaditos } = getState().bocaditosReducer;

    dispatch({
      type: CARGANDO,
    });

    //Indexes
    let indexProducto = bocaditos.findIndex(
      (bocadito) => bocadito.id === idProducto
    );

    if (indexProducto === -1) {
      indexProducto = idProducto.replace("array", "");
    }

    let indexParametro = bocaditos[indexProducto].parametros.findIndex(
      (parametro) => parametro.id === idParametro
    );

    if (indexParametro === -1) {
      indexParametro = idParametro.replace("array", "");
    }

    let indexOpcion = bocaditos[indexProducto].parametros[
      indexParametro
    ].data.findIndex((dato) => dato.id === idOpciones);

    //DELETE LOGIC
    try {
      const header = await authHeader();

      if (indexOpcion !== -1) {
        await axios.delete(`${URL_VALOR_BOCADITOS}/${idOpciones}`, {
          headers: {
            ...header,
          },
        });
      } else {
        indexOpcion = idOpciones.replace("array", "");
      }

      const seleccionada =
        bocaditos[indexProducto].parametros[indexParametro].data;

      const newArray = [...bocaditos];

      newArray[indexProducto] = {
        ...bocaditos[indexProducto],
      };

      newArray[indexProducto].parametros[indexParametro] = {
        ...bocaditos[indexProducto].parametros[indexParametro],
        data: seleccionada
          .slice(0, indexOpcion)
          .concat(seleccionada.slice(indexOpcion + 1)),
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newArray,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };

export const agregarEdit = () => (dispatch, getState) => {
  const { bocaditos } = getState().bocaditosReducer;

  const newArray = [
    ...bocaditos,
    {
      editar: true,
      producto: "",
      precio: "",
      parametros: [
        {
          parametro: "",
          data: [""],
        },
      ],
    },
  ];

  dispatch({
    type: ACTUALIZAR,
    payload: newArray,
  });
};

export const eliminarEdit = (idProducto) => async (dispatch, getState) => {
  const { bocaditos } = getState().bocaditosReducer;
  dispatch({
    type: CARGANDO,
  });

  //Indexes
  let indexProducto = bocaditos.findIndex(
    (bocadito) => bocadito.id === idProducto
  );

  if (indexProducto === -1) {
    indexProducto = idProducto.replace("array", "");
  }

  //DELETE LOGIC
  try {
    const header = await authHeader();

    await axios.delete(`${URL_TITULO_BOCADITOS}/${idProducto}`, {
      headers: {
        ...header,
      },
    });

    const newArray = [
      ...bocaditos.slice(0, indexProducto),
      ...bocaditos.slice(indexProducto + 1),
    ];

    dispatch({
      type: ACTUALIZAR,
      payload: newArray,
    });
  } catch (error) {
    console.log("Error: ", error.message);
    dispatch({
      type: ERROR,
      payload: "Servicio no disponible en este momento.",
    });
  }
};

export const editarProducto =
  (idProducto, data) => async (dispatch, getState) => {
    const { bocaditos } = getState().bocaditosReducer;

    const datos = {
      datos: [data],
    };

    dispatch({
      type: CARGANDO,
    });

    try {
      const header = await authHeader();

      const rtaPost = await axios.post(URL_TITULO_BOCADITOS, datos, {
        headers: {
          ...header,
        },
      });

      const rtaData = await rtaPost.data[0];

      let indexProducto = bocaditos.findIndex(
        (bocadito) => bocadito.id === rtaData.id
      );

      const bocadito = {
        id: rtaData.id,
        producto: rtaData.nombre,
        precio: rtaData.precio,
        parametros: rtaData.bocaditoParametros.map((parametro) => ({
          id: parametro.id,
          bocaditoId: parametro.bocaditoId,
          parametro: parametro.parametro,
          data: parametro.bocaditoParametroValores.map((dato) => ({
            id: dato.id,
            bocaditoParametroId: dato.bocaditoParametroId,
            valor: dato.valor,
          })),
        })),
      };

      if (indexProducto === -1) {
        indexProducto = idProducto.replace("array", "");
      }

      const newObject = [...bocaditos];

      newObject[indexProducto] = {
        ...bocaditos[indexProducto],
        ...bocadito,
        editar: false,
      };

      dispatch({
        type: ACTUALIZAR,
        payload: newObject,
      });
    } catch (error) {
      console.log("Error: ", error.message);
      dispatch({
        type: ERROR,
        payload: "Servicio no disponible en este momento.",
      });
    }
  };
